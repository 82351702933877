import { useSelector } from 'react-redux';
import { getStations } from '../store/stationsReducer';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { QUERY_FILTER_STATION_KEY } from 'consts';
import { StationModel } from 'types';
import { getDebouncedSearch } from '../utils/seacrh';

const useGetFilteredSearchedStations = () => {
  const { data: stations, loaded, loading } = useSelector(getStations);
  const [searchFilter, setSearchFilter] = React.useState<string>('');
  const [searchParams] = useSearchParams();
  const [searchedStations, setSearchedStations] = React.useState(stations);
  const currentStationType = searchParams.get(QUERY_FILTER_STATION_KEY);

  const filteredStations = React.useMemo(
    () => (currentStationType ? stations?.filter(station => station.type === currentStationType) : stations),
    [stations, currentStationType]
  );

  const debouncedSearch = React.useMemo(
    () => getDebouncedSearch<StationModel>(setSearchedStations, filteredStations || []),
    [filteredStations]
  );

  // !!! PAY attention, that debouncing is performed by React
  React.useMemo(
    () => debouncedSearch(searchFilter, { keys: ['name', 'location', 'type'] }),
    [searchFilter, debouncedSearch]
  );

  return { loaded, stations: searchedStations, searchFilter, setSearchFilter, loading };
};

export default useGetFilteredSearchedStations;
