import { UNAUTHORISED_KEY } from 'services/api';
import { AnyAction } from 'redux';
import { EventBus } from 'services';
import { SESSION_EXPIRED, USER_IS_UNATHORIZED } from 'services/event-bus';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

export default function (eventBus: EventBus) {
  return (store: ToolkitStore) =>
    (next: (action: AnyAction) => void) =>
    (action: AnyAction): void => {
      try {
        if (action.type.match('/rejected') && action.error.message === UNAUTHORISED_KEY) {
          eventBus.publish(USER_IS_UNATHORIZED);
          if (action.type !== 'AUTH@SIGN_IN/rejected' && action.type !== 'AUTH@GET_ME/rejected') {
            setTimeout(() => eventBus.publish(SESSION_EXPIRED), 0);
          }
        } else {
          return next(action);
        }
      } catch (err) {
        console.error('Caught an exception!', err);
        throw err;
      }
    };
}
