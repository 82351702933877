import React from 'react';
import CardsLayout from 'components/CardsLayout';
import DashboardStationCard from '../DashboardStationCard';
import HeaderComponent from 'components/HeaderComponent';
import Spinner from 'components/Spinner';
import FilterStation from '../../shared/FilterStation';
import { GiProcessor } from 'react-icons/gi';
import { COLORS, DESC, LOGO_SIZE, NAME, STATUS_PRIORITY } from 'consts';
import useGetFilteredSearchedStations from 'hooks/useGetFilteredSearchedStations';
import useGetSortingValue from 'hooks/useGetSortingValue';
import Select from 'components/Select';
import { getStationPriority } from 'utils/stations';
import Input from 'components/Input';
import TextResourceContext from 'contexts/TextResource';

const sortings = [STATUS_PRIORITY, NAME];

const DashboardComponent = () => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const { stations, setSearchFilter, searchFilter, loading } = useGetFilteredSearchedStations();
  const {
    ControlComponent,
    menuIsOpen,
    onChangeFilter,
    direction,
    options: sortingOptions,
    sortValue
  } = useGetSortingValue(sortings);

  const sortedStations = React.useMemo(() => {
    if (!stations) {
      return stations;
    }

    if (sortValue === NAME) {
      return [...stations].sort((station1, station2) => {
        const sign = direction === DESC ? 1 : -1;

        return station1.name.toLowerCase() > station2.name.toLowerCase() ? sign : -sign;
      });
    }

    return [...stations].sort((station1, station2) => {
      const sign = direction === DESC ? 1 : -1;

      return getStationPriority(station1) > getStationPriority(station2) ? sign : -sign;
    });
  }, [stations, direction, sortValue]);

  const components = React.useMemo(
    () => ({
      Control: ControlComponent
    }),
    [ControlComponent]
  );

  if (!sortedStations && loading) {
    return <Spinner isFullSize />;
  }

  return (
    <div>
      <HeaderComponent logo={<GiProcessor size={LOGO_SIZE} color={COLORS.primary} />} />
      <div className='filters-placeholder d-flex gap-3 align-items-center justify-content-end position-fixed layout-width'>
        <Input
          value={searchFilter}
          onSetValue={setSearchFilter}
          placeholder={getTextResourceByKey('search') as string}
          withEmptyStringValidation={false}
          className='mr-3 mb-2'
        />
        <Select
          className='mr-2'
          optionsData={sortingOptions}
          defaultValue={sortingOptions[0]}
          onChange={onChangeFilter}
          menuIsOpen={menuIsOpen}
          components={components}
        />
        <FilterStation />
      </div>
      <div className='pt-5'>
        <CardsLayout cardMinWidth='300px'>
          {sortedStations?.map(station => <DashboardStationCard key={station.id} station={station} />)}
        </CardsLayout>
      </div>
    </div>
  );
};

export default DashboardComponent;
