import React from 'react';
import MapComponent from 'components/MapComponent';
import { ElescopeStationModel } from 'types';
import { flatten, noop, xor } from 'lodash';
import SateliteLayer from 'components/MapComponent/layers/SateliteLayer';
import { v4 as uuidv4 } from 'uuid';
import { MapServiceContext } from 'contexts/MapServiceContext';
import { Coordinate } from 'ol/coordinate';
import { FeatureType } from 'components/MapComponent/MapComponent';
import EmptyState from 'components/EmptyState';
import { FlightWithDroneDetails } from './types';
import ElescopeStationOneDayCards from './ElescopeStationOneDayCards';
import { COLORS } from 'consts';
import { getDronesStationSelector } from 'store/dronesReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';
import Spinner from 'components/Spinner';
import MapLayerComponent from 'components/MapComponent/layers/MapLayerComponent';
import MapFeatures from 'components/MapComponent/MapFeatures';
import classNames from 'classnames';

export type FlightWithDroneDetailsMap = {
  [key: string]: FlightWithDroneDetails[];
};

interface ElescopeStationLayoutProps {
  flightsMap: FlightWithDroneDetailsMap;
  station: ElescopeStationModel;
  splittedByDays?: boolean;
}

const styleLeftColumn = {
  width: '95%'
};

const ElescopeStationLayout: React.FC<ElescopeStationLayoutProps> = ({ station, flightsMap }) => {
  const [disabledFlights, setDisabledFlights] = React.useState<string[]>([]);
  const mapService = React.useContext(MapServiceContext);

  const getDronesStationSelectorCb = React.useCallback(
    (state: RootState) => getDronesStationSelector(state, station.id),
    []
  );
  const isLoading = useSelector(getDronesStationSelectorCb).loading;

  const onFlightCardClick = React.useCallback(
    (id: string) => {
      setDisabledFlights(state => xor(state, [id]));
    },
    [mapService]
  );

  const onFlyTo = React.useCallback(
    (coords: Coordinate) => {
      if (mapService) {
        setTimeout(() => mapService.flyTo(coords, 15), 0);
      }
    },
    [mapService]
  );

  const flightsList = React.useMemo(() => flatten(Object.values(flightsMap)), [flightsMap]);

  const lineFeatures = React.useMemo(
    () =>
      flightsList
        .map(flight => ({
          type: FeatureType.Line,
          data: {
            id: flight.id,
            coords: flight.coords,
            color: flight.color,
            label: `${flight.droneId} (${flight.droneType})`
          }
        }))
        .filter(d => !disabledFlights.includes(d.data.id)),
    [flightsMap, disabledFlights]
  );

  const layers = React.useMemo(
    () => [
      {
        component: (
          <SateliteLayer coords={[+station.latitude, +station.longitude]} id={station.id} color={COLORS.warning} />
        ),
        coords: [+station.latitude, +station.longitude],
        id: uuidv4(),
        priority: 0
      }
    ],
    [station]
  );

  React.useEffect(() => {
    if (mapService) {
      setTimeout(() => mapService.flyTo([+station.latitude, +station.longitude], 10, noop, 0), 100);
    }

    return () => {
      mapService?.removeAllFeatures();
    };
  }, [mapService]);

  return (
    <div className={classNames(flightsList.length > 0 && 'grid-table-1-3')}>
      {flightsList.length > 0 && (
        <div className='relative overflow-auto'>
          <div className='p-absolute mr-3' style={styleLeftColumn}>
            {Object.entries(flightsMap).map(([date, flights]) => (
              <ElescopeStationOneDayCards
                key={date}
                date={date}
                flights={flights}
                onFlightCardClick={onFlightCardClick}
                flyTo={onFlyTo}
                disabledFlights={disabledFlights}
              />
            ))}
          </div>
        </div>
      )}
      <MapComponent className={classNames(isLoading && 'opacity-25')}>
        <MapFeatures features={lineFeatures.filter(d => !disabledFlights.includes(d.data.id))}>
          {layers.map(layer => (
            <MapLayerComponent layer={layer} key={layer.id} />
          ))}
        </MapFeatures>
      </MapComponent>
      {isLoading && (
        <div className='absolute-centered'>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default React.memo(ElescopeStationLayout);
