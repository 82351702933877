import React, { ReactElement } from 'react';
import { SettingsConfigWithId, StatusStationModel } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/types';
import {
  getConfigsStationSelector,
  getStationConfigs,
  updateStationConfigs,
  updateStationWithoutRefetch
} from 'store/configsReducer';
import Card from 'components/Card';
import Text, { TextColor, TextSize } from 'components/Text';
import TextResourceContext from 'contexts/TextResource';
import CardsLayout from 'components/CardsLayout';
import { ButtonSize, ButtonType } from 'components/Button';
import { ModalContext } from 'contexts/ModalContext';
import ConfirmModalBody from 'components/ConfirmModalBody';
import UpdateSettingsModal from '../UpdateSettingsModal';
import EditSettingsModal from '../UpdateSettingsModal/EditSettingsModal';
import Spinner from 'components/Spinner';
import { HiPlus } from 'react-icons/hi';
import { COLORS } from 'consts';
import Button from 'components/Button';
import { CiSettings, CiTrash } from 'react-icons/ci';
import { MdArrowDropUp, MdArrowDropDown } from 'react-icons/md';
import ErrorMessage from 'components/ErrorMessage';
import { omit } from 'lodash';

import './SettingsConfiguratiopn.scss';

interface SettingsStationProps {
  station: StatusStationModel;
  setExtraNavbarComponent: (el: ReactElement) => void;
}

const ICON_WIDTH = 24;

const SettingsConfiguration: React.FC<SettingsStationProps> = ({ station, setExtraNavbarComponent }) => {
  const dispatch = useDispatch() as AppDispatch;
  const getConfigCb = React.useCallback(
    (state: RootState) => getConfigsStationSelector(state, station.id),
    [station.id]
  );

  const { data, error, loading } = useSelector(getConfigCb);
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const { openModal, closeModal } = React.useContext(ModalContext);

  React.useEffect(() => {
    dispatch(getStationConfigs({ stationId: station.id }));
    setExtraNavbarComponent(<div />);
  }, []);

  const onDeleteCard = React.useCallback(
    (beginFrequency: string) => {
      if (station) {
        const updatedCards = data.filter(dataItem => dataItem.beginFrequency !== beginFrequency);

        dispatch(updateStationConfigs({ stationId: station.id, configs: updatedCards })).then(closeModal);
      }
    },
    [station, closeModal, data]
  );

  const onDeleteCardClick = React.useCallback(
    (beginFrequency: string) => () => {
      const onSubmit = () => onDeleteCard(beginFrequency);

      openModal(
        <ConfirmModalBody title={getTextResourceByKey('deleteStationTitle')} onClose={closeModal} onSubmit={onSubmit}>
          {getTextResourceByKey('deleteStationSettingsBody', {
            name: <span className='text-underline text-bold color-white'>{station.name}</span>
          })}
        </ConfirmModalBody>,
        { modalType: 'confirm' }
      );
    },
    [getTextResourceByKey, onDeleteCard]
  );

  const addSettings = React.useCallback(
    (newSettings: SettingsConfigWithId) => {
      if (station) {
        const omittedSettings = [...data, newSettings].map(dataItem => omit(dataItem, 'id'));

        dispatch(updateStationConfigs({ stationId: station.id, configs: omittedSettings })).then(closeModal);
      }
    },
    [station, data]
  );

  const editSettings = React.useCallback(
    (newSettings: SettingsConfigWithId) => {
      const settings = data.map(configItem => (newSettings.id === configItem.id ? newSettings : configItem));

      dispatch(updateStationWithoutRefetch(station.id, settings));
      closeModal();
    },
    [station, data]
  );

  const onAddSettings = React.useCallback(() => {
    openModal(<UpdateSettingsModal onSubmit={addSettings} title={'addSettingsFrequencyModalTitle'} />);
  }, [addSettings]);

  const onEditSettings = React.useCallback(
    (settingsIid: number | string) => () => {
      const initialSettings = data.find(dataItem => dataItem.id === settingsIid);

      if (initialSettings) {
        openModal(<EditSettingsModal onSubmit={editSettings} initialSettings={initialSettings} />);
      }
    },
    [addSettings]
  );

  if (loading) {
    return <Spinner isFullSize />;
  }

  if (error) {
    return <ErrorMessage className='settings-configuration__error-section' message={error as string} />;
  }

  return (
    <div className='pt-5'>
      <CardsLayout className='mb-2' cardMinWidth='240px'>
        <Card
          onClick={onAddSettings}
          className='settings-configuration__card  d-flex flex-column flex-align-center flex-justify-center pt-4'
        >
          <Text size={TextSize.Md} className='text-center mb-1 uppercase' color={TextColor.White}>
            {getTextResourceByKey('newSettings')}
          </Text>
          <div className='text-center'>
            <HiPlus size='40' color={COLORS.white} />
          </div>
        </Card>
        {data?.map(dataItem => (
          <Card className='settings-configuration__card  pt-2 pb-2' key={dataItem.beginFrequency}>
            <div className='d-flex gap-2 flex-align-center flex-justify-center mb-1'>
              <Text color={TextColor.White} size={TextSize.Md} className='d-inline-flex'>
                <div className='d-flex flex-row flex-align-center flex-justify-center'>
                  <div className='d-flex pt-2'>
                    <MdArrowDropDown size={20} color={COLORS.white} />
                  </div>
                  {dataItem.beginFrequency} ... {dataItem.endFrequency}
                  <div className='d-flex pb-2'>
                    <MdArrowDropUp size={20} color={COLORS.white} />
                  </div>
                </div>
              </Text>
            </div>
            <div className='flex text-center flex-justify-center mb-2'>
              <div className='mr-3 no-white-space'>
                <Text size={TextSize.Sm} className='mb-1 text-center no-white-space'>
                  {getTextResourceByKey('interval')}
                </Text>
                <div className='d-flex gap-1 flex-align-center flex-justify-center'>
                  <Text color={TextColor.White} size={TextSize.Md}>
                    {dataItem.intervalFrequency}
                  </Text>
                  <div className='d-flex align-self-center'>
                    <Text size={TextSize.XXs} color={TextColor.White} className='pt-2'>
                      SEC
                    </Text>
                  </div>
                </div>
              </div>
              <div className='mr-3 no-white-space'>
                <Text size={TextSize.Sm} className='mb-1 text-center'>
                  {getTextResourceByKey('bandWidth')}
                </Text>
                <Text color={TextColor.White} size={TextSize.Md}>
                  {dataItem.bandWidth}
                </Text>
              </div>
              <div>
                <Text size={TextSize.Sm} className='mb-1 text-center no-white-space'>
                  {getTextResourceByKey('minPower')}
                </Text>
                <div className='d-flex gap-1 flex-align-center flex-justify-center'>
                  <Text color={TextColor.White} size={TextSize.Md}>
                    {dataItem.minPower}
                  </Text>
                  <div className='d-flex align-self-center'>
                    <Text size={TextSize.XXs} color={TextColor.White} className='pt-2'>
                      MHz
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex gap-2 flex-align-center flex-justify-center'>
              <Button
                size={ButtonSize.SmallMedium}
                className='settings-configuration__btns p-1 rounded-05 d-flex flex-justify-center'
                onClick={onEditSettings(dataItem.id)}
              >
                <CiSettings size={ICON_WIDTH} color={COLORS.white} />
                <Text size={TextSize.Sm} color={TextColor.White} className='uppercase d-flex flex-justify-center w-100'>
                  {getTextResourceByKey('edit')}
                </Text>
              </Button>
              <Button
                size={ButtonSize.SmallMedium}
                className='settings-configuration__btns p-1 rounded-05 d-flex flex-justify-center'
                buttonType={ButtonType.Danger}
                onClick={onDeleteCardClick(dataItem.beginFrequency)}
              >
                <CiTrash size={ICON_WIDTH} color={COLORS.white} />
                <Text size={TextSize.Sm} color={TextColor.White} className='uppercase d-flex flex-justify-center w-100'>
                  {getTextResourceByKey('remove')}
                </Text>
              </Button>
            </div>
          </Card>
        ))}
      </CardsLayout>
    </div>
  );
};

export default React.memo(SettingsConfiguration);
