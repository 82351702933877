import config from '../config.json';
import { isNil, omitBy } from 'lodash';

const isDevelopment = process.env.NODE_ENV === 'development';

const apiHost = process.env.HOST || (isDevelopment ? config.devHost : config.proHost);
const apiProtocol = process.env.PROTOCOL || config.protocol;
const apiPort = process.env.API_PORT || config.port;

const port = apiPort ? `:${apiPort}` : '';

export const baseUrl = isDevelopment ? `${apiProtocol}://${apiHost}${port}` : config.proHost;

export const getUrl = (url: string) => `${baseUrl}/${url}`;

export function extendUrlByQueries(url: string, queryParams: object = {}) {
  const truthyQueryParams = omitBy(queryParams, isNil);

  return Object.entries(truthyQueryParams).reduce((acc, [queryKey, queryValue]) => {
    const chainingSign = acc.indexOf('?') > -1 ? '&' : '?';
    // tslint:disable-next-line:no-parameter-reassignment

    acc += `${chainingSign}${queryKey}=${queryValue}`;

    return acc;
  }, url);
}
