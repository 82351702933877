import React from 'react';
import { StatusStationModel } from 'types';
import EmptyState from 'components/EmptyState';
import ErrorMessage from 'components/ErrorMessage';
import Spinner from 'components/Spinner';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import TextResourceContext from 'contexts/TextResource';
import {
  getAnalyticsFrequenciesAction,
  getAnalyticsFrequenciesSelector,
  getAnalyticsFrequenciesDataIsEmptySelector,
  resetStationAnalyticsFrequenciesAndCancelPrevRequest
} from 'store/analyticsFrequenciesReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/types';
import Pagination from 'components/Pagination';
import Calendar from 'components/Calendar';

import './AnalyticsWidget.scss';

interface AnalyticsFrequenciesProps {
  station: StatusStationModel;
  pageSize: number;
  firstPeriodDefaultVal: Date[];
  secondPeriodDefaultVal: Date[];
}

const AnalyticsFrequencies: React.FC<AnalyticsFrequenciesProps> = ({
  station,
  pageSize,
  firstPeriodDefaultVal,
  secondPeriodDefaultVal
}) => {
  const dispatch = useDispatch() as AppDispatch;
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const [firstPeriod, setFirstPeriod] = React.useState(firstPeriodDefaultVal);
  const [secondPeriod, setSecondPeriod] = React.useState(secondPeriodDefaultVal);
  const [currentPage, setCurrentPage] = React.useState(1);

  const {
    error: frequenciesError,
    loading: isFrequenciesLoading,
    data: { added: frequenciesAdded, removed: frequenciesRemoved }
  } = useSelector((state: RootState) => getAnalyticsFrequenciesSelector(state, station.id));

  const frequenciesDataMaxLength =
    frequenciesAdded.length >= frequenciesRemoved.length ? frequenciesAdded.length : frequenciesRemoved.length;
  const totalPages = Math.ceil(frequenciesDataMaxLength / pageSize);
  const frequenciesPaginatedData = (arr: number[]) => arr.slice(0, currentPage * pageSize);
  const frequenciesAddedPaginated = frequenciesPaginatedData(frequenciesAdded);
  const frequenciesRemovedPaginated = frequenciesPaginatedData(frequenciesRemoved);

  const onNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [firstPeriod, secondPeriod]);

  const isFrequenciesDataEmpty = useSelector((state: RootState) =>
    getAnalyticsFrequenciesDataIsEmptySelector(state, station.id)
  );

  React.useEffect(() => {
    dispatch(
      getAnalyticsFrequenciesAction({
        stationId: station.id,
        firstPeriodStart: firstPeriod[0].getTime(),
        firstPeriodEnd: firstPeriod[1].getTime(),
        secondPeriodStart: secondPeriod[0].getTime(),
        secondPeriodEnd: secondPeriod[1].getTime()
      })
    );

    return () => {
      dispatch(resetStationAnalyticsFrequenciesAndCancelPrevRequest(station.id));
    };
  }, [firstPeriod, secondPeriod]);

  return (
    <div className='mr-3 ml-3'>
      <div className='d-flex analytics__calendars-gap mb-4 flex-row'>
        <div className='d-flex flex-column'>
          <Text size={TextSize.Sm} className='mb-2 '>
            {getTextResourceByKey('primaryPeriod')}
          </Text>
          <Calendar
            placement='bottomRight'
            onSubmit={setFirstPeriod}
            textColor={TextColor.Orange}
            defaultValue={firstPeriodDefaultVal}
          />
        </div>
        <div className='d-flex flex-column'>
          <Text size={TextSize.Sm} className='mb-2 '>
            {getTextResourceByKey('secondaryPeriod')}
          </Text>
          <Calendar
            placement='bottomRight'
            onSubmit={setSecondPeriod}
            textColor={TextColor.Orange}
            defaultValue={secondPeriodDefaultVal}
          />
        </div>
      </div>
      {isFrequenciesLoading ? (
        <Spinner className='w-100' isFullSize />
      ) : (
        <>
          {isFrequenciesDataEmpty || frequenciesError?.message ? (
            <>
              {isFrequenciesDataEmpty && <EmptyState />}
              {frequenciesError?.message && (
                <ErrorMessage className='analytics__error-section' message={frequenciesError?.message} />
              )}
            </>
          ) : (
            <Pagination callback={onNextPage}>
              <div className='d-flex mb-4'>
                <div className='d-flex flex-column w-100'>
                  <Text className='mb-2'>{getTextResourceByKey('analytics/frequencies/description')}</Text>
                  <div className='d-flex gap-3 w-100'>
                    <div className='analytics__section w-50 p-3 rounded-1'>
                      {frequenciesAddedPaginated.map(addedFreq => (
                        <div
                          key={addedFreq}
                          className='analytics__section__card-frequencies d-flex rounded-1 align-itmes-center'
                        >
                          <Text color={TextColor.White} size={TextSize.SmMd} className='align-self-center'>
                            {addedFreq}
                          </Text>
                        </div>
                      ))}
                    </div>
                    <div className='analytics__section w-50 p-3 rounded-1'>
                      {frequenciesRemovedPaginated.map(removedFreq => (
                        <div
                          key={removedFreq}
                          className='analytics__section__card-frequencies d-flex rounded-1 align-itmes-center'
                        >
                          <Text color={TextColor.White} size={TextSize.SmMd} className='align-self-center'>
                            {removedFreq}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Pagination>
          )}
        </>
      )}
    </div>
  );
};

export default AnalyticsFrequencies;
