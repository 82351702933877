import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createEmptyFetchedState, createEmptyPaginationState } from './utils';
import { VmsCamera, VmsCameraBE, VmsEvent, VmsSettings } from '../types/Vms';
import { RootState } from './types';
import { PaginationData, ThunkOptions } from '../types';
import { extendUrlByQueries } from '../utils/api';
import dayjs from 'dayjs';
import { createEmptyCamerasFolder, transformCamerasListInfoFolder, vmsApiEvent2VmsEvent } from '../utils/vms';
import { toastSuccess } from '../components/Toaster';
import { CAMERAS_ICONS_COLORS_MAP } from '../consts';
import { get } from 'lodash';
// import camerasMock from 'mocks/cameras.json';

const initialState = {
  cameras: createEmptyFetchedState<VmsCamera[]>([]),
  settings: createEmptyFetchedState<VmsSettings | null>(null),
  camerasFolder: createEmptyCamerasFolder(),
  events: createEmptyPaginationState<VmsEvent[]>(),
  videoWallsMap: {} as { [key: string]: VmsCamera },
  status: createEmptyFetchedState<{ connectionStatus: string; message?: string }>({
    connectionStatus: 'DISCONNECTED'
  })
};

const vmsSlice = createSlice({
  name: 'VMS',
  initialState,
  reducers: {
    setVideoWall(state, action: { payload: VmsCamera[] }) {
      state.videoWallsMap = action.payload.reduce<{ [key: number]: VmsCamera }>((acc, cam, i) => {
        acc[i] = cam;

        return acc;
      }, {});
    },
    updateCameraFromVideoWall(state, action: { payload: { index: number; camera: VmsCamera } }) {
      const { index, camera } = action.payload;

      state.videoWallsMap[index] = camera;
    },
    updateCamera(state, { payload }: { payload: { id: number; name?: string; status?: string } }) {
      const { id, ...newFields } = payload;
      const camera = state.cameras.data.find(cam => cam.id === id);

      if (camera) {
        Object.assign(camera, newFields);
      }
    },
    appendEvent(state, { payload }: { payload: VmsEvent }) {
      if (state.events.loaded && state.events.data.data.length !== 0) {
        state.events.data.data.unshift(payload);
      }
    },
    updateVmsConnectionStatus(state, { payload }: { payload: { status: string; message?: string } }) {
      state.status.data.connectionStatus = payload.status;
      state.status.data.message = payload.message;
    }
  },
  extraReducers: builder => {
    builder.addCase(getVmsCamerasAction.pending, state => {
      state.cameras.loading = true;
    });

    builder.addCase(getVmsCamerasAction.rejected, (state, action) => {
      state.cameras.loading = true;
      state.cameras.error = action.error;
    });

    builder.addCase(getVmsCamerasAction.fulfilled, (state, action) => {
      state.cameras.loading = false;
      state.cameras.loaded = true;
      state.cameras.error = null;
      state.cameras.data = action.payload.sort((cam1, cam2) => (cam1.name > cam2.name ? 1 : -1));
      state.camerasFolder = transformCamerasListInfoFolder(action.payload);
    });

    builder.addCase(getVmsEventsAction.pending, state => {
      state.events.loading = true;
    });

    builder.addCase(getVmsEventsAction.rejected, (state, action) => {
      state.events.loading = true;
      state.events.error = action.error;
    });

    builder.addCase(getVmsEventsAction.fulfilled, (state, action) => {
      state.events.loading = false;
      state.events.loaded = true;
      state.events.error = null;

      if (action.payload.currentPage === 0) {
        state.events.data = action.payload;
      } else {
        state.events.data = {
          ...action.payload,
          data: [...state.events.data.data, ...action.payload.data]
        };
      }
    });

    builder.addCase(fetchVmsSettings.fulfilled, (state, action) => {
      state.settings.loading = false;
      state.settings.loaded = true;
      state.settings.error = null;
      state.settings.data = action.payload;
    });

    builder.addCase(fetchVmsSettings.pending, state => {
      state.settings.loading = true;
    });

    builder.addCase(fetchVmsStatus.fulfilled, (state, action) => {
      state.status.loading = false;
      state.status.loaded = true;
      state.status.error = null;
      state.status.data = action.payload;
    });
  }
});

const parseCamera = (camera: VmsCameraBE): VmsCamera => {
  const [name, coords, ...restParams] = camera.name.split('|');

  const colorId = restParams.find(param => param.startsWith('c:')) as string;
  const isPTZ = Boolean(restParams.find(param => param.trim() === 'ptz'));

  const color = colorId
    ? get(CAMERAS_ICONS_COLORS_MAP, colorId.trim().replace('c:', ''), CAMERAS_ICONS_COLORS_MAP.Y)
    : CAMERAS_ICONS_COLORS_MAP.Y;

  const [latitude, longitude] = coords ? coords.split(',').map(cItem => +cItem) : [null, null];
  const isrusnya = name.toLowerCase().includes('росія');

  return {
    id: camera.id,
    src: camera.src,
    name,
    latitude,
    longitude,
    status: camera.status,
    isrusnya,
    folderName: camera.folderName,
    color,
    isPTZ
  };
};

export const getVmsCamerasAction = createAsyncThunk<VmsCamera[]>('VMS@GET_CAMERAS', (body, { extra }: ThunkOptions) => {
  const api = extra.api;

  api.abortController('VMS@GET_CAMERAS');

  return (
    api
      .get('vms/cameras', 'VMS@GET_CAMERAS')
      // .then(() => camerasMock)
      .then((serverCameras: VmsCameraBE[]) => serverCameras.map(parseCamera))
  );
});

export const getVmsEventsAction = createAsyncThunk<
  PaginationData<VmsEvent[]>,
  { page: number; pageSize: number; startDate: Date; endDate: Date }
>('VMS@GET_EVENTS', ({ page, startDate, pageSize, endDate }, { extra }: ThunkOptions) => {
  const api = extra.api;

  api.abortController('VMS@GET_EVENTS');

  return api
    .get(
      extendUrlByQueries('vms/events', {
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        page,
        pageSize
      }),
      'VMS@GET_EVENTS'
    )
    .then((response: PaginationData<VmsEvent[]>) => ({
      ...response,
      data: response.data.map(vmsApiEvent2VmsEvent)
    }));
});

export const fetchVmsSettings = createAsyncThunk('VMS@GET_SETTINGS', (_, { extra }: ThunkOptions) => {
  const api = extra.api;

  return api.get('vms/settings');
});

export const fetchVmsStatus = createAsyncThunk('VMS@GET_STATUS', (_, { extra }: ThunkOptions) => {
  const api = extra.api;

  return api.get('vms/status');
});

export const updateVmsSettings = createAsyncThunk(
  'VMS@UPDATE_SETTINGS',
  (body: VmsSettings, { extra, dispatch }: ThunkOptions) => {
    const api = extra.api;

    return api.post('vms/settings', body).then(() => {
      dispatch(fetchVmsSettings());
      toastSuccess('updatedSuccessfully');
    });
  }
);

export const vmsReducer = vmsSlice.reducer;

export const getVmsState = (state: RootState) => state.vms;
export const getVmsCamerasState = (state: RootState) => getVmsState(state).cameras;
export const getVmsEventsState = (state: RootState) => getVmsState(state).events;

export const getVmsCameras = (state: RootState) => getVmsCamerasState(state).data;
export const getVmsCamerasFolder = (state: RootState) => getVmsState(state).camerasFolder;
export const getVmsCamerasAreLoading = (state: RootState) =>
  !getVmsCamerasState(state).loaded && getVmsCamerasState(state).loading;

export const getVmsCamerasAreLoaded = (state: RootState) => getVmsCamerasState(state).loaded;

export const getVideoWalls = (state: RootState) => {
  const camerasMap = getVmsState(state).videoWallsMap;

  return Object.values(camerasMap);
};

export const getVmsSettings = (state: RootState) => getVmsState(state).settings;
export const getVmsStatus = (state: RootState) => getVmsState(state).status.data;

export const { setVideoWall, updateCameraFromVideoWall, updateCamera, appendEvent, updateVmsConnectionStatus } =
  vmsSlice.actions;
