import React from 'react';
import Text, { TextColor, TextSize } from 'components/Text';
import TextResourceContext from 'contexts/TextResource';

const DashboardBaklansStationBody: React.FC = () => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  return (
    <div className='uppercase'>
      <Text size={TextSize.Sm} color={TextColor.Danger}>
        {getTextResourceByKey('lostConnection')}
      </Text>
    </div>
  );
};

export default DashboardBaklansStationBody;
