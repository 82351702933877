import React, { ReactElement } from 'react';
import TabsPanel from 'components/TabsPanel';
import { StationType, StatusStationModel } from 'types';
import StationName from 'components/StationName/StationName';
import StationSettings from '../StationSettings/StationSettings';
import SettingsConfiguration from '../SettingsConfiguration/SettingsConfiguration';

interface StatusSettingsComponentProps {
  station: StatusStationModel;
  setExtraNavbarComponent: (comp: ReactElement) => void;
}

const tabs = ['serverConfig', 'remoteConfig'];

const StatusSettingsComponent: React.FC<StatusSettingsComponentProps> = ({ station, setExtraNavbarComponent }) => {
  const tabsPanels = React.useMemo(
    () =>
      station
        ? [
            <StationSettings station={station} setExtraNavbarComponent={setExtraNavbarComponent} />,
            <SettingsConfiguration station={station} setExtraNavbarComponent={setExtraNavbarComponent} />
          ]
        : [],
    [station]
  );

  return (
    <TabsPanel
      tabs={tabs}
      tabsPanels={station.type === StationType.Status ? tabsPanels : null}
      tabsListBodyClassName='layout-width position-fixed'
      title={<StationName station={station} />}
    />
  );
};

export default StatusSettingsComponent;
