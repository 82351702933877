import React from 'react';
import CardsLayout from 'components/CardsLayout';
import { Frequency, StatusStationModel } from 'types';
import EmptyState from 'components/EmptyState';
import useGetBlockFrequency from './useGetBLockFrequency';
import SimpleFrequencyCard from './SimpleFrequencyCard';

interface FrequenciesProps {
  frequencies: Frequency[];
  station: StatusStationModel;
  blockFreqEnabled?: boolean;
}

const SimpleFrequencies: React.FC<FrequenciesProps> = ({ frequencies, station, blockFreqEnabled = false }) => {
  const onBlockFrequencies = useGetBlockFrequency(station, blockFreqEnabled);

  if (frequencies.length === 0) {
    return <EmptyState />;
  }

  return (
    <CardsLayout className='flex-1' cardMinWidth='140px'>
      {frequencies.map(frequency => (
        <SimpleFrequencyCard
          key={frequency.frequency}
          frequency={frequency}
          onBlockFrequencies={blockFreqEnabled ? onBlockFrequencies : undefined}
        />
      ))}
    </CardsLayout>
  );
};

export default SimpleFrequencies;
