export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;

export const getDateOfSomeDaysAgo = (days: number) => {
  const start = new Date(Date.now() - ONE_DAY * days);

  start.setHours(0, 0, 0, 0);

  return start;
};
