import React from 'react';
import { MapServiceContext } from 'contexts/MapServiceContext';
import { TileSourceMapKey } from 'services/OiMapService/OiMapService';
import 'react-dropdown/style.css';
import './MapLayers.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledComponent } from 'types';
import Text, { TextColor } from 'components/Text';
import classNames from 'classnames';

const options = Object.values(TileSourceMapKey).map(
  option => ({
    value: option,
    label: option,
    className: 'map-layers-dropdown__option'
  }),
  []
);

const TILE_KEY = 'tileLayer';

const MapLayers: React.FC<StyledComponent> = ({ className }) => {
  const mapService = React.useContext(MapServiceContext);
  const location = useLocation();
  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(location.search);
  const tileLayer = urlSearchParams.get(TILE_KEY) || mapService?.currentTileLayerKey;

  const onChange = React.useCallback(
    (value: string) => () => {
      const searchParams = new URLSearchParams(location.search);

      searchParams.set(TILE_KEY, value);
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: false });
    },
    [mapService]
  );

  React.useEffect(() => {
    if (tileLayer !== mapService?.currentTileLayerKey) {
      mapService?.changeLayerGroup(tileLayer as TileSourceMapKey);
    }
  }, [tileLayer]);

  return (
    <div className={classNames('d-flex gap-3', className)}>
      {options.map(option => (
        <Text
          key={option.value}
          className='text-uppercase cursor-pointer'
          color={tileLayer === option.value ? TextColor.Warning : TextColor.HalfLight}
          onClick={onChange(option.value)}
        >
          {option.label}
        </Text>
      ))}
    </div>
  );
};

export default MapLayers;
