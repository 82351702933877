import React from 'react';
import { ElescopeStationModel } from 'types';
import Text, { TextColor, TextSize } from 'components/Text';
import TextResourceContext from 'contexts/TextResource';
import { getNumberOfDrones } from 'utils/stations';

interface DashboardElescopeStationBodyProps {
  station: ElescopeStationModel;
}

const DashboardElescopeStationBody: React.FC<DashboardElescopeStationBodyProps> = ({ station }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  const amountOfDrones = getNumberOfDrones(station);

  if (!amountOfDrones) {
    return null;
  }

  return (
    <div className='uppercase'>
      <Text size={TextSize.Sm} color={TextColor.Danger}>
        {amountOfDrones} {getTextResourceByKey('eleron')}
      </Text>
      <Text size={TextSize.Sm} color={TextColor.Danger} className='mt-1'>
        {getTextResourceByKey('detected')}
      </Text>
    </div>
  );
};

export default DashboardElescopeStationBody;
