export default class ColoredLogger {
  constructor(
    private title: string,
    private color: string
  ) {}
  log(message?: unknown, ...optionalParams: unknown[]) {
    console.log(`%c ${this.title}`, `color: ${this.color}`, message, ...optionalParams);
  }
  warn(message?: unknown, ...optionalParams: unknown[]) {
    console.warn(`%c ${this.title}`, `color: ${this.color}`, message, ...optionalParams);
  }
  error(message?: unknown, ...optionalParams: unknown[]) {
    console.error(`%c ${this.title}`, `color: ${this.color}`, message, ...optionalParams);
  }
}
