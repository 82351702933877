import React from 'react';
import { Frequency, StatusStationModel } from 'types';
import { blockFrequency, unblockFrequency } from 'store/frequenciesReducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/types';

export default function useGetBlockFrequency(station: StatusStationModel, blockFreqEnabled: boolean) {
  const dispatch = useDispatch() as AppDispatch;

  return React.useCallback(
    (freq: Frequency) => {
      if (!blockFreqEnabled) {
        return;
      }

      if (freq.block) {
        dispatch(unblockFrequency({ stationId: station.id, frequency: freq.frequency }));
      } else {
        dispatch(blockFrequency({ stationId: station.id, frequency: freq.frequency }));
      }
    },
    [blockFreqEnabled]
  );
}
