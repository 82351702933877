import React from 'react';
import MapComponent from 'components/MapComponent';
import { VmsCamera } from 'types/Vms';
import Text, { TextColor, TextSize } from 'components/Text';
import { keyBy, truncate } from 'lodash';
import { BsArrowRightCircle } from 'react-icons/bs';
import { SlClose } from 'react-icons/sl';
import { MdOutlineClose } from 'react-icons/md';
import { COLORS } from 'consts';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/types';
import { setVideoWall } from 'store/vmsReducer';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from 'types';
import CameraMarker from './CameraMarker';
import VmsCameraPreview from './VmsCameraPreview';
import { MapServiceContext } from 'contexts/MapServiceContext';
import { Pixel } from 'ol/pixel';
import { useOnClickOutside } from 'usehooks-ts';

interface VmsMapComponentProps {
  cameras: VmsCamera[];
}

const VIDEO_WALL_CAMERAS_LIMIT = 4;
const tooltipStyle = {
  marginTop: '-20px',
  transform: 'translate(-50%, -100%)',
  zIndex: 111
};

const VmsMapComponent: React.FC<VmsMapComponentProps> = ({ cameras }) => {
  const [camerasForVideoWall, setCamerasForVideoWall] = React.useState<VmsCamera[]>([]);
  const dispatch = useDispatch() as AppDispatch;
  const navigate = useNavigate();
  const mapService = React.useContext(MapServiceContext);
  const [floatModalCoords, setFloatModalCoords] = React.useState<Pixel | null>(null);
  const [tooltipCamIds, setTooltipCamIds] = React.useState<number[]>([]);
  const refFloatModal = React.useRef<HTMLDivElement>(null);

  const closeFloatModal = React.useCallback(() => {
    setFloatModalCoords(null);
  }, []);

  useOnClickOutside(refFloatModal, closeFloatModal);

  const camerasMap = React.useMemo(() => keyBy(cameras, 'id'), [cameras]);

  const onAddCameraToVideoWall = React.useCallback((camera: VmsCamera) => {
    setCamerasForVideoWall(cams => {
      if (cams.length === VIDEO_WALL_CAMERAS_LIMIT) {
        return cams;
      }

      const cameraExist = cams.find(cam => cam.id === camera.id);

      if (cameraExist) {
        return cams;
      }

      return [...cams, camera];
    });
  }, []);

  const clearCams = React.useCallback(() => {
    setCamerasForVideoWall([]);
  }, []);

  const removeCamera = React.useCallback((camera: VmsCamera) => {
    setCamerasForVideoWall(cams => cams.filter(cam => cam.id !== camera.id));
  }, []);

  const onSubmitCameras = React.useCallback(() => {
    dispatch(setVideoWall(camerasForVideoWall));
    navigate(`${PageRoutes.VMS}?tabIndex=2`);
  }, [camerasForVideoWall]);

  const isNotEmpty = camerasForVideoWall.length > 0;

  React.useEffect(() => {
    const subscr = mapService?.onFeatureClickSubject.subscribe(({ windowCoords, ids }) => {
      setFloatModalCoords(windowCoords);
      setTooltipCamIds(ids);
    });

    return () => {
      subscr?.unsubscribe();
    };
  }, []);

  const floatModalStyle = {
    ...tooltipStyle,
    ...(floatModalCoords ? { top: floatModalCoords[1], left: floatModalCoords[0] } : { display: 'none' })
  };

  return (
    <MapComponent>
      <div className='position-absolute' style={floatModalStyle} ref={refFloatModal}>
        {floatModalCoords && (
          <VmsCameraPreview
            key={tooltipCamIds.join('')}
            cameras={tooltipCamIds.map(id => camerasMap[id]) as VmsCamera[]}
            onAddCameraToVideoWall={onAddCameraToVideoWall}
            idsForVideoWall={camerasForVideoWall.map(({ id }) => id)}
            closePreview={closeFloatModal}
          />
        )}
      </div>

      {cameras.map(camera => (
        <CameraMarker key={camera.id} camera={camera} />
      ))}
      {isNotEmpty && (
        <div className='vms-cameras-to-videowall' style={{ minWidth: '300px' }}>
          {camerasForVideoWall.map(camera => (
            <Text
              color={TextColor.Dark}
              key={camera.id}
              size={TextSize.Sm}
              className='vms-cameras-to-videowall__item d-inline-flex flex-align-center'
            >
              <MdOutlineClose
                color={COLORS.danger}
                size={14}
                className='mr-2 cursor-pointer'
                onClick={() => removeCamera(camera)}
              />
              {truncate(camera.name, { length: 50, omission: '...' })}
            </Text>
          ))}
          <div className='float-right'>
            <SlClose size={25} color={COLORS.danger} className='cursor-pointer mr-1' onClick={clearCams} />
            <BsArrowRightCircle size={25} color={COLORS.success} className='cursor-pointer' onClick={onSubmitCameras} />
          </div>
        </div>
      )}
    </MapComponent>
  );
};

export default VmsMapComponent;
