export enum ConnectionType {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  NotFound = 'NOT_FOUND',
  WrongResponse = 'WRONG_RESPONSE',
  StationIssue = 'STATION_ISSUE'
}

export enum StationStatusType {
  Alarm = 'ALARM',
  Medium = 'MEDIUM',
  Normal = 'NORMAL',
  Learning = 'LEARNING'
}

export enum StationType {
  Elescope = 'ELESCOPE',
  Status = 'STATUS',
  Baklans = 'BAKLANS',
  Hrushch = 'HRUSHCH'
}

export interface StationModelBase<Type, StationData, Configuration = unknown, StationStatus = unknown> {
  id: number;
  name: string;
  location: string;
  ip: string;
  port: number;
  latitude: string;
  longitude: string;
  connectionStatus: ConnectionType;
  lastUpdate: string;
  status?: StationStatus;
  enabled: boolean;
  stationData?: StationData;
  configuration?: Configuration;
  type: Type;
}
