import React, { SetStateAction } from 'react';
import { MapServiceContext } from 'contexts/MapServiceContext';
import { DrawType } from 'services/OiMapService/DrawService';
import { CiRuler } from 'react-icons/ci';
import { TbPolygon } from 'react-icons/tb';
import { VscCircleLarge } from 'react-icons/vsc';
import { COLORS } from 'consts';
import './MapInstruments.scss';
import TextResourceContext from 'contexts/TextResource';
import Card from 'components/Card';
import Text, { TextColor, TextSize } from 'components/Text';

const instruments = [
  { mode: DrawType.Circle, textKey: 'circle', Component: VscCircleLarge },
  { mode: DrawType.LineString, textKey: 'ruler', Component: CiRuler },
  { mode: DrawType.Polygon, textKey: 'polygon', Component: TbPolygon }
];

interface DrawInstrumentProps {
  mode: DrawType | null;
  setMode: React.Dispatch<React.SetStateAction<DrawType | null>>;
}

const DrawInstrument: React.FC<DrawInstrumentProps> = ({ mode, setMode }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  const onUpdateInstrument = React.useCallback(
    (value: DrawType) => () => {
      setMode(val => (val === value ? null : value));
    },
    []
  );

  const mapService = React.useContext(MapServiceContext);

  React.useEffect(() => {
    if (mode) {
      mapService?.addDrawFeature(mode);
    } else if (mode === null) {
      mapService?.stopInteractions();
    }

    return () => {
      mapService?.stopInteractions();
    };
  }, [mode]);

  React.useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setMode(null);
      }
    };

    window.addEventListener('keydown', callback);

    return () => {
      window.removeEventListener('keydown', callback);
    };
  }, []);

  return (
    <>
      {instruments.map(instrument => (
        <div
          onClick={onUpdateInstrument(instrument.mode)}
          key={instrument.mode}
          className='d-flex align-items-center gap-1 cursor-pointer'
        >
          <instrument.Component size={20} color={mode === instrument.mode ? COLORS.warning : COLORS.gray} />
          <Text
            color={mode === instrument.mode ? TextColor.Warning : TextColor.HalfLight}
            size={TextSize.Sm}
            className='text-uppercase'
          >
            {getTextResourceByKey(instrument.textKey)}
          </Text>
        </div>
      ))}
    </>
  );
};

export default DrawInstrument;
