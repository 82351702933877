import { FetchingData, PaginationData } from '../types';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

export function getRequestIsRejected(response: AxiosResponse) {
  const { status } = response;

  return status && status > 200;
}

export function getActionIsRejected(action: PayloadAction<any, string, any>) {
  return action.meta.requestStatus === 'rejected';
}

export function createEmptyFetchedState<T>(data: T): FetchingData<T> {
  return {
    loading: false,
    loaded: false,
    error: null,
    data
  };
}

export function createEmptyPaginationState<T = any>(): FetchingData<PaginationData<T>> {
  return {
    loading: false,
    loaded: false,
    error: null,
    data: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      data: [] as T
    }
  };
}
