import React from 'react';
import Text, { TextColor, TextSize } from 'components/Text';
import TextResourceContext from 'contexts/TextResource';
import { getStationIsConnected } from 'utils/stations';
import { HrushchStationModel } from 'types/Hrushch';
import './DashboardStationCard.scss';

interface DashboardHrushchStationBodyProps {
  station: HrushchStationModel;
}

const DashboardHrushchStationBody: React.FC<DashboardHrushchStationBodyProps> = ({ station }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  if (!station.stationData || !getStationIsConnected(station)) {
    return null;
  }

  return (
    <>
      {station.stationData.frequency && (
        <div className='d-flex flex-column mb-1'>
          <Text className='dashboard-station-card__counts-title' size={TextSize.XXs} color={TextColor.Light}>
            {getTextResourceByKey('frequency')}
          </Text>
          <Text size={TextSize.SmMd} color={TextColor.Light}>
            {station.stationData.frequency}
          </Text>
        </div>
      )}
      {station.stationData.mac && (
        <div className='d-flex flex-column mb-1'>
          <Text className='dashboard-station-card__counts-title' size={TextSize.XXs} color={TextColor.Light}>
            {getTextResourceByKey('mac')}
          </Text>
          <Text size={TextSize.SmMd} color={TextColor.Light}>
            {station.stationData.mac}
          </Text>
        </div>
      )}
    </>
  );
};

export default DashboardHrushchStationBody;
