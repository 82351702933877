import { Event } from 'types';
import { isConnectionStatusEvent, isElescopeStatusEvent, isHrushchEvent, isStrausEvent } from '../../utils/events';

export const getFilteredEventsByStatusType = (events: Event[], filter: string[]) =>
  events.filter(event => {
    if (isConnectionStatusEvent(event) && event.data.connectionStatus && event.data.previousConnectionStatus) {
      return filter.includes(event.data.connectionStatus);
    }
    if ((isStrausEvent(event) || isHrushchEvent(event)) && event.data.currentStatus && event.data.previousStatus) {
      return filter.includes(event.data.currentStatus);
    }
    if (isElescopeStatusEvent(event)) {
      return filter.includes(event.data.status);
    }

    return false;
  });
