import { Coordinate } from 'ol/coordinate';
import { isString } from 'lodash';

export function hexToRgb(hex: string, opacity = 1) {
  let c;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');

    // @ts-ignore
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity})`;
  }
  throw new Error('Bad Hex');
}

const degreesToRadians = (degrees: number) => (degrees * Math.PI) / 180;

const radiansToDegrees = (rads: number) => (rads * 180) / Math.PI;

const EARTH_RADIUS_IN_METRES = 6378100; // metres

export const getDestinationCoordinatesFromInitPoint = ([lat, long]: Coordinate, brng: number, distanceInKm: number) => {
  const d2R = (distanceInKm * 1000) / EARTH_RADIUS_IN_METRES;
  const latRad = degreesToRadians(lat);
  const longRad = degreesToRadians(long);
  const brngRad = degreesToRadians(brng);

  return [
    radiansToDegrees(
      Math.asin(Math.sin(latRad) * Math.cos(d2R) + Math.cos(latRad) * Math.sin(d2R) * Math.cos(brngRad))
    ),
    radiansToDegrees(
      longRad +
        Math.atan2(
          Math.sin(brngRad) * Math.sin(d2R) * Math.cos(latRad),
          Math.cos(d2R) - Math.sin(latRad) * Math.sin(longRad)
        )
    )
  ];
};

export const getDistanceBetweenCoordinatesInKm = ([lat1, long1]: Coordinate, [lat2, long2]: Coordinate) => {
  const R = EARTH_RADIUS_IN_METRES / 1000; // km
  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(long2 - long1);
  const lat1Rad = degreesToRadians(lat1);
  const lat2Rad = degreesToRadians(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

export const visualizeCoordinate = (coord: number | string): string =>
  isString(coord) ? parseFloat(coord).toFixed(6) : coord.toFixed(6);

export const getDefaultCoordsView = (initialSettings: { latitude?: string; longitude?: string }) => {
  const { latitude, longitude } = initialSettings;

  return {
    ...initialSettings,
    ...(latitude ? { latitude: visualizeCoordinate(latitude) } : {}),
    ...(longitude ? { latitude: visualizeCoordinate(longitude) } : {})
  };
};
