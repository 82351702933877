import React from 'react';
import { fetchUsersAction, getUsers } from 'store/usersReducer';
import { getStations, getStationsAction } from 'store/stationsReducer';
import { getVmsCamerasAction, getVmsCamerasState } from 'store/vmsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/types';

export default function useFetchDataForUserPage() {
  const { loaded: stationsLoaded } = useSelector(getStations);
  const { loaded: usersStateLoaded } = useSelector(getUsers);
  const { loaded: camerasLoaded } = useSelector(getVmsCamerasState);
  const dispatch = useDispatch() as AppDispatch;

  React.useEffect(() => {
    if (!usersStateLoaded) {
      dispatch(fetchUsersAction());
    }

    if (!stationsLoaded) {
      dispatch(getStationsAction());
    }

    if (!camerasLoaded) {
      dispatch(getVmsCamerasAction());
    }
  }, []);
}
