export interface VmsCameraBE {
  id: number;
  name: string;
  src: string;
  cameraId: string;
  status: CameraStatus;
  folderName: string | null;
}

export enum CameraStatus {
  Online = 'Online',
  Offline = 'Offline',
  Recording = 'Recording'
}

export interface VmsCamera {
  id: number;
  name: string;
  src: string;
  status: CameraStatus;
  latitude: number | null;
  longitude: number | null;
  isrusnya: boolean;
  folderName: string | null;
  color: string;
  isPTZ?: boolean;
}

export interface VmsSettings {
  login: string;
  password: string;
  ip: string;
  port: number;
}

export interface VmsApiEvent {
  id: number;
  message: string;
  createdAt: string;
  cameraName: string;
  type: string;
}

export interface VmsEvent extends VmsApiEvent {
  date: string;
}

export type VmsCameraFolder = {
  folders: VmsCameraFolder[];
  cameras: VmsCamera[];
  name?: string;
};
