import React from 'react';
import { HashRouter } from 'react-router-dom';
import RouterComponent from './pages';
import { TextResourceProvider } from './contexts/TextResource';
import { store } from 'store';
import { Provider } from 'react-redux';
import ModalContextProvider from './contexts/ModalContext';
import MapServiceContextProvider from './contexts/MapServiceContext';
import { MyToaster } from 'components/Toaster';
import { EventBusProvider } from './contexts/EventBusContext';
import ErrorBoundary from 'components/ErrorBoundary';

const Root = () => (
  <div className='layout layout-width'>
    <Provider store={store}>
      <HashRouter>
        <TextResourceProvider>
          <ModalContextProvider>
            <MapServiceContextProvider>
              <EventBusProvider>
                <ErrorBoundary>
                  <RouterComponent />
                </ErrorBoundary>
                <MyToaster />
              </EventBusProvider>
            </MapServiceContextProvider>
          </ModalContextProvider>
        </TextResourceProvider>
      </HashRouter>
    </Provider>
  </div>
);

export default Root;
