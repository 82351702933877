import React from 'react';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import Input from 'components/Input/Input';
import { coordinateValidationError, ipValidation, optionalPortValidation } from 'components/Input/validations';
import SingleSelect from 'components/MultiSelect/SingleSelect';
import Button, { ButtonType } from 'components/Button';
import { AddStationBody, StationType } from 'types';
import TextResourceContext from 'contexts/TextResource';
import { useBoolean } from 'usehooks-ts';
import { isNumber } from 'lodash';
import { getDefaultCoordsView } from 'utils/common';

const stationsOptions = Object.values(StationType).map(val => ({
  value: val,
  label: val
}));

interface BaseStationModalProps {
  onSubmit: (settings: Partial<AddStationBody>) => Promise<unknown>;
  initialSettings?: Partial<AddStationBody>;
  isAddStationMode?: boolean;
  stationTypeProp?: StationType;
  title: string;
}

const BaseStationModal: React.FC<BaseStationModalProps> = ({
  onSubmit,
  initialSettings = {},
  title,
  stationTypeProp = null,
  isAddStationMode = false
}) => {
  const [newStationSettings, setNewStationSettings] = React.useState<Partial<AddStationBody>>(
    getDefaultCoordsView(initialSettings)
  );
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const [stationType, setStationType] = React.useState<StationType | null>(stationTypeProp);
  const { value: isLoading, setValue: setIsLoading } = useBoolean(false);

  const onChangeSettings = React.useCallback(
    (key: string) => (value: string | number) => {
      if (key === 'port') {
        // @ts-ignore
        setNewStationSettings(set => ({ ...set, [key]: isNumber(+value) ? +value : value }));
      } else {
        setNewStationSettings(set => ({ ...set, [key]: value }));
      }
    },
    []
  );

  const onClickSubmit = React.useCallback(() => {
    if (stationType) {
      setIsLoading(true);
      onSubmit({ ...newStationSettings, type: stationType }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [stationType, newStationSettings, onSubmit]);

  const submitIsDisabled =
    !newStationSettings.name ||
    !newStationSettings.ip ||
    !newStationSettings.latitude ||
    !newStationSettings.longitude ||
    (isAddStationMode && !stationType);

  return (
    <div className='text-center'>
      <Text size={TextSize.MdLg} className='text-center uppercase mb-4' color={TextColor.White}>
        {title}
      </Text>
      <Input
        value={newStationSettings.name}
        onSetValue={onChangeSettings('name')}
        label={getTextResourceByKey('name') as string}
        placeholder={getTextResourceByKey('stationName') as string}
        labelClassName='add-station-component__label'
      />
      <Input
        value={newStationSettings.location}
        onSetValue={onChangeSettings('location')}
        label={getTextResourceByKey('location') as string}
        placeholder={getTextResourceByKey('location') as string}
        labelClassName='add-station-component__label'
      />
      <Input
        value={newStationSettings.ip}
        onSetValue={onChangeSettings('ip')}
        placeholder={getTextResourceByKey('ip') as string}
        label={getTextResourceByKey('ip') as string}
        labelClassName='add-station-component__label'
        validations={[ipValidation]}
      />
      <Input
        value={newStationSettings.port}
        onSetValue={onChangeSettings('port')}
        placeholder={getTextResourceByKey('port') as string}
        label={getTextResourceByKey('port') as string}
        labelClassName='add-station-component__label'
        validations={[optionalPortValidation]}
        withEmptyStringValidation={false}
      />
      <Input
        value={newStationSettings.latitude}
        onSetValue={onChangeSettings('latitude')}
        placeholder={getTextResourceByKey('latitude') as string}
        validations={[coordinateValidationError]}
        label={getTextResourceByKey('latitude') as string}
        labelClassName='add-station-component__label'
      />
      <div className='mb-3'>
        <Input
          value={newStationSettings.longitude}
          onSetValue={onChangeSettings('longitude')}
          placeholder={getTextResourceByKey('longitude') as string}
          validations={[coordinateValidationError]}
          label={getTextResourceByKey('longitude') as string}
          labelClassName='add-station-component__label'
        />
      </div>
      {isAddStationMode && (
        <div className='flex mb-3'>
          <Text className='add-station-component__label mr-2' color={TextColor.Light}>
            {getTextResourceByKey('StationType')}
          </Text>
          <SingleSelect
            options={stationsOptions}
            // @ts-ignore
            onChange={setStationType}
            className='add-station-component__select w-100'
            labelColor={TextColor.Gray}
          />
        </div>
      )}
      {/*<SelectStation setStationType={setStationType} stationType={stationType} className="mb-3"/>*/}
      <div className='flex-justify-end'>
        <Button
          buttonType={ButtonType.Success}
          className='add-station-component__add-btn'
          role='button'
          onClick={onClickSubmit}
          disabled={submitIsDisabled}
          loading={isLoading}
        >
          {getTextResourceByKey('submit')}
        </Button>
      </div>
    </div>
  );
};

export default BaseStationModal;
