import React from 'react';
import TextResourceContext from 'contexts/TextResource';
import { useSelector } from 'react-redux';
import { getStations } from 'store/stationsReducer';
import { NewUser } from 'types';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import { isEmpty, xor } from 'lodash';
import Input from 'components/Input';
import { passwordValidation } from 'components/Input/validations';
import Button, { ButtonType } from 'components/Button';
import MenuCheckBoxes from 'components/MultiSelect/MenuCheckBoxes';
import { getVmsCamerasFolder, getVmsCamerasState } from 'store/vmsReducer';
import { Option } from 'components/MultiSelect/types';
import { UsersRole } from 'types';
import CheckboxCamerasFolder from './CheckboxCamerasFolder';

interface UserTemplateComponentProps {
  initialState?: Partial<NewUser>;
  onSubmit: (userInfo: Partial<NewUser>) => void;
  title: string | React.ReactNode;
  submitTitle: string | React.ReactNode;
  isEditMode?: boolean;
}

const optionalPasswordValidation = (val: string | number) => (val ? passwordValidation(val) : null);

const UserTemplateComponent: React.FC<UserTemplateComponentProps> = ({
  initialState = {},
  onSubmit,
  title,
  submitTitle,
  isEditMode
}) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const { data: stations, loaded: stationsLoaded } = useSelector(getStations);
  const { data: cameras, loaded: camerasLoaded } = useSelector(getVmsCamerasState);
  const camerasFolder = useSelector(getVmsCamerasFolder);
  const [userFields, setUserFields] = React.useState<Partial<NewUser>>(initialState);

  const refValidationsCbRef = React.useRef<{ [key: string]: () => void }>({});
  const [submitInProcessing, setSubmitInProcessing] = React.useState(false);

  const isAdmin = UsersRole.Admin === userFields.name?.toLocaleUpperCase();

  const onChangeValue = React.useCallback(
    (key: string) => (value: string | { value: string | number } | number | (string | number)[]) => {
      setUserFields(set => ({ ...set, [key]: value }));
    },
    []
  );

  const onSelectStations = (option: Option) => {
    const stationIds = userFields['stationIds'];

    onChangeValue('stationIds')(xor(stationIds, [option.value]));
  };

  const onSelectCameras = (camIds: number[], mode = 'toggle') => {
    const cameraIds = userFields['cameraIds'] || [];

    if (mode === 'select') {
      onChangeValue('cameraIds')(Array.from(new Set([...cameraIds, ...camIds])));
    } else if (mode === 'deselect') {
      onChangeValue('cameraIds')(cameraIds.filter(camId => !camIds.includes(camId)));
    } else {
      onChangeValue('cameraIds')(xor(cameraIds, camIds));
    }
  };

  const onClickAllStations = () => {
    const stationIds = userFields['stationIds'] || [];

    if (stations && stationIds) {
      const allStationsAreSelected = stationIds.length === stations.length;

      onChangeValue('stationIds')(allStationsAreSelected ? [] : stations.map(st => st.id));
    }
  };

  const onSetValidationCb = React.useCallback(
    (id: string) => (cb: () => void) => {
      refValidationsCbRef.current[id] = cb;
    },
    []
  );

  const onSubmitWithValidation = () => {
    const isValid = !Object.values(refValidationsCbRef.current).some(getIsNotValid => getIsNotValid());

    if (isValid) {
      setSubmitInProcessing(true);
      onSubmit({ ...userFields, password: isEmpty(userFields.password) ? undefined : userFields.password });
    }
  };

  return (
    <div className='flex-column flex-space-between add-user-modal' style={{ minHeight: '300px' }}>
      <div>
        <Text size={TextSize.MdLg} className='text-center uppercase mb-3'>
          {title}
        </Text>
        <div className='d-flex flex-column w-100 mb-5 mt-5'>
          <div className='mb-3'>
            <Text size={TextSize.Sm} color={TextColor.Silver} className='ml-2'>
              {getTextResourceByKey('userName')}
            </Text>
            <Input
              value={userFields.name}
              onSetValue={onChangeValue('name')}
              placeholder={getTextResourceByKey('userName') as string}
              wrapperClassName='add-user-modal__input'
              setValidationCb={onSetValidationCb('userName')}
            />
          </div>

          <div className='mb-3'>
            <Text size={TextSize.Sm} color={TextColor.Silver} className='ml-2'>
              {getTextResourceByKey('login')}
            </Text>
            <Input
              value={userFields.login}
              onSetValue={onChangeValue('login')}
              placeholder={getTextResourceByKey('login') as string}
              wrapperClassName='add-user-modal__input'
              setValidationCb={onSetValidationCb('login')}
            />
          </div>

          <div className='mb-3'>
            <Text size={TextSize.Sm} color={TextColor.Silver} className='ml-2'>
              {getTextResourceByKey('password')}
            </Text>
            <Input
              value={userFields.password}
              onSetValue={onChangeValue('password')}
              validations={[optionalPasswordValidation]}
              withEmptyStringValidation={!isEditMode}
              placeholder={getTextResourceByKey('password') as string}
              setValidationCb={onSetValidationCb('password')}
              wrapperClassName='add-user-modal__input'
              type='password'
            />
          </div>
          {!isAdmin && (
            <div className='d-flex'>
              {stationsLoaded && stations && (
                <div className='w-100'>
                  <Text color={TextColor.Silver} className='flex mb-3'>
                    {getTextResourceByKey('stations')}
                  </Text>
                  <div style={{ maxWidth: '400px', maxHeight: '600px' }} className='overflow-auto'>
                    <MenuCheckBoxes
                      options={stations.map(st => ({ value: st.id, label: st.name, searchValue: st.name }))}
                      onToggleCheckBox={onSelectStations}
                      selectedOptionValues={userFields.stationIds || []}
                      onClickAll={onClickAllStations}
                    />
                  </div>
                </div>
              )}
              {camerasLoaded && cameras && (
                <div className='w-100'>
                  <Text color={TextColor.Silver} className='flex mb-3'>
                    {getTextResourceByKey('cameras')}
                  </Text>
                  <div style={{ maxWidth: '400px', maxHeight: '1000px' }} className='overflow-auto pr-2'>
                    <CheckboxCamerasFolder
                      isDefaultExpanded
                      folder={camerasFolder}
                      onSelectCameras={onSelectCameras}
                      selectedCameraIds={userFields.cameraIds || []}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <Button
            buttonType={ButtonType.Success}
            className='add-user-modal__add-btn align-self-end mt-4'
            role='button'
            onClick={onSubmitWithValidation}
            loading={submitInProcessing}
          >
            {submitTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserTemplateComponent;
