import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/types';
import { fetchVmsStatus, getVmsStatus } from 'store/vmsReducer';
import Text, { TextSize } from 'components/Text';
import { COLORS } from 'consts';
import { ConnectionType } from 'types';

const VmsConnectionStatus = () => {
  const status = useSelector(getVmsStatus);
  const dispatch = useDispatch() as AppDispatch;

  React.useEffect(() => {
    dispatch(fetchVmsStatus());
  }, []);

  const getBackgroundColor = () => {
    if (status.connectionStatus === ConnectionType.Disconnected) {
      return COLORS.danger;
    }

    if (status.connectionStatus === ConnectionType.Connected) {
      return COLORS.success;
    }

    return COLORS.warning;
  };

  return (
    <div className='d-flex position-relative align-items-center'>
      <div
        className='absolute-centered-y rounded-circle'
        style={{ width: '10px', height: '10px', background: getBackgroundColor() }}
      />
      <Text size={TextSize.Sm} className='ml-3'>
        {status.connectionStatus}
        {status.message && <Text size={TextSize.Sm}>{status.message}</Text>}
      </Text>
    </div>
  );
};

export default VmsConnectionStatus;
