import React from 'react';
import UserTemplate from './UserTemplate';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/types';
import TextResourceContext from 'contexts/TextResource';
import { NewUser, PageRoutes } from 'types';
import { addUserAction } from 'store/usersReducer';
import { useNavigate } from 'react-router-dom';
import { extendUrlByQueries } from 'utils/api';
import HeaderComponent from 'components/HeaderComponent';
import { COLORS, LOGO_SIZE } from 'consts';
import useFetchDataForUserPage from './useFetchDataForUserPage';
import { FaUserPlus } from 'react-icons/fa';

const AddUserComponent = () => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const navigate = useNavigate();
  const dispatch = useDispatch() as AppDispatch;

  useFetchDataForUserPage();

  const onAddUser = (userFields: Partial<NewUser>) => {
    const stationIds = userFields.stationIds;
    const cameraIds = userFields.cameraIds;

    dispatch(addUserAction({ ...userFields, stationIds, cameraIds } as NewUser)).then(() => {
      navigate(extendUrlByQueries(PageRoutes.AdminPanel, { tabIndex: 1 }));
    });
  };

  return (
    <div>
      <HeaderComponent logo={<FaUserPlus size={LOGO_SIZE} color={COLORS.white} />} />
      <div className='py-5'>
        <UserTemplate
          onSubmit={onAddUser}
          title={getTextResourceByKey('addUser')}
          submitTitle={getTextResourceByKey('add')}
        />
      </div>
    </div>
  );
};

export default AddUserComponent;
