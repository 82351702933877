import React from 'react';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import HTMLBarChart from 'components/HTMLBarChart/HTMLBarChart';
import TextResourceContext from 'contexts/TextResource';
import useGetStatics from 'hooks/useGetStatics';
import { StatusStationModel, StyledComponent } from 'types';
import Calendar from 'components/Calendar';
import { getCustomDateLabel } from 'utils';
import ErrorMessage from 'components/ErrorMessage';

interface StatisticGraphicAmplitudeProps extends StyledComponent {
  station: StatusStationModel;
  onlyToday?: boolean;
}

const StatisticGraphicAmplitude: React.FC<StatisticGraphicAmplitudeProps> = ({ station, className, onlyToday }) => {
  const { data, error, fetchStatistic, loading, setPeriodDate, periodDate } = useGetStatics(station, onlyToday);
  const isManageParamsVisible = !onlyToday;

  const formattedAmplitudeChartData = React.useMemo(
    () =>
      data.map((dataItem, i) => ({
        x: `${dataItem.time} \n ${getCustomDateLabel(dataItem.date)}`,
        y: dataItem.power,
        getLabel: () => (
          <>
            <Text size={TextSize.Sm} color={TextColor.White}>
              f={dataItem.frequency}
            </Text>
            <Text size={TextSize.Sm} color={TextColor.Silver}>
              (p={dataItem.power})
            </Text>
          </>
        ),
        id: dataItem.time + dataItem.power + i,
        extraElement: (getHeight: (val: number) => string) => (
          <div className='extra-element-at-bar' style={{ bottom: getHeight(+dataItem.maxPower) }}>
            {dataItem.maxPower}
          </div>
        )
      })),
    [data]
  );
  const maxValue = React.useMemo(
    () =>
      Math.max.apply(
        null,
        data.map(d => d.maxPower)
      ),
    [data]
  );

  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  return (
    <div className={className}>
      <div className='flex-space-around mb-4'>
        <div className='d-flex'>
          <Text size={TextSize.MdLg} color={TextColor.Success} className='uppercase text-center align-self-center'>
            {getTextResourceByKey('statisticGraphicAmplitude/Frequency')}
          </Text>
        </div>
        {isManageParamsVisible && (
          <div className='d-flex'>
            <Calendar
              placement='bottomRight'
              align={{ offset: [-90, 30] }}
              onSubmit={setPeriodDate}
              defaultValue={periodDate}
            />
          </div>
        )}
      </div>
      {error ? (
        <ErrorMessage message={error.message} />
      ) : (
        <HTMLBarChart
          data={formattedAmplitudeChartData}
          paginationCallback={fetchStatistic}
          xUnit={getTextResourceByKey('time') as string}
          yUnit={getTextResourceByKey('amplitude') as string}
          maxYValue={maxValue}
          isLoading={loading}
        />
      )}
    </div>
  );
};

export default StatisticGraphicAmplitude;
