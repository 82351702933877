import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createEmptyFetchedState } from './utils';
import { FetchingData, UsersResponse, NewUser, ThunkOptions, User } from '../types';
import { RootState } from './types';
import { toastError, toastSuccess } from 'components/Toaster';
import { resetStateAction } from './shared';
import { isString } from 'lodash';

type UsersState = FetchingData<UsersResponse>;
const initialState: UsersState = createEmptyFetchedState([]);

export const fetchUsersAction = createAsyncThunk<UsersResponse>('USERS@GET_USERS', (body, { extra }: ThunkOptions) => {
  const api = extra.api;

  return api.get('users');
});

export const fetchUserAction = createAsyncThunk<User, number>('USERS@GET_USER', (userId, { extra }: ThunkOptions) => {
  const api = extra.api;

  return api.get(`users/${userId}`);
});

export const removeUserAction = createAsyncThunk<number, number>(
  'USERS@REMOVE_USER',
  (userId, { extra: { api } }: ThunkOptions) =>
    api
      .delete(`users/${userId}`)
      .then(() => {
        toastSuccess('userRemovedSuccessfully');

        return userId;
      })
      .catch(() => {
        toastError('userRemovedFailed');
      })
);

export const addUserAction = createAsyncThunk<number, NewUser>(
  'USERS@ADD_USER',
  (body, { dispatch, extra: { api } }: ThunkOptions) =>
    api
      .post('users', body)
      .then((userId: number) => {
        toastSuccess('userAddedSuccessfully');

        return dispatch(fetchUserAction(userId));
      })
      .catch((e: string | Error) => {
        toastError(isString(e) ? e : 'userAddedFailed');

        throw e;
      })
);

export const updateUserAction = createAsyncThunk<Partial<NewUser>, { id: number } & Partial<NewUser>>(
  'USERS@EDIT_USER',
  ({ id, ...body }, { dispatch, extra: { api } }: ThunkOptions) =>
    api
      .patch(`users/${id}`, body)
      .then((userId: number) => {
        toastSuccess('userUpdatedSuccessfully');

        dispatch(fetchUserAction(userId));
      })
      .catch((e: string | Error) => {
        console.log(e);
        toastError(isString(e) ? e : 'userUpdatedFailed');

        throw e;
      })
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUsersAction.pending, state => {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    });
    builder.addCase(fetchUsersAction.rejected, (state, action) => {
      state.loading = false;
      state.loaded = false;
      state.error = action.error;
    });
    builder.addCase(fetchUsersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.loaded = true;
      state.data = action.payload;
    });
    builder.addCase(removeUserAction.fulfilled, (state, action) => {
      state.data = state.data.filter(user => user.id !== action.payload);
    });
    builder.addCase(resetStateAction.fulfilled, () => initialState);
    builder.addCase(fetchUserAction.fulfilled, (state, action) => {
      const userId = action.meta.arg;

      state.data.forEach(user => {
        if (user.id === userId) {
          Object.assign(user, action.payload);
        }
      });
    });
  }
});

export const getUsers = (state: RootState) => state.users;
export const getUsersData = (state: RootState) => getUsers(state).data;
export const getUsersById = (state: RootState, userId: number) => getUsers(state).data.find(user => user.id === userId);

export const usersReducer = usersSlice.reducer;
