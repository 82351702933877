import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/types';
import React from 'react';
import {
  abortAllFrequenciesAction,
  getAllFrequenciesSelector,
  getFrequenciesIsLoading,
  getStationAllFrequencies
} from '../store/frequenciesReducer';
import { StationModel } from '../types';

export default function useGetAllFrequencies(station: StationModel) {
  const dispatch = useDispatch() as AppDispatch;
  const getFrequenciesSelectorCb = React.useMemo(
    () => (state: RootState) => getAllFrequenciesSelector(state, station?.id),
    [station?.id]
  );

  const getStationLoadingCb = React.useCallback(
    (s: RootState) => getFrequenciesIsLoading(s, station?.id),
    [station?.id]
  );

  const frequencies = useSelector(getFrequenciesSelectorCb);
  const loading = useSelector(getStationLoadingCb);

  React.useEffect(() => {
    if (station?.id) {
      dispatch(getStationAllFrequencies({ stationId: station.id }));
    }

    return () => {
      dispatch(abortAllFrequenciesAction());
    };
  }, [station?.id]);

  return { frequencies, loading: loading && frequencies.length === 0 };
}
