import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  type AddStationBody,
  FetchingData,
  type StationModel,
  StationModelBody,
  StatusStationConfiguration,
  ThunkOptions
} from 'types';
import { AppDispatch, AuthResponse, RootState } from './types';
import config from 'config.json';
import UpdateStationsService from '../services/updateStationsService';
import { createEmptyFetchedState } from './utils';
import { toastError, toastSuccess } from 'components/Toaster';
import { isString, keyBy, omit, uniqBy } from 'lodash';
import { AxiosError } from 'axios';
import { resetStateAction } from './shared';

interface StationsState extends FetchingData<StationModel[] | null> {
  nextTimeUpdate: number;
}

const initialState: StationsState = {
  ...createEmptyFetchedState([]),
  nextTimeUpdate: 0
};

export const getStationsAction = createAsyncThunk<StationModel[]>(
  'STATIONS@GET_STATIONS',
  (body, { dispatch, extra }: ThunkOptions) => {
    const api = extra.api;

    api.abortController('STATIONS@GET_STATIONS');
    dispatch(stationsSlice.actions.nextTimeUpdate());

    return api.get('stations', 'STATIONS@GET_STATIONS');
  }
);

export const getStationConfigurationAction = createAsyncThunk<StatusStationConfiguration & { id: number }, number>(
  'STATIONS@GET_STATION_DATA',
  (stationId, { dispatch, extra }: ThunkOptions) => {
    const api = extra.api;

    return api.get(`stations/${stationId}/config`).then((response: AuthResponse) => {
      dispatch(stationsSlice.actions.nextTimeUpdate());

      return response;
    });
  }
);

export const getStationAction = createAsyncThunk<StationModel, number>(
  'STATIONS@GET_STATION',
  (stationId, { extra }: ThunkOptions) => {
    const api = extra.api;

    return api.get(`stations/${stationId}`);
  }
);

export const addStationAction = createAsyncThunk<Partial<StationModel>, AddStationBody>(
  'STATIONS@ADD_STATION',
  (settings, { extra }: ThunkOptions) => {
    const api = extra.api;

    return api
      .post('stations', settings)
      .then((station: StationModel) => {
        toastSuccess('stationAddedSuccessfully');

        return station;
      })
      .catch((e: AxiosError) => {
        const errorIsString = isString(e);

        toastError(errorIsString ? e : 'stationAddedFailed');
        throw e;
      });
  }
);

export const removeStationAction = createAsyncThunk<number, number>(
  'STATIONS@REMOVE_STATION',
  (stationId, { extra }: ThunkOptions) => {
    const api = extra.api;

    return api
      .delete(`stations/${stationId}`)
      .then((response: number) => {
        toastSuccess('stationRemovedSuccessfully');

        return response;
      })
      .catch((err: AxiosError) => {
        const errorIsString = isString(err);

        toastError(errorIsString ? err : 'stationRemovedFailed');

        throw err;
      });
  }
);

export const updateStationAction = createAsyncThunk<StationModelBody, StationModelBody>(
  'STATIONS@UPDATE_STATION',
  ({ stationId, body }, { dispatch, extra }: ThunkOptions) => {
    const api = extra.api;

    return api
      .patch(`stations/${stationId}`, body)
      .then(() => {
        toastSuccess('stationUpdatedSuccessfully');
        dispatch(getStationConfigurationAction(stationId));
        dispatch(getStationAction(stationId));

        return Promise.resolve(true);
      })
      .catch((err: AxiosError) => {
        const errorIsString = isString(err);

        toastError(errorIsString ? err : 'stationUpdatedFailed');

        throw err;
      });
  }
);

const stationsSlice = createSlice({
  name: 'StationModel',
  initialState,
  reducers: {
    nextTimeUpdate: state => {
      state.nextTimeUpdate = Date.now() + config.updateStationsInterval * 60 * 1000; // milliseconds
    }
  },
  extraReducers: builder => {
    builder.addCase(getStationsAction.pending, state => {
      state.loading = true;
    });
    builder.addCase(getStationsAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getStationsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.loaded = true;
      const configurationsMap = keyBy(state.data, 'id') || {};

      state.data = uniqBy(
        action.payload.map(newStation => {
          const configuration = configurationsMap[newStation.id]?.configuration;

          return configuration ? { ...newStation, configuration } : newStation;
        }),
        'id'
      ) as StationModel[];
    });
    builder.addCase(resetStateAction.fulfilled, () => initialState);
    builder.addCase(getStationConfigurationAction.fulfilled, (state, action) => {
      state.data = state.data?.map(station =>
        station.id === action.payload.id ? { ...station, configuration: omit(action.payload, 'id') } : station
      ) as StationModel[];
    });

    builder.addCase(getStationAction.fulfilled, (state, action) => {
      // const configurationsMap = keyBy(state.data, 'id') || {};
      state.data = state.data?.map(station =>
        station.id === action.payload.id ? { ...station, ...action.payload } : station
      ) as StationModel[];
    });

    builder.addCase(addStationAction.fulfilled, (state, action) => {
      state.data?.push(action.payload as StationModel);
    });

    builder.addCase(removeStationAction.fulfilled, (state, action) => {
      state.data = state.data ? state.data?.filter(station => station.id !== action.payload) : state.data;
    });
  }
});

export const getStations = (state: RootState) => state.stations || initialState;
export const getStationsData = (state: RootState) => getStations(state).data || [];
export const getNextTimeUpdate = (state: RootState) => getStations(state).nextTimeUpdate;

export const initIntervalStationsFetching =
  () =>
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    { updateStationsService }: { updateStationsService: UpdateStationsService }
  ) => {
    if (!updateStationsService._initted) {
      const fetchStationsCb = () => dispatch(getStationsAction());

      updateStationsService.initiate(config.updateStationsInterval, fetchStationsCb);
      updateStationsService.start({ leading: true });
    }
  };

export const refreshStationsAction =
  () =>
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    { updateStationsService }: { updateStationsService: UpdateStationsService }
  ) => {
    updateStationsService.reset({ leading: true });
  };

export const StationModelReducer = stationsSlice.reducer;
