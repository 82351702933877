import { ConnectionType, StationStatusType, UsersRole } from 'types';

export const ROLES_MAP: { [key: string]: string } = {
  [UsersRole.Admin]: 'Administrator',
  [UsersRole.Default]: 'default'
};

export const STATION_STATUS_PRIOIRITY = {
  [StationStatusType.Alarm]: 0,
  [StationStatusType.Medium]: 1,
  [ConnectionType.StationIssue]: 2,
  [ConnectionType.Disconnected]: 3,
  [StationStatusType.Normal]: 4,
  [ConnectionType.Connected]: 5,
  [StationStatusType.Learning]: 6,
  [ConnectionType.NotFound]: 7,
  [ConnectionType.WrongResponse]: 8
};

export const COLORS = {
  orange: '#fbb13c',
  primary: '#255f85',
  success: '#4d9078',
  white: '#ffffff',
  light: '#D0CFCF',
  gray: '#a4a4a4',
  danger: '#d62828',
  darkGrey: '#3A3B3C',
  black: '#000',
  pink: '#ea1c79',
  purple: '#a42cea',
  warning: '#ffc008'
};

export const COLOR_MIXIN_VARIABLES = {
  Black: 'black',
  Primary: 'primary',
  Success: 'success',
  Warning: 'warning',
  Danger: 'danger',
  Transparent: 'transparent',
  Light: 'light',
  White: 'white'
};

export const LOGO_SIZE = 40;

export const FREQUENCY = 'frequency';
export const COUNT = 'count';
export const ASC = 'asc';
export const DESC = 'desc';
export const STATUS_PRIORITY = 'statusPriority';
export const NAME = 'name';
export const ALL_STATIONS = 'All';
export const QUERY_FILTER_STATION_KEY = 'stationType';

export const TABS = ['today', 'statistic', 'analytics', 'allFrequencies', 'events'];

export const CAMERAS_ICONS_COLORS_MAP = {
  B: 'blue',
  R: 'red',
  Y: 'yellow'
};
