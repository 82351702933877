import React from 'react';
import { useBoolean } from 'usehooks-ts';
import { AiOutlineCompass } from 'react-icons/ai';
import DrowInstrument from './DrawInstrument';
import { ReactSVG } from 'react-svg';
import { TextColor, TextSize } from 'components/Text';
import Text from 'components/Text/Text';
import TextResourceContext from 'contexts/TextResource';
import './MapInstruments.scss';
import { DrawType } from 'services/OiMapService/DrawService';

interface MapInstrumentsProps {
  children: React.ReactNode;
}

const MapInstruments: React.FC<MapInstrumentsProps> = ({ children }) => {
  const { value: isActiveAzimut, toggle: toggleEnableAzimut, setFalse: setActiveAzimutFalse } = useBoolean(false);
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const [mode, setMode] = React.useState<null | DrawType>(null);

  React.useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setActiveAzimutFalse();
      }
    };

    window.addEventListener('keydown', callback);

    return () => {
      window.removeEventListener('keydown', callback);
    };
  }, []);

  return (
    <div className='relative'>
      {children}
      <div className='p-absolute top-2 right-2 d-flex gap-3 p-2 border-radius-05 bg-dark'>
        <DrowInstrument mode={mode} setMode={setMode} />
        <div onClick={toggleEnableAzimut} className='d-flex align-items-center gap-1 cursor-pointer'>
          <AiOutlineCompass color={isActiveAzimut ? TextColor.Warning : TextColor.HalfLight} size={20} />
          <Text
            color={isActiveAzimut ? TextColor.Warning : TextColor.HalfLight}
            size={TextSize.Sm}
            className='text-uppercase'
          >
            {getTextResourceByKey('azimuth')}
          </Text>
        </div>
      </div>
      {isActiveAzimut && (
        <div className='w-100 h-100'>
          <ReactSVG src={`${process.env.PUBLIC_URL}/Azimuth.svg`} className='azimut-circle__circle absolute-centered' />
        </div>
      )}

      {(isActiveAzimut || mode) && (
        <div className='position-absolute bottom-2 left-2 bg-dark p-2 px-3 border-radius-05'>
          <Text color={TextColor.Warning}>{getTextResourceByKey('mapInstrumentsInfo')}</Text>
        </div>
      )}
    </div>
  );
};

export default React.memo(MapInstruments);
