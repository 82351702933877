import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DroneModel, DronesReducerState, DronesResponse, FetchingData, ThunkOptions } from '../types';
import { RootState } from './types';
import { createEmptyFetchedState } from './utils';
import { toastError } from '../components/Toaster';
import { keyBy, last } from 'lodash';
import { resetStateAction } from './shared';
// import dronesResponse from 'mocks/drones.json';

interface InitialState {
  [key: string]: DronesReducerState;
}
const initialState: InitialState = {};

const getStationDronesIsPending = createAction<{ stationId: number }>('getStationDronesIsPending');

export const resetStationDrones = createAction<number>('resetStationDrones');

export const getStationDrones = createAsyncThunk<
  { data: DroneModel[]; stationId: number },
  { stationId: number; startDate?: string; endDate?: string }
>('DRONES@GET_DRONES', ({ stationId, startDate, endDate }, { dispatch, extra }: ThunkOptions) => {
  const api = extra.api;

  dispatch(getStationDronesIsPending({ stationId }));
  const baseUrl =
    startDate && endDate ? `drones/${stationId}?startDate=${startDate}&endDate=${endDate}` : `drones/${stationId}`;

  return api
    .get(baseUrl)
    .then((response: DronesResponse) => ({ stationId, data: response.drones }))
    .catch((error: Error) => ({ stationId, response: error.message }));

  // return new Promise<DronesResponse>(resolve => {
  //   setTimeout(() => {
  //     console.log(dronesResponse);
  //     resolve(dronesResponse as DronesResponse);
  //   }, 1000);
  // })
  //   .then((response: DronesResponse) => ({ stationId, data: response.drones }))
  //   .catch((error: Error) => ({ stationId, response: error.message }));
});

export const getDronesCurrentLocationAction = createAsyncThunk<
  { data: DroneModel[]; stationId: string },
  { stationId: number }
>('DRONES@GET_DRONES_CURRENT_LOCATION', ({ stationId }, { extra, getState }: ThunkOptions) => {
  const api = extra.api;
  const rootState = getState();
  const stationState = getDronesStationSelector(rootState, stationId);

  if (stationState.data.length > 0) {
    return api
      .get(`drones/${stationId}/current`)
      .then((response: DronesResponse) => ({ stationId, data: response.drones }))
      .catch((error: Error) => {
        toastError('failedRequest');

        return { stationId, response: error.message };
      });
  }
});

const dronesSlice = createSlice({
  name: 'stationDrones',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStationDrones.rejected, (state, action) => {
      if (action.payload) {
        const { stationId, error } = action.payload as { stationId: string; error: string };

        state[stationId] = {
          ...state[stationId],
          error
        };
      }
    });
    builder.addCase(getStationDrones.fulfilled, (state, action) => {
      const { stationId, data } = action.payload;

      state[stationId] = {
        loading: false,
        loaded: true,
        error: null,
        data
      };
    });
    builder.addCase(getStationDronesIsPending, (state, action) => {
      const { stationId } = action.payload;
      const prevState = state.stationId || createEmptyFetchedState([]);

      state[stationId] = {
        ...prevState,
        loading: true,
        error: null
      };
      state[stationId].loading = true;
    });
    builder.addCase(resetStateAction.fulfilled, () => initialState);
    builder.addCase(resetStationDrones, (state, action) => {
      const stationId = action.payload;

      state[stationId] = createEmptyFetchedState([]);
    });
    builder.addCase(getDronesCurrentLocationAction.fulfilled, (state, action) => {
      const { stationId } = action.payload;

      const newDronesMap = keyBy(action.payload.data, 'droneId');

      state[stationId] = {
        ...state[stationId],
        data: state[stationId].data.map(drone => {
          if (drone.flights.length === 1) {
            const { latitude, longitude } = last(drone.flights[0].locationHistory) || {};

            if (latitude && longitude) {
              const newLocation = newDronesMap[drone.droneId].currentLocation;

              if (newLocation.latitude !== latitude || newLocation.longitude !== longitude) {
                drone.flights[0].locationHistory.push(newLocation);
              }
            }
          }

          return drone;
        })
      };
    });
  }
});

const defaultDronesStationData = createEmptyFetchedState([]);

export const dronesReducer = dronesSlice.reducer;
export const getDronesStationSelector = (state: RootState, stationId: number): FetchingData<DroneModel[]> =>
  stationId ? state.drones[stationId] || defaultDronesStationData : defaultDronesStationData;
