import React from 'react';
import Card from 'components/Card';
import Text, { TextColor, TextSize } from 'components/Text';
import TextResourceContext from 'contexts/TextResource';
import { HiPlus } from 'react-icons/hi';
import { COLORS } from 'consts';

interface AddStationCardProps {
  onClick: () => void;
}

const AddStationCard: React.FC<AddStationCardProps> = ({ onClick }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  return (
    <Card onClick={onClick} bodyClassName='d-flex flex-column h-100 justify-content-center'>
      <div className='text-center'>
        <HiPlus size='40' color={COLORS.gray} />
      </div>
      <Text size={TextSize.Md} className='text-center mb-2 text-uppercase' color={TextColor.White}>
        {getTextResourceByKey('newStation')}
      </Text>
      <Text className='text-center mb-3' size={TextSize.Sm}>
        {getTextResourceByKey('clickToAddNewStation')}
      </Text>
    </Card>
  );
};

export default React.memo(AddStationCard);
