import React from 'react';
import { StatusStationModel } from 'types';
import AnalyticsCounts from './AnalyticsCounts';
import AnalyticsFrequencies from './AnalyticsFrequencies';
import TabsPanel from 'components/TabsPanel';
import { SUBTAB_INDEX_PREFIX } from 'components/TabsPanel/TabsPanel';
import { getDateOfSomeDaysAgo } from 'utils/date';

import './AnalyticsWidget.scss';

interface AnalyticsWidgetProps {
  station: StatusStationModel;
}

const analyticsTabs = ['analytics/counts', 'frequencies'];

const pageSize = 50;
const firstPeriodDefaultVal = [getDateOfSomeDaysAgo(30), new Date()];
const secondPeriodDefaultVal = [getDateOfSomeDaysAgo(7), new Date()];

const AnalyticsWidget: React.FC<AnalyticsWidgetProps> = ({ station }) => {
  const tabsPanels = React.useMemo(
    () =>
      station
        ? [
            <AnalyticsCounts
              station={station}
              pageSize={pageSize}
              firstPeriodDefaultVal={firstPeriodDefaultVal}
              secondPeriodDefaultVal={secondPeriodDefaultVal}
            />,
            <AnalyticsFrequencies
              station={station}
              pageSize={pageSize}
              firstPeriodDefaultVal={firstPeriodDefaultVal}
              secondPeriodDefaultVal={secondPeriodDefaultVal}
            />
          ]
        : [],
    [station]
  );

  return (
    <TabsPanel
      tabs={analyticsTabs}
      tabsPanels={tabsPanels}
      tabsListBodyClassName='inner-tabs-position-right position-fixed'
      searchQuery={`${SUBTAB_INDEX_PREFIX}Analytics`}
    />
  );
};

export default AnalyticsWidget;
