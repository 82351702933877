import React from 'react';
import { COLORS } from 'consts';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import CardsLayout from 'components/CardsLayout';
import Card from 'components/Card';
import StationLabel from 'components/StationLabel';
import { FaClock } from 'react-icons/fa';
import {
  isConnectionStatusEvent,
  isElescopeStatusEvent,
  isHrushchEvent,
  isStatusTypeEvent,
  isStrausEvent
} from 'utils/events';
import Pagination from 'components/Pagination';
import TruncatedText from 'components/Text/TruncatedText';
import { Event } from 'types';

import './EventsPaginationWidget.scss';
import { getConnectionStatusEventBg } from './utils';

interface EventsPaginationWidgetProps {
  eventsMap: {
    [k: string]: Event[];
  };
  fetchNextPage: () => void;
}

const EventsPaginationWidget: React.FC<EventsPaginationWidgetProps> = ({ eventsMap, fetchNextPage }) => (
  <Pagination callback={fetchNextPage}>
    {Object.entries(eventsMap).map(([date, events]) => (
      <div className='mb-4' key={date}>
        <Text color={TextColor.White} className='events-widget__section-date position-sticky ml-2 mb-2 d-inline'>
          {date}
        </Text>
        <CardsLayout cardMinWidth='200px'>
          {events.map(event => (
            <Card
              key={event.id}
              bottomBar={
                <div className={getConnectionStatusEventBg(event)}>
                  {isConnectionStatusEvent(event) && (
                    <div className='d-flex justify-content-around px-2 py-1'>
                      <Text color={TextColor.White} size={TextSize.XSm}>
                        {event.data.previousConnectionStatus}
                      </Text>
                      <Text color={TextColor.White} size={TextSize.XSm}>
                        {`${'>>'}`}{' '}
                      </Text>
                      <Text color={TextColor.White} size={TextSize.XSm}>
                        {event.data.connectionStatus}
                      </Text>
                    </div>
                  )}
                  {isElescopeStatusEvent(event) && (
                    <div className='d-flex justify-content-around px-2 py-1'>
                      <Text color={TextColor.White} size={TextSize.XSm}>
                        {event.data.droneId}
                      </Text>
                      <Text color={TextColor.White} size={TextSize.XSm}>
                        {event.data.droneType}
                      </Text>
                    </div>
                  )}
                  {isStatusTypeEvent(event) && (isStrausEvent(event) || isHrushchEvent(event)) && (
                    <div className='d-flex justify-content-around px-2 py-1'>
                      <Text color={TextColor.White} size={TextSize.XSm}>
                        {event.data.previousStatus}
                      </Text>
                      <Text color={TextColor.White} size={TextSize.XSm}>
                        {`${'>>'}`}{' '}
                      </Text>
                      <Text color={TextColor.White} size={TextSize.XSm}>
                        {event.data.currentStatus}
                      </Text>
                    </div>
                  )}
                </div>
              }
            >
              <div className='d-flex justify-content-between mt-1 mb-1 h-100'>
                <div className='d-flex gap-1 w-100 align-items-start'>
                  <StationLabel stationType={event.box.type} size={24} />
                  <div className='d-flex flex-column w-75 h-75'>
                    <TruncatedText
                      className='no-white-space overflow-hidden text-overflow-elipsis'
                      size={TextSize.Default}
                      color={TextColor.White}
                    >
                      {event.box.name}
                    </TruncatedText>
                    <TruncatedText
                      size={TextSize.XSm}
                      className='align-self-end no-white-space overflow-hidden text-overflow-elipsis'
                    >
                      {event.box.location}
                    </TruncatedText>
                  </div>
                  <div>
                    <div className='d-flex flex-row flex-justify-end'>
                      <FaClock size={8} className='mr-1' color={COLORS.gray} />
                      <Text size={TextSize.XSm} color={TextColor.Light} className='no-white-space text-end'>
                        {event.time}
                      </Text>
                    </div>

                    {isHrushchEvent(event) && isStatusTypeEvent(event) && event.data.frequency && event.data.mac && (
                      <div className='grid-table-1-1'>
                        <Text size={TextSize.XSm} color={TextColor.Light}>
                          fr
                        </Text>
                        <Text size={TextSize.XSm} color={TextColor.White} className='no-white-space'>
                          {event.data.frequency}
                        </Text>
                        <Text size={TextSize.XSm} color={TextColor.Light}>
                          id
                        </Text>
                        <Text size={TextSize.XSm} color={TextColor.White} className='no-white-space'>
                          {event.data.mac}
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </CardsLayout>
      </div>
    ))}
  </Pagination>
);

export default EventsPaginationWidget;
