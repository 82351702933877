import React from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import TextResourceContext from 'contexts/TextResource';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, getAuth } from 'store/authReducer';
import { AppDispatch } from 'store/types';
import { useNavigate } from 'react-router-dom';
import { getRequestIsRejected } from 'store/utils';
import { noop, throttle } from 'lodash';

import './AuthFormComponent.scss';

const AuthFormComponent = () => {
  const [login, setLogin] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const { loading: isProcessingAutentification, error } = useSelector(getAuth);
  const [errorMessage, setErrorMessage] = React.useState<React.ReactNode>(error?.message);
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const dispatch = useDispatch() as AppDispatch;
  const navigate = useNavigate();
  const ref = React.useRef<HTMLDivElement>(null);
  const onSubmitRef = React.useRef<() => void>(noop);

  const clearErrorMessage = React.useCallback(() => {
    setErrorMessage('');
  }, []);

  const onSetInputValue = React.useCallback(
    (handler: (val: string | number) => void) => (value: string | number) => {
      handler(value);
    },
    []
  );

  const onSubmit = React.useCallback(
    throttle(
      () => {
        if (login && password) {
          dispatch(signIn({ login, password })).then(res => {
            const isRejected = getRequestIsRejected(res);

            if (isRejected) {
              setPassword('');
              setLogin('');
              setErrorMessage(getTextResourceByKey('authFailed'));
            } else {
              navigate('/home');
            }
          });
        }
      },
      200,
      { trailing: false }
    ),
    [login, password]
  );

  onSubmitRef.current = onSubmit;

  React.useEffect(() => {
    const cb = (event: KeyboardEvent) => {
      if (event.keyCode === 13) {
        onSubmitRef.current();
      }
    };

    window.addEventListener('keydown', cb);

    return () => window.removeEventListener('keydown', cb);
  }, []);

  return (
    <div className='auth_form__container' ref={ref}>
      <div className='mb-4'>
        <div className='auth_form__input-wrapper'>
          <Input
            value={login}
            onSetValue={onSetInputValue(setLogin as (val: string | number) => void)}
            className='auth_form__input'
            placeholder={getTextResourceByKey('authLoginPlaceHolder') as string}
            type='text'
            onFocus={clearErrorMessage}
          />
        </div>
        <div className='auth_form__input-wrapper'>
          <Input
            value={password}
            onSetValue={onSetInputValue(setPassword as (val: string | number) => void)}
            className='auth_form__input'
            placeholder={getTextResourceByKey('authPasswordPlaceholder') as string}
            type='password'
            onFocus={clearErrorMessage}
          />
        </div>
        {errorMessage && <span className='auth_form__error-msg'>{errorMessage}</span>}
      </div>
      <Button
        type='button'
        className='auth_form__sbm-btn'
        onClick={onSubmit}
        disabled={!login || !password}
        loading={isProcessingAutentification}
      >
        {getTextResourceByKey('authSubmitButton')}
      </Button>
    </div>
  );
};

export default AuthFormComponent;
