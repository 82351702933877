import React, { ReactElement } from 'react';
import useGetStation from 'hooks/useGetStation';
import { isElescopeStation, isStatusStation, PageRoutes } from 'types';
import { useNavigate } from 'react-router-dom';
import HeaderComponent from 'components/HeaderComponent';
import Spinner from 'components/Spinner';
import EmptyState from 'components/EmptyState';
import { CiSettings } from 'react-icons/ci';
import { COLORS, LOGO_SIZE } from 'consts';
import StatusSettingsComponent from './StatusSettingsComponent';
import ElescopeSettingsComponent from '../elescope/ElescopeSettingsComponent';

import './SettingsComponent.scss';

const SettingsComponent = () => {
  const { loading, loaded, station } = useGetStation();
  const navigate = useNavigate();
  const [extraNavbarComponent, setExtraNavbarComponent] = React.useState<ReactElement | null>(null);

  if (loaded && !station) {
    navigate(PageRoutes.Dashboard);
  }

  if (loading) {
    return <Spinner />;
  }

  if (!station) {
    return <EmptyState />;
  }

  return (
    <div className='station-settings'>
      <HeaderComponent
        logo={<CiSettings size={LOGO_SIZE} color={COLORS.white} />}
        extraComponent={extraNavbarComponent}
      />
      {isStatusStation(station) && (
        <StatusSettingsComponent station={station} setExtraNavbarComponent={setExtraNavbarComponent} />
      )}
      {isElescopeStation(station) && (
        <ElescopeSettingsComponent station={station} setExtraNavbarComponent={setExtraNavbarComponent} />
      )}
    </div>
  );
};

export default SettingsComponent;
