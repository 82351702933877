import React from 'react';
import { Frequency } from 'types';
import { CurrentFrequenciesAmplitudeCharDB, CurrentFrequenciesCountChart } from '../CurrentFrequenciesAmplitudeCharDB';
import Spinner from 'components/Spinner';

interface StationChartsComponentProps {
  frequencies: Frequency[];
  loading?: boolean;
}

const StationCurrentCharts: React.FC<StationChartsComponentProps> = ({ frequencies, loading }) => {
  if (loading) {
    return <Spinner isFullSize />;
  }

  return (
    <>
      <CurrentFrequenciesAmplitudeCharDB frequencies={frequencies} />
      <CurrentFrequenciesCountChart frequencies={frequencies} />
    </>
  );
};

export default React.memo(StationCurrentCharts);
