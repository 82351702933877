import React from 'react';
import Pagination from 'components/Pagination';
import CardsLayout from 'components/CardsLayout';
import Card from 'components/Card';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import { StatisticItem, StatusStationModel } from 'types';
import TextResourceContext from 'contexts/TextResource';
import useGetStatics from 'hooks/useGetStatics';
import { transform } from 'lodash';
import Spinner from 'components/Spinner';
import EmptyState from 'components/EmptyState';

interface StatisticGridProps {
  station: StatusStationModel;
  onlyToday?: boolean;
}

const StatisticGrid: React.FC<StatisticGridProps> = ({ station, onlyToday }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const { loading, fetchStatistic, data } = useGetStatics(station, onlyToday);

  const dataMapByDate = React.useMemo(
    () =>
      transform(
        data,
        (acc: { [key: string]: StatisticItem[] }, dataItem) => {
          if (!acc[dataItem.date]) {
            acc[dataItem.date] = [];
          }
          acc[dataItem.date].push(dataItem);

          return acc;
        },
        {}
      ),
    [data]
  );

  const cards = React.useMemo(
    () =>
      Object.entries(dataMapByDate).map(([date, statisticItems]) => (
        <div key={date} className='mb-3'>
          <Text className='mx-3 mb-1' color={TextColor.HalfLight} size={TextSize.Md}>
            {date}
          </Text>
          <CardsLayout cardMinWidth='200px'>
            {statisticItems.map((dataItem, i) => (
              <Card key={`${dataItem.frequency}-${i}`} className='py-0 px-1'>
                <div className='d-flex justify-content-between mb-2 text-center'>
                  <div>
                    <Text size={TextSize.Sm} className='mb-1' color={TextColor.HalfLight}>
                      {getTextResourceByKey('frequency')}
                    </Text>
                    <Text size={TextSize.Md} color={TextColor.White}>
                      {dataItem.frequency}
                    </Text>
                  </div>
                  <div className='d-flex gap-1 align-self-center'>
                    {/*<FaClock size={16} />*/}
                    <Text size={TextSize.Sm} color={TextColor.Light} className='text-underline'>
                      {dataItem.time}
                    </Text>
                  </div>
                </div>
                <div className='d-flex w-100 justify-content-between align-items-baseline text-center'>
                  <div>
                    <Text color={TextColor.Light} size={TextSize.Sm} className='mb-1'>
                      {getTextResourceByKey('width')}
                    </Text>
                    <Text size={TextSize.Default} color={TextColor.Light}>
                      {dataItem.band}
                    </Text>
                  </div>
                  <div>
                    <div>
                      <Text color={TextColor.Warning} size={TextSize.Sm} className='d-inline-block mr-1'>
                        {getTextResourceByKey('power')}, {'dB'}
                      </Text>
                      <Text color={TextColor.HalfLight} size={TextSize.Sm} className='d-inline-block'>
                        (max)
                      </Text>
                    </div>
                    <div className='text-end'>
                      <Text color={TextColor.Warning} size={TextSize.Default} className='d-inline-block mr-1'>
                        {dataItem.power}
                      </Text>
                      <Text color={TextColor.HalfLight} size={TextSize.Default} className='d-inline-block'>
                        ({dataItem.maxPower})
                      </Text>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </CardsLayout>
        </div>
      )),
    [dataMapByDate]
  );

  return (
    <>
      <Pagination callback={fetchStatistic} loading={data.length > 0 && loading}>
        {cards}
        {data.length === 0 && (loading ? <Spinner isFullSize /> : <EmptyState />)}
      </Pagination>
    </>
  );
};

export default StatisticGrid;
