import React from 'react';
import { BaklansStationModel } from 'types/Baklans';
import { useGetStationEvents, StationEventsWidget } from 'pages/shared/StationEvents';
import Calendar from 'components/Calendar';
import MultiSelectCheckBoxes from 'components/MultiSelect/MultiSelectCheckBoxes';
import { Option } from 'components/MultiSelect/types';
import { BAKLANS_STATUSES } from 'pages/events/utils';

interface BaklansStationComponentProps {
  station: BaklansStationModel;
}

const statusesFilterList = BAKLANS_STATUSES.map(status => ({
  value: status,
  label: status
}));

const BaklansStationComponent: React.FC<BaklansStationComponentProps> = ({ station }) => {
  const { onSubmitCalendar, loading, eventsMap, fetchNextPage, onSetStatusFilter, onSetAllStatusesFilters } =
    useGetStationEvents(station, BAKLANS_STATUSES);

  const onSelectStatus = (option: Option) => {
    onSetStatusFilter(option.value as string);
  };

  return (
    <>
      <div className='filters-placeholder d-flex gap-3 align-items-center justify-content-end position-fixed layout-width'>
        <MultiSelectCheckBoxes
          placeholder='selectStatus'
          options={statusesFilterList}
          initialSelectedValues={BAKLANS_STATUSES}
          onChange={onSelectStatus}
          onSetAllOptions={onSetAllStatusesFilters}
        />
        <Calendar placement='leftBottom' align={{ offset: [-90, -30] }} className='mr-2' onSubmit={onSubmitCalendar} />
      </div>
      <StationEventsWidget className='pt-5' loading={loading} eventsMap={eventsMap} fetchNextPage={fetchNextPage} />
    </>
  );
};

export default React.memo(BaklansStationComponent);
