export function playSingleSound(name: string) {
  let el: HTMLAudioElement | null = document.createElement('audio');

  el.classList.add('hidden');
  document.body.appendChild(el);

  el.src = `${process.env.PUBLIC_URL}/sounds/${name}`;
  el.play();

  el.onended = () => {
    el?.parentElement?.removeChild(el);
    el = null;
  };
}
