import React from 'react';
import { HrushchStationModel } from 'types/Hrushch';
import { useGetStationEvents, StationEventsWidget } from 'pages/shared/StationEvents';
import Calendar from 'components/Calendar';
import MultiSelectCheckBoxes from 'components/MultiSelect/MultiSelectCheckBoxes';
import { Option } from 'components/MultiSelect/types';
import { HRUSHCH_STATUSES } from 'pages/events/utils';

interface HrushchStationComponentProps {
  station: HrushchStationModel;
}

const statusesFilterList = HRUSHCH_STATUSES.map(status => ({
  value: status,
  label: status
}));

const HrushchStationComponent: React.FC<HrushchStationComponentProps> = ({ station }) => {
  const { onSubmitCalendar, loading, eventsMap, fetchNextPage, onSetStatusFilter, onSetAllStatusesFilters } =
    useGetStationEvents(station, HRUSHCH_STATUSES);

  const onSelectStatus = (option: Option) => {
    onSetStatusFilter(option.value as string);
  };

  return (
    <>
      <div className='filters-placeholder d-flex gap-3 align-items-center justify-content-end position-fixed layout-width'>
        <MultiSelectCheckBoxes
          placeholder='selectStatus'
          options={statusesFilterList}
          initialSelectedValues={HRUSHCH_STATUSES}
          onChange={onSelectStatus}
          onSetAllOptions={onSetAllStatusesFilters}
        />
        <Calendar placement='leftBottom' align={{ offset: [-90, -30] }} className='mr-2' onSubmit={onSubmitCalendar} />
      </div>
      <StationEventsWidget className='pt-5' loading={loading} eventsMap={eventsMap} fetchNextPage={fetchNextPage} />
    </>
  );
};

export default HrushchStationComponent;
