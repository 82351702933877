export const WS_VMS_CAMERA_ONLINE = 'WS_VMS_CAMERA_ONLINE';
export const WS_VMS_CAMERA_OFFLINE = 'WS_VMS_CAMERA_OFFLINE';
export const WS_VMS_CAMERA_UPDATED = 'WS_VMS_CAMERA_UPDATED';
export const WS_VMS_CONNECTION_STATUS_EVENT = 'WS_VMS_CONNECTION_STATUS_EVENT';

export const WS_STATION_UPDATED = 'WS_STATION_UPDATED';
export const WS_STATION_DISCONNECTED = 'WS_STATION_DISCONNECTED';
export const WS_STATION_CONNECTED = 'WS_STATION_CONNECTED';
export const WS_STATION_ALARM = 'WS_STATION_ALARM';
export const WS_STATION_NORMAL = 'WS_STATION_NORMAL';
export const WS_STATION_MEDIUM = 'WS_STATION_MEDIUM';
export const WS_STATION_ISSUE = 'WS_STATION_ISSUE';
export const WS_STATION_LEARNING = 'WS_STATION_LEARNING';
