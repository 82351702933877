export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const USER_IS_UNATHORIZED = 'USER_IS_UNATHORIZED';

export const VMS_CAMERA_ONLINE = 'VMS_CAMERA_ONLINE';
export const VMS_CAMERA_OFFLINE = 'VMS_CAMERA_OFFLINE';
export const VMS_CAMERA_UPDATED = 'VMS_CAMERA_UPDATED';
export const VMS_CONNECTION_STATUS_EVENT = 'VMS_CONNECTION_STATUS_EVENT';

export const STATION_UPDATED = 'STATION_UPDATED';
export const STATION_DISCONNECTED = 'STATION_DISCONNECTED';
export const STATION_CONNECTED = 'STATION_CONNECTED';
export const STATION_ALARM = 'STATION_ALARM';
export const STATION_NORMAL = 'STATION_NORMAL';
export const STATION_MEDIUM = 'STATION_MEDIUM';
export const STATION_ISSUE = 'STATION_ISSUE';
export const STATION_LEARNING = 'STATION_LEARNING';
