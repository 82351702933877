import React from 'react';
import { Frequency } from 'types';
import TextResourceContext from 'contexts/TextResource';
import Text, { TextColor, TextSize } from 'components/Text';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getIsAdmin } from 'store/authReducer';
import Card from 'components/Card';

interface FrequencyCardProps {
  frequency: Frequency;
  onBlockFrequencies?: (freq: Frequency) => void;
}

const SimpleFrequencyCard: React.FC<FrequencyCardProps> = ({ frequency, onBlockFrequencies }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const isAdmin = useSelector(getIsAdmin);

  const onClickBlockFreq = React.useCallback(() => {
    onBlockFrequencies?.(frequency);
  }, [onBlockFrequencies, frequency]);

  return (
    <Card
      className='d-flex justify-content-between align-items-center'
      rightBar={
        isAdmin ? (
          <Text
            className={classNames(
              frequency.block ? 'background-danger' : 'background-light-gray',
              'writing-mode-vertical-rl text-center p-1 cursor-pointer h-100'
            )}
            size={TextSize.Sm}
            color={frequency.block ? TextColor.White : TextColor.Dark}
            onClick={onClickBlockFreq}
          >
            <div style={{ transform: 'rotate(180deg)' }}>
              {getTextResourceByKey(frequency.block ? 'blocked' : 'active')}
            </div>
          </Text>
        ) : undefined
      }
      style={{ height: '70px' }}
    >
      <div
        className={classNames(
          'text-center h-100 d-flex flex-column justify-content-center',
          frequency.block && 'opacity-25'
        )}
      >
        <Text className='mb-1' size={TextSize.Sm}>
          {getTextResourceByKey('frequency')}
        </Text>
        <Text size={TextSize.SmMd} color={TextColor.White}>
          {frequency.frequency}
        </Text>
      </div>
    </Card>
  );
};

export default SimpleFrequencyCard;
