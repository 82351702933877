import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toastError, toastSuccess } from 'components/Toaster';
import { ThunkOptions } from '../types';

export const resetStateAction = createAsyncThunk(
  'RESET_STATE',
  (_, { extra: { updateStationsService } }: ThunkOptions) => {
    updateStationsService.clearSetup();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('sessionId');

    return Promise.resolve(true);
  }
);

export const removeStationSettingSuccessAction = createAction<number>('STATIONS@REMOVE_SETTINGS_SUCCESSFULLY');

export const removeAllStationSettings = createAsyncThunk<number, number>(
  'STATIONS@REMOVE_SETTINGS',
  (stationId, { dispatch, extra }: ThunkOptions) => {
    const api = extra.api;

    return api
      .delete(`signals/station/${stationId}`)
      .then(() => {
        toastSuccess('stationSettingsRemovedSuccessfully');
        dispatch(removeStationSettingSuccessAction(stationId));
      })
      .catch(() => {
        toastError('stationSettingsRemovedFailed');
      });
  }
);

export const heartBeatAction = createAsyncThunk('HEARTBEAT@PING', (_, { extra }: ThunkOptions) => {
  const api = extra.api;

  return api.get('ping');
});
