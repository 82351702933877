import React from 'react';
import Input from 'components/Input';
import TextResourceContext from 'contexts/TextResource';
import { numberValidationError } from 'components/Input/validations';
import { SettingsConfigWithId } from 'types';
import Button from 'components/Button';
import { v4 as uuidv4 } from 'uuid';
import Text, { TextColor, TextSize } from 'components/Text';
import { isEqual } from 'lodash';
import './UpdateSettingsModal.scss';

const validations = [numberValidationError];

interface AddSettingsModalProps {
  onSubmit: (options: SettingsConfigWithId) => void;
  initialSettings?: SettingsConfigWithId;
  title: string;
}

const UpdateSettingsModal: React.FC<AddSettingsModalProps> = ({ onSubmit, initialSettings = {}, title }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const refValidationsCbRef = React.useRef<{ [key: string]: () => void }>({});
  const [newSettings, setNewSettings] = React.useState<SettingsConfigWithId>({
    beginFrequency: initialSettings.beginFrequency || '',
    endFrequency: initialSettings.endFrequency || '',
    intervalFrequency: initialSettings.intervalFrequency || '0',
    minPower: initialSettings.minPower || '0',
    bandWidth: initialSettings.bandWidth || '0',
    id: initialSettings.id || uuidv4()
  });

  const onChangeSettings = React.useCallback(
    (key: string) => (value: string | number) => {
      setNewSettings(set => ({ ...set, [key]: value }));
    },
    []
  );

  const onSubmitCb = React.useCallback(() => {
    onSubmit(newSettings);
  }, [newSettings]);

  const onSetValidationCb = React.useCallback(
    (id: string) => (cb: () => void) => {
      refValidationsCbRef.current[id] = cb;
    },
    []
  );

  const onSubmitWithValidation = () => {
    const isValid = !Object.values(refValidationsCbRef.current).some(getIsNotValid => getIsNotValid());

    if (isValid) {
      onSubmitCb();
    }
  };

  const isDisabled = isEqual(initialSettings, newSettings);

  return (
    <div className='add-settings'>
      <Text color={TextColor.Success} size={TextSize.MdLg} className='uppercase text-center mb-3'>
        {getTextResourceByKey(title)}
      </Text>
      <Input
        setValidationCb={onSetValidationCb('beginFrequency')}
        validations={validations}
        labelClassName='add-settings__input-label'
        label={getTextResourceByKey('beginFrequency')}
        value={newSettings.beginFrequency}
        onSetValue={onChangeSettings('beginFrequency')}
      />
      <Input
        setValidationCb={onSetValidationCb('endFrequency')}
        validations={validations}
        labelClassName='add-settings__input-label'
        label={getTextResourceByKey('endFrequency')}
        value={newSettings.endFrequency}
        onSetValue={onChangeSettings('endFrequency')}
      />
      <Input
        setValidationCb={onSetValidationCb('interval')}
        validations={validations}
        labelClassName='add-settings__input-label'
        label={getTextResourceByKey('interval')}
        value={newSettings.intervalFrequency}
        onSetValue={onChangeSettings('intervalFrequency')}
      />
      <Input
        setValidationCb={onSetValidationCb('minPower')}
        validations={validations}
        labelClassName='add-settings__input-label'
        label={getTextResourceByKey('minPower')}
        value={newSettings.minPower}
        onSetValue={onChangeSettings('minPower')}
      />
      <Input
        className='mb-4'
        setValidationCb={onSetValidationCb('bandWidth')}
        validations={validations}
        labelClassName='add-settings__input-label'
        label={getTextResourceByKey('bandWidth')}
        value={newSettings.bandWidth}
        onSetValue={onChangeSettings('bandWidth')}
      />
      <div className='flex-justify-end'>
        <Button onClick={onSubmitWithValidation} disabled={isDisabled}>
          {getTextResourceByKey('submit')}
        </Button>
      </div>
    </div>
  );
};

export default UpdateSettingsModal;
