import React from 'react';
import useGetDrones from '../useGetDrones';
import Calendar from 'components/Calendar';
import ElescopeStationLayout from '../ElescopeStationLayout';
import { ElescopeStationModel } from 'types';
import { getCustomDateFormat, getNextDayCustomDateFormat } from 'utils';
import './ElescopeHistoryWidget.scss';
import { getDateOfSomeDaysAgo } from 'utils/date';

interface ElescopeHistoryWidgetProps {
  station: ElescopeStationModel;
}

const ElescopeHistoryWidget: React.FC<ElescopeHistoryWidgetProps> = ({ station }) => {
  const [periodDate, setPeriodDate] = React.useState<Date[]>([getDateOfSomeDaysAgo(10), new Date()]);
  const { flightsMap } = useGetDrones(station.id, {
    startDate: getCustomDateFormat(periodDate[0]),
    endDate: getNextDayCustomDateFormat(periodDate[1])
  });

  return (
    <div className='position-relative'>
      <div className='position-absolute right-2 elescope-history-calendar'>
        <Calendar
          onSubmit={setPeriodDate}
          defaultValue={periodDate}
          placement='leftBottom'
          align={{ offset: [-90, -30] }}
        />
      </div>

      <ElescopeStationLayout station={station} flightsMap={flightsMap} />
    </div>
  );
};

export default React.memo(ElescopeHistoryWidget);
