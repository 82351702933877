import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { StationStatistic, StatisticsFrequenciesResponse, StatisticsState, ThunkOptions } from '../types';
import { AppDispatch, RootState, ThunkExtraArgumentType } from './types';
import { createEmptyPaginationState } from './utils';
import { removeStationSettingSuccessAction, resetStateAction } from './shared';

const initialState: StatisticsState = {};

const SIGNAL_KEY = 'resetStationStatistic';

export const getStationStatisticsAction = createAsyncThunk<
  StatisticsFrequenciesResponse,
  {
    stationId: number;
    page: number;
    startDate: string;
    endDate: string;
  }
>(
  'STATIONS@GET_STATION_STATISTICS_FREQUENCIES',
  ({ stationId, page, startDate, endDate }, { extra: { api } }: ThunkOptions) =>
    api.get(`signals/station/${stationId}?page=${page}&size=50&startDate=${startDate}&endDate=${endDate}`, SIGNAL_KEY)
);

export const resetStationStatistic = createAction<number>('resetStationStatistic');

export const resetStationStatisticAndCancelPrevRequest =
  (stationId: number) =>
  (dispatch: AppDispatch, _: () => RootState, { api }: ThunkExtraArgumentType) => {
    api.abortController(SIGNAL_KEY);
    dispatch(resetStationStatistic(stationId));
  };

const statisticsSlice = createSlice({
  name: 'stationStatistic',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStationStatisticsAction.pending, (state, action) => {
      const stationId = action.meta.arg.stationId;
      const station = state[stationId] || createEmptyPaginationState();

      state[stationId] = {
        ...station,
        loading: true
      };
    });
    builder.addCase(getStationStatisticsAction.rejected, (state, action) => {
      const stationId = action.meta.arg.stationId;
      const error = action.error;

      state[stationId] = {
        ...state[stationId],
        error,
        loading: false
      };
    });
    builder.addCase(getStationStatisticsAction.fulfilled, (state, action) => {
      const stationId = action.meta.arg.stationId;
      const response = action.payload;
      const station: StationStatistic = state[stationId];

      if (!station.loaded || station.data.currentPage + 1 === response.currentPage) {
        state[stationId] = {
          ...station,
          loading: false,
          loaded: true,
          error: null,
          data: {
            ...response,
            // @ts-ignore
            data: [...station.data.data, ...response.data]
          }
        };
      }
    });
    builder.addCase(resetStationStatistic, (state, action) => {
      const stationId = action.payload;

      state[stationId] = createEmptyPaginationState();
    });
    builder.addCase(resetStateAction.fulfilled, () => initialState);
    builder.addCase(removeStationSettingSuccessAction, (state, action) => {
      const stationId = action.payload;

      state[stationId] = createEmptyPaginationState();
    });
  }
});

const defaultStatisticStationData = createEmptyPaginationState();

export const statisticsReducer = statisticsSlice.reducer;
export const getStatisticStationSelector = (state: RootState, stationId?: number): StationStatistic =>
  stationId ? state.statistic[stationId] || defaultStatisticStationData : defaultStatisticStationData;
