import { Coordinate } from 'ol/coordinate';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import View from 'ol/View';
import * as proj from 'ol/proj';
import Overlay from 'ol/Overlay';
import { getElementsIntersected } from 'utils';
import { compact, keyBy, noop, throttle, uniq } from 'lodash';
import { Feature } from 'ol';
import { LineString, Point, Circle } from 'ol/geom';
import { Vector as VectorSource, Cluster } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Style, Fill as StyleFill, Stroke as StyleStroke, Icon, Text, Circle as StyleCircle } from 'ol/style';
import { hexToRgb } from 'utils/common';
import { defaults as defaultControls, FullScreen as FullScreenControl } from 'ol/control';
import { OverlayTooltipDetails } from '../types';
import DrawService, { DrawType } from './DrawService';
import { Subject } from 'rxjs';
import { Pixel } from 'ol/pixel';
import { COLORS } from 'consts';
import { ClickMapEvent } from './types';

interface OverlayInfo {
  id: number | string;
  overlay: Overlay;
  coords: Coordinate;
  priority: number;
  visible: boolean;
  hiddenOverlays: (number | string)[];
  tooltipDetails?: OverlayTooltipDetails;
  tooltipCb?: (args: OverlayTooltipDetails[] | null) => void;
}

export enum TileSourceMapKey {
  Satellite = 'Satellite',
  Roadmap = 'Roadmap',
  AlteredRoadmap = 'AlteredRoadmap',
  Terrain = 'Terrain',
  TerrainOnly = 'TerrainOnly',
  Hybrid = 'Hybrid',
  Geographic = 'Geographic'
}

export default class OiMapService {
  _initialCoordinates: Coordinate;
  _map: Map;
  zoom: number;
  overlaysInfo: OverlayInfo[] = [];
  fullScreenControl: FullScreenControl;
  currentTileLayerKey: TileSourceMapKey;
  drawService: DrawService;
  mapTileSourcesMap = {
    [TileSourceMapKey.Satellite]: new XYZ({ url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}' }),
    [TileSourceMapKey.Roadmap]: new XYZ({ url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}' }),
    [TileSourceMapKey.AlteredRoadmap]: new XYZ({ url: 'http://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}' }),
    [TileSourceMapKey.Terrain]: new XYZ({ url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}' }),
    [TileSourceMapKey.TerrainOnly]: new XYZ({ url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}' }),
    [TileSourceMapKey.Hybrid]: new XYZ({ url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}' }),
    [TileSourceMapKey.Geographic]: new XYZ({ url: 'https://tile.opentopomap.org/{z}/{x}/{y}.png' })
  };

  sources = {
    vectorLine: new VectorSource(),
    vectorCircles: new VectorSource(),
    vectorMarkers: new VectorSource(),
    iconMarkers: new VectorSource()
  };

  sourcesMap = {
    line: this.sources.vectorLine,
    circle: this.sources.vectorCircles
  };

  onFeatureClickSubject = new Subject<{ windowCoords: Pixel; ids: number[] }>();
  constructor(initialCoordinates: Coordinate, zoom = 6) {
    this._initialCoordinates = initialCoordinates;
    this.zoom = zoom;
    this.fullScreenControl = new FullScreenControl({
      source: 'fullscreen'
    });

    this.currentTileLayerKey = TileSourceMapKey.Satellite;

    this._map = new Map({
      controls: defaultControls().extend([this.fullScreenControl]),
      layers: [
        new TileLayer({
          source: this.mapTileSourcesMap[TileSourceMapKey.Satellite]
        })
      ],
      view: new View({
        showFullExtent: true,
        zoom: this.zoom
      })
    });

    this.drawService = new DrawService(this.map);

    this.map.addEventListener('moveend', this.onChangeViewMap);
    this.map.addEventListener('change:layerGroup', throttle(this.onChangeViewMap, 200));
    this.map.addEventListener('click', this.onFeatureClick);

    const vectorLayer = new VectorLayer({ source: this.sources.vectorLine });
    const stationsLayer = new VectorLayer({ source: this.sources.vectorCircles });
    const markersLayer = new VectorLayer({ source: this.sources.vectorMarkers });

    const iconLayers = new VectorLayer({
      source: new Cluster({
        distance: 25,
        minDistance: 25,
        source: this.sources.iconMarkers
      }),
      style: feature => {
        const features = feature.get('features');
        const length = feature.get('features').length;

        const styles: Style[] = [];
        let circleStyle: Style | null = null;

        if (length > 1) {
          circleStyle = new Style({
            text: new Text({
              text: length.toString(),
              offsetY: -9,
              offsetX: 10,
              fill: new StyleFill({
                color: '#fff'
              }),
              scale: 0.8
            }),
            image: new StyleCircle({
              displacement: [9, 9],
              radius: 8,
              fill: new StyleFill({
                color: COLORS.primary
              }),
              stroke: new StyleStroke({
                color: COLORS.light,
                width: 1
              })
            })
          });
        }
        const firstFeature = features[0];

        const featureStyle = firstFeature.getStyle();

        styles.push(
          ...(Array.isArray(featureStyle) ? featureStyle : [featureStyle]),
          ...(circleStyle ? [circleStyle] : [])
        );

        return styles;
      }
    });

    this.map.addLayer(vectorLayer);
    this.map.addLayer(stationsLayer);
    this.map.addLayer(markersLayer);
    this.map.addLayer(iconLayers);
  }

  changeLayerGroup = (type: TileSourceMapKey) => {
    const tileLayer = this._map.getLayers().getArray()[0];

    if (tileLayer instanceof TileLayer) {
      tileLayer.setSource(this.mapTileSourcesMap[type]);
    }
    this.currentTileLayerKey = type;
  };

  resetOverlaysVisibility = () => {
    this.overlaysInfo.forEach(overlay => {
      overlay.visible = true;
      overlay.hiddenOverlays = [];

      const overlayElement = overlay.overlay.getElement();
      const multipleLabelElement = overlayElement?.getElementsByClassName(
        'layer-marker__multiple-label'
      )[0] as HTMLElement;

      if (multipleLabelElement) {
        multipleLabelElement.classList.add('invisible');
        multipleLabelElement.innerText = '';
      }
    });
  };

  onFeatureClick = (event: ClickMapEvent) => {
    this.map.forEachFeatureAtPixel(event.pixel, feature => {
      if (feature.getGeometry()?.getType() === 'Point') {
        const mapCoordinates = this.map.getCoordinateFromPixel(event.pixel);
        const windowCoordinates = this.map.getPixelFromCoordinate(mapCoordinates);

        const ids = feature.get('features').map((f: Feature) => f.getId());

        if (ids.length > 0) {
          this.onFeatureClickSubject.next({ ids, windowCoords: windowCoordinates });
          //@ts-ignore
          event.stopPropagation();
        }
      }
    });
  };

  onChangeViewMap = () => {
    this.resetOverlaysVisibility();

    this.overlaysInfo.sort((overlay1, overlay2) => overlay1.coords[0] - overlay2.coords[0]);

    this.overlaysInfo.reduce<OverlayInfo[]>((acc, overlay) => {
      acc.forEach((accOverlay, i) => {
        if (
          overlay.visible &&
          accOverlay.visible &&
          getElementsIntersected(
            accOverlay.overlay.getElement() as HTMLElement,
            overlay.overlay.getElement() as HTMLElement
          )
        ) {
          if (accOverlay.priority <= overlay.priority) {
            overlay.visible = false;
            accOverlay.hiddenOverlays = uniq([...accOverlay.hiddenOverlays, ...overlay.hiddenOverlays, overlay.id]);
            overlay.hiddenOverlays = [];
          } else {
            accOverlay.visible = false;
            acc.splice(i, 1);
            overlay.hiddenOverlays = uniq([accOverlay.id, ...accOverlay.hiddenOverlays]);
          }
        }
      });

      if (overlay.visible) {
        acc.push(overlay);
      }

      return acc;
    }, []);

    this.updateOverlayVisibility();
  };

  updateOverlayVisibility() {
    const overalaysMap = keyBy(this.overlaysInfo, 'id');

    this.overlaysInfo.forEach(overlayInfo => {
      const element = overlayInfo.overlay.getElement()?.parentElement;

      if (overlayInfo.visible) {
        element?.classList.remove('invisible');
        if (overlayInfo.hiddenOverlays.length > 0) {
          const multipleLabelElement = element?.getElementsByClassName(
            'layer-marker__multiple-label'
          )[0] as HTMLElement;

          if (multipleLabelElement) {
            multipleLabelElement.classList.remove('invisible');
            multipleLabelElement.innerText = (overlayInfo.hiddenOverlays.length + 1).toString();
            overlayInfo.tooltipCb?.(
              compact(
                [overlayInfo.id, ...overlayInfo.hiddenOverlays].map(overlayId => overalaysMap[overlayId].tooltipDetails)
              )
            );
          }
        } else {
          overlayInfo.tooltipCb?.(null);
        }
      } else {
        element?.classList.add('invisible');
      }
    });
  }

  get map() {
    return this._map;
  }

  getTranformedCoords(coords: Coordinate, fromScreenCoords?: boolean) {
    const latLong = fromScreenCoords
      ? proj.transform([coords[0], coords[1]], 'EPSG:3857', 'EPSG:4326').reverse()
      : coords;

    return proj.transform([latLong[1], latLong[0]], 'EPSG:4326', 'EPSG:3857');
  }

  moveToCoords(coordinates: Coordinate) {
    this.map.getView().setCenter(this.getTranformedCoords(coordinates));
  }

  show(elementId: string) {
    this.map.setTarget(elementId);
    this.moveToCoords(this._initialCoordinates);
  }

  addOverlay({
    coords,
    elem,
    id,
    priority,
    tooltipDetails,
    isScreenCoords,
    tooltipCb
  }: {
    coords: Coordinate;
    elem: HTMLElement;
    id: number | string;
    priority: number;
    tooltipCb?: (args: OverlayTooltipDetails[] | null) => void;
    tooltipDetails?: OverlayTooltipDetails;
    isScreenCoords?: boolean;
  }) {
    const overlay = new Overlay({
      position: this.getTranformedCoords(coords, isScreenCoords),
      positioning: 'center-center',
      element: elem,
      stopEvent: false,
      id
    });

    this.overlaysInfo.push({
      id,
      overlay,
      priority,
      visible: true,
      hiddenOverlays: [],
      coords: coords,
      tooltipDetails,
      tooltipCb
    });
    this.map.addOverlay(overlay);
    setTimeout(this.onChangeViewMap, 0);
    this.updateOverlayVisibility();
  }

  addOrUpdateLine = ({ id, coords, color }: { id: string; coords: Coordinate[]; color: string }) => {
    const transformedPoints = coords.map(point => this.getTranformedCoords(point));
    const feature = this.sources.vectorLine.getFeatureById(id) as Feature<LineString>;

    if (feature && feature.getGeometry() instanceof LineString) {
      const geometry = feature.getGeometry();

      if (geometry) {
        geometry.setCoordinates(transformedPoints);
      }

      return;
    }

    const newFeature = new Feature({
      geometry: new LineString(transformedPoints)
    });

    newFeature.setId(id);
    newFeature.setStyle(
      new Style({
        fill: new StyleFill({ color }),
        stroke: new StyleStroke({ color, width: 1 })
      })
    );
    this.sources.vectorLine.addFeature(newFeature);
  };

  addStartEndPointForLine = ({
    id,
    color,
    label,
    radius
  }: {
    id: string;
    coords: Coordinate[];
    color: string;
    label: string;
    radius?: number;
  }) => {
    const feature = this.sources.vectorLine.getFeatureById(id) as Feature<LineString>;
    const geometry = feature.getGeometry();
    const start = geometry?.getFirstCoordinate();
    const end = geometry?.getLastCoordinate();

    const styles = [
      new Style({
        fill: new StyleFill({ color }),
        stroke: new StyleStroke({ color, width: 1 })
      })
    ];

    styles.push(
      new Style({
        geometry: new Point(start as Coordinate),
        image: new Icon({
          src: `${process.env.PUBLIC_URL}/start-point.svg`,
          color
        })
      })
    );

    styles.push(
      new Style({
        geometry: new Point(end as Coordinate),
        image: new Icon({
          src: `${process.env.PUBLIC_URL}/plane.svg`,
          color
        })
      })
    );

    styles.push(
      new Style({
        geometry: new Point(end as Coordinate),
        text: new Text({
          text: label,
          offsetY: -20,
          scale: 1,
          fill: new StyleFill({
            color
          })
        })
      })
    );

    if (radius) {
      styles.push(
        new Style({
          geometry: new Circle(end as Coordinate, this.getMapRadius(radius)),
          fill: new StyleFill({ color: hexToRgb(color, 0.1) }),
          stroke: new StyleStroke({ color, width: 1 })
        })
      );
    }

    feature.setStyle(styles);
  };

  getMapRadius = (mapRadius: number) => mapRadius * 1.5;

  getRealRadius = (realRadius: number) => realRadius * 1.5;

  addCircle = ({
    id,
    coords,
    radius,
    color,
    isScreenCoords
  }: {
    id: string | number;
    coords: Coordinate;
    radius: number;
    color: string;
    isScreenCoords?: boolean;
  }) => {
    const feature = this.sources.vectorLine.getFeatureById(id) as Feature<Circle>;

    if (!feature) {
      const newFeature = new Feature({
        geometry: new Circle(this.getTranformedCoords(coords, isScreenCoords), this.getMapRadius(radius))
      });

      newFeature.setId(id);
      newFeature.setStyle(
        new Style({
          fill: new StyleFill({ color: hexToRgb(color, 0.05) }),
          stroke: new StyleStroke({ color: hexToRgb(color, 0.75), width: 1 })
        })
      );
      this.sources.vectorCircles.addFeature(newFeature);
    }
  };

  addIconMarker = ({ id, srcIcon, coords }: { id: string | number; srcIcon: string; coords: Coordinate }) => {
    const marker = this.sources.iconMarkers.getFeatureById(id);

    if (!marker) {
      const feature = new Feature({ geometry: new Point(this.getTranformedCoords(coords)) });

      const style = new Style({
        image: new Icon({
          src: srcIcon
        })
      });

      feature.setId(id);
      feature.setStyle(style);
      this.sources.iconMarkers.addFeature(feature);
      feature.setStyle([style] as Style[]);
    }
  };

  addMarker = ({
    id,
    srcIcon,
    coords,
    color,
    label
  }: {
    id: string | number;
    srcIcon: string;
    coords: Coordinate;
    color: string;
    label?: string;
  }) => {
    const marker = this.sources.vectorMarkers.getFeatureById(id);

    if (!marker) {
      const feature = new Feature({ geometry: new Point(this.getTranformedCoords(coords)) });
      const style = new Style({
        image: new Icon({
          src: srcIcon
        })
      });

      feature.setId(id);
      feature.setStyle(style);
      this.sources.vectorMarkers.addFeature(feature);

      if (label) {
        const styles = [];

        styles.push(
          new Style({
            text: new Text({
              text: label,
              offsetY: -20,
              scale: 1,
              fill: new StyleFill({
                color
              })
            })
          })
        );
        styles.push(feature.getStyle());
        feature.setStyle(styles as Style[]);
      }
    }
  };

  addCircleToMarker = ({
    id,
    color,
    radius,
    source = 'vectorMarkers',
    strokeColor = '#fff'
  }: {
    id: string | number;
    color: string;
    radius: number;
    source?: 'vectorMarkers' | 'iconMarkers';
    strokeColor?: string;
  }) => {
    const feature = this.sources[source].getFeatureById(id);

    if (feature) {
      const featureStyle = feature.getStyle();
      const styles = [...(Array.isArray(featureStyle) ? featureStyle : [featureStyle])];

      styles.unshift(
        new Style({
          image: new StyleCircle({
            radius,
            stroke: new StyleStroke({
              color: strokeColor
            }),
            fill: new StyleFill({
              color
            })
          })
        })
      );
      feature.setStyle(styles as Style[]);
    }
  };

  removeIconMarker = (id: string | number) => {
    const feature = this.sources.iconMarkers.getFeatureById(id);

    if (feature) {
      this.sources.iconMarkers.removeFeature(feature);
    }
  };

  removeMarker = (id: string) => {
    const feature = this.sources.vectorMarkers.getFeatureById(id);

    if (feature) {
      this.sources.vectorMarkers.removeFeature(feature);
    }
  };

  removeFeature = (id: string | number, type: 'line' | 'circle') => {
    const feature = this.sourcesMap[type]?.getFeatureById(id);

    if (feature) {
      this.sources.vectorLine.removeFeature(feature);
      this.sources.vectorCircles.removeFeature(feature);
      this.sources.vectorMarkers.removeFeature(feature);
    }
  };

  removeOverlay(id: number | string) {
    const overlayInfo = this.overlaysInfo.find(overlay => overlay.id === id);

    if (overlayInfo) {
      this.map.removeOverlay(overlayInfo.overlay);
      this.overlaysInfo = this.overlaysInfo.filter(overlayInfoL => overlayInfoL.id !== id);
    }
    const overlay = this.map.getOverlayById(id);

    if (overlay) {
      this.map.removeOverlay(overlay);
    }
  }

  flyTo = (location: Coordinate, zoomTo?: number, onComplete = noop, duration = 3000) => {
    const view = this.map.getView();
    const currentZoom = view.getZoom() as number;
    let parts = 2;
    let called = false;

    function callback(complete: boolean) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
        onComplete(complete);
      }
    }
    view.animate({ center: this.getTranformedCoords(location), duration }, callback);
    view.animate({ zoom: zoomTo || currentZoom, duration }, callback);
  };

  removeAllFeatures = () => {
    this.sources.vectorCircles.clear();
    this.sources.vectorLine.clear();
    this.sources.vectorMarkers.clear();
  };

  destroy() {
    this.map.setTarget(undefined);
  }

  addEventListener(type: string, callback: (args: any) => void) {
    this.map.addEventListener(type, callback);

    return () => this.map.removeEventListener(type, callback);
  }

  updateFeaturePosition = ({
    id,
    type,
    coords,
    isScreenCoords,
    radius
  }: {
    id: string;
    type: 'line' | 'circle';
    coords?: Coordinate;
    isScreenCoords?: boolean;
    radius?: number;
  }) => {
    const feature = this.sourcesMap[type]?.getFeatureById(id);
    const geometry = feature?.getGeometry();

    if (geometry instanceof Circle) {
      if (coords) {
        geometry.setCenter(this.getTranformedCoords(coords, isScreenCoords));
      }
      if (radius) {
        geometry.setRadius(this.getMapRadius(radius));
      }
    }
  };

  updateOverlayPosition = ({
    coords,
    id,
    isScreenCoords
  }: {
    id: string;
    coords: Coordinate;
    isScreenCoords?: boolean;
  }) => {
    const overlay = this.map.getOverlayById(id);

    if (overlay) {
      overlay.setPosition(this.getTranformedCoords(coords, isScreenCoords));
    }
  };

  addDrawFeature = (type: DrawType) => {
    this.drawService.addInteraction({ type });
  };

  stopInteractions() {
    this.drawService.dropInteraction();
    if (this.drawService.tempId) {
      this.removeOverlay(this.drawService.tempId as number);
    }
  }

  removeAllOverlays() {
    this.map.getOverlays().forEach(overlay => {
      this.map.removeOverlay(overlay);
    });
  }
}
