import React from 'react';
import TabsPanel from 'components/TabsPanel';
import VmsCameras from './VmsCameras';
import VmsMapComponent from './VMSMapComponent';
import VmsWalls from './layouts/VmsWalls';
import { useDispatch, useSelector } from 'react-redux';
import { getVmsCamerasAction, getVmsCamerasAreLoaded, getVmsState } from 'store/vmsReducer';
import { AppDispatch } from 'store/types';
import HeaderComponent from 'components/HeaderComponent';
import { COLORS, LOGO_SIZE } from 'consts';
import { BsCameraVideo } from 'react-icons/bs';
import Spinner from 'components/Spinner';
import VmsEvents from './VmsEvents';
import './Vms.scss';
import classNames from 'classnames';
import VmsConnectionStatus from '../shared/VmsConnectionStatus';

const tabs = ['cameras', 'map', 'videoWalls', 'events'];

const VmsPage: React.FC = () => {
  const {
    cameras: { data: cameras },
    camerasFolder
  } = useSelector(getVmsState);
  const isLoaded = useSelector(getVmsCamerasAreLoaded);
  const dispatch = useDispatch() as AppDispatch;
  const [tabIndex, setTabIndex] = React.useState(0);

  React.useEffect(() => {
    dispatch(getVmsCamerasAction());
  }, []);

  const tabsPanels = [
    <VmsCameras camerasFolder={camerasFolder} />,
    <VmsMapComponent cameras={cameras} />,
    <VmsWalls cameras={cameras} />,
    <VmsEvents />
  ];

  return (
    <div className={classNames(tabIndex !== 3 && 'overflow-hidden h-100vh')}>
      <HeaderComponent logo={<BsCameraVideo size={LOGO_SIZE} color={COLORS.white} />} />
      {isLoaded ? (
        <TabsPanel
          tabs={tabs}
          tabsPanels={tabsPanels}
          tabPanelClassName='pt-5'
          tabsListBodyClassName='layout-width position-fixed'
          onSelectTabIndex={setTabIndex}
          afterElements={<VmsConnectionStatus />}
        />
      ) : (
        <Spinner isFullSize />
      )}
    </div>
  );
};

export default VmsPage;
