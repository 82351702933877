import { ConnectionType, Event, StationStatusType } from 'types';
import { isConnectionStatusEvent, isElescopeStatusEvent, isStatusTypeEvent } from 'utils/events';

export const BASE_STATUSES = [ConnectionType.Connected, ConnectionType.Disconnected];
export const BAKLANS_STATUSES = BASE_STATUSES;
export const HRUSHCH_STATUSES = [...BASE_STATUSES, StationStatusType.Alarm, StationStatusType.Normal];
export const ELESCOPE_STATUSES = [...BASE_STATUSES, StationStatusType.Alarm, StationStatusType.Normal];
export const STRAUS_STATUSES = [
  ...BASE_STATUSES,
  ConnectionType.NotFound,
  ConnectionType.StationIssue,
  ConnectionType.WrongResponse,
  StationStatusType.Alarm,
  StationStatusType.Medium,
  StationStatusType.Normal,
  StationStatusType.Learning
];

export const getConnectionStatusEventBg = (event: Event) => {
  if (isConnectionStatusEvent(event)) {
    switch (event.data.connectionStatus) {
      case ConnectionType.Connected:
        return 'background-success';
      case ConnectionType.Disconnected:
      case ConnectionType.NotFound:
        return 'background-black';
      case ConnectionType.WrongResponse:
      case ConnectionType.StationIssue:
        return 'background-danger';
    }
  }

  if (isElescopeStatusEvent(event)) {
    switch (event.data.status) {
      case StationStatusType.Alarm:
        return 'background-danger';
      case StationStatusType.Normal:
        return 'background-success';
    }
  }

  if (isStatusTypeEvent(event)) {
    //@ts-ignore
    switch (event.data.currentStatus) {
      case StationStatusType.Alarm:
        return 'background-danger';
      case StationStatusType.Medium:
        return 'background-warning';
      case StationStatusType.Normal:
        return 'background-success';
      case StationStatusType.Learning:
        return 'background-dark';
    }
  }

  return '';
};
