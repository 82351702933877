import React from 'react';
import TabsPanel from 'components/TabsPanel';
import HeaderComponent from 'components/HeaderComponent';
import { COLORS, LOGO_SIZE } from 'consts';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import UsersComponent from './users/UsersComponent';
import StationsComponent from './stations';
import { useSearchParams, useLocation } from 'react-router-dom';
import useGetFilteredSearchedStations from 'hooks/useGetFilteredSearchedStations';
import FilterStation from 'pages/shared/FilterStation';
import { PageRoutes, StationModel } from 'types';
import Input from 'components/Input';
import TextResourceContext from 'contexts/TextResource';
import VmsSettings from './vms';

const tabs = ['stations', 'users', 'vms'];

const AdminPanelComponent: React.FC = () => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  const { stations, setSearchFilter, searchFilter } = useGetFilteredSearchedStations();
  const [searchParams] = useSearchParams();
  const searchParamTabIndex = searchParams.get('tabIndex');
  const location = useLocation();
  const routeWithoutSearchParams = location.pathname === PageRoutes.AdminPanel && location.search === '';

  const tabsPanels = React.useMemo(
    () => [<StationsComponent stations={stations as StationModel[]} />, <UsersComponent />, <VmsSettings />],
    [stations, searchFilter]
  );

  return (
    <div>
      <HeaderComponent logo={<MdOutlineAdminPanelSettings size={LOGO_SIZE} color={COLORS.white} />} />
      <TabsPanel
        tabsListBodyClassName='layout-width position-fixed'
        tabPanelClassName='pt-5'
        tabs={tabs}
        tabsPanels={tabsPanels}
        afterElements={
          searchParamTabIndex === '0' || routeWithoutSearchParams
            ? [
                <div className='d-flex gap-3'>
                  <Input
                    value={searchFilter}
                    onSetValue={setSearchFilter}
                    placeholder={getTextResourceByKey('search') as string}
                    withEmptyStringValidation={false}
                    className='mr-3 mb-2'
                  />
                  <FilterStation />
                </div>
              ]
            : null
        }
      />
    </div>
  );
};

export default AdminPanelComponent;
