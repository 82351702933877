import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/types';
import React from 'react';
import {
  abortFrequenciesAction,
  getFrequencies,
  getFrequenciesIsLoading,
  getStationCurrentFrequencies
} from '../store/frequenciesReducer';
import { Frequency, StationModel } from '../types';

const useGetCurrentFrequencies = (station?: StationModel): { frequencies: Frequency[]; loading: boolean } => {
  const dispatch = useDispatch() as AppDispatch;
  const getFrequenciesSelectorCb = React.useMemo(
    () => (state: RootState) => getFrequencies(state, station?.id),
    [station?.id]
  );

  const getStationLoadingCb = React.useCallback(
    (s: RootState) => getFrequenciesIsLoading(s, station?.id),
    [station?.id]
  );

  const frequencies = useSelector(getFrequenciesSelectorCb);
  const loading = useSelector(getStationLoadingCb);

  React.useEffect(() => {
    if (station?.id) {
      dispatch(getStationCurrentFrequencies({ stationId: station.id }));
    }

    return () => {
      dispatch(abortFrequenciesAction());
    };
  }, [station?.id]);

  return { frequencies, loading: loading && frequencies.length === 0 };
};

export default useGetCurrentFrequencies;
