import { useDispatch, useSelector } from 'react-redux';
import { getAllEventsAction, getAllEventsSelector, resetAllEvents } from 'store/allEventsReducer';
import React from 'react';
import { AppDispatch } from 'store/types';
import { Event, StationType } from 'types';
import dayjs from 'dayjs';
import { QUERY_FILTER_STATION_KEY } from 'consts';
import { useSearchParams } from 'react-router-dom';
import { isConnectionStatusEvent, isElescopeStatusEvent, isStrausEvent } from 'utils/events';
import { getFilteredValues } from 'utils/seacrh';
import { ONE_DAY } from 'utils/date';

const PAGE_SIZE = 100;

export default function useGetEvents() {
  const {
    loading,
    data: { totalPages, currentPage, data }
  } = useSelector(getAllEventsSelector);
  const dispatch = useDispatch() as AppDispatch;
  const [dates, setDates] = React.useState([new Date(Date.now() - ONE_DAY * 3), new Date()]);
  const [searchFilter, setSearchFilter] = React.useState<string>('');
  const [searchParams] = useSearchParams();
  const selectedStationFilter = searchParams.get(QUERY_FILTER_STATION_KEY) as StationType | null;

  const filteredEventsByStationType = React.useMemo(
    () => (selectedStationFilter ? data?.filter(event => event.box.type === selectedStationFilter) : data),
    [data, selectedStationFilter]
  );

  React.useEffect(() => {
    const startDate = dayjs(dates[0]).format('YYYY-MM-DD');
    const endDate = dayjs(dates[1]).format('YYYY-MM-DD');

    dispatch(resetAllEvents());

    dispatch(
      getAllEventsAction({
        startDate,
        endDate,
        page: 0,
        size: PAGE_SIZE,
        stationType: selectedStationFilter as StationType
      })
    );
  }, [dates, selectedStationFilter]);

  const eventsMap = React.useMemo(() => {
    const searchedData = getFilteredValues(filteredEventsByStationType, searchFilter, {
      keys: ['stationName', 'eventType']
    });

    const isConnectionStatusEventDataMissing = (event: Event) =>
      isConnectionStatusEvent(event) && event.data.connectionStatus && event.data.previousConnectionStatus;
    const isStrausEventDataMissing = (event: Event) =>
      isStrausEvent(event) && event.data.currentStatus && event.data.previousStatus;
    const isElescopeStatusEventDataMissing = (event: Event) => isElescopeStatusEvent(event);

    const entries = Object.entries(
      searchedData.reduce<Record<string, Event[]>>((acc, event) => {
        if (
          (!selectedStationFilter || selectedStationFilter === event.box.type) &&
          (isConnectionStatusEventDataMissing(event) ||
            isStrausEventDataMissing(event) ||
            isElescopeStatusEventDataMissing(event))
        ) {
          const key = event.date;

          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(event);
        }

        return acc;
      }, {})
    ).sort((a, b) => (a[0] < b[0] ? 1 : -1));

    return Object.fromEntries(entries);
  }, [data, searchFilter, selectedStationFilter]);

  const fetchNextPage = React.useCallback(() => {
    const startDate = dayjs(dates[0]).format('YYYY-MM-DD');
    const endDate = dayjs(dates[11]).format('YYYY-MM-DD');

    if (currentPage < totalPages - 1) {
      dispatch(
        getAllEventsAction({
          startDate,
          endDate,
          page: currentPage + 1,
          size: PAGE_SIZE,
          ...(selectedStationFilter ? { stationType: selectedStationFilter } : {})
        })
      );
    }
  }, [currentPage, totalPages, dates]);

  return {
    loading: loading && data.length === 0,
    onSubmitCalendar: setDates,
    eventsMap,
    searchFilter,
    setSearchFilter,
    fetchNextPage
  };
}
