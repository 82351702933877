import { configureStore } from '@reduxjs/toolkit';
import { ClientWebSocketService, Api, EventBus, UpdateStationsService } from 'services';
import { baseUrl } from 'utils/api';
import { authReducer } from './authReducer';
import { allEventsReducer } from './allEventsReducer';
import { stationMapEventsReducer } from './stationMapEventsReducer';
import { analyticsCountsReducer } from './analyticsCountsReducer';
import { analyticsFrequenciesReducer } from './analyticsFrequenciesReducer';
import { StationModelReducer } from './stationsReducer';
import { usersReducer } from './usersReducer';
import { configsReducer } from './configsReducer';
import { frequenciesReducer } from './frequenciesReducer';
import { statisticsReducer } from './statisticReducer';
import { statisticsFrequenciesReducer } from './statisticFrequenciesReducer';
import { todayFrequenciesReducer } from './todayFrequenciesReducer';
import { dronesReducer } from './dronesReducer';
import resetStateMiddleware from './middlewares/resetStateMiddleware';
import { vmsReducer } from './vmsReducer';
import { uiReducer } from './uiReducer';
import { getLocalizedTextValue } from 'contexts/TextResource';

export const eventBus = new EventBus();
export const thunkExtraArgument = {
  api: new Api(baseUrl, 'auth/refreshToken'),
  wsClient: new ClientWebSocketService(eventBus),
  updateStationsService: new UpdateStationsService(),
  eventBus,
  getLocalizedTextValue
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    stations: StationModelReducer,
    users: usersReducer,
    configs: configsReducer,
    frequencies: frequenciesReducer,
    statistic: statisticsReducer,
    statisticsFrequencies: statisticsFrequenciesReducer,
    todayFrequencies: todayFrequenciesReducer,
    drones: dronesReducer,
    vms: vmsReducer,
    allEvents: allEventsReducer,
    stationMapEvents: stationMapEventsReducer,
    analyticsCounts: analyticsCountsReducer,
    analyticsFrequencies: analyticsFrequenciesReducer,
    ui: uiReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: thunkExtraArgument
      },
      serializableCheck: false
      //@ts-ignore
    }).concat(resetStateMiddleware(eventBus))
});
