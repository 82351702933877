import { CSSProperties } from 'react';
import { StationModel } from './api';
import { StatusStationModel } from './Status';
import { DroneModel, ElescopeStationModel } from './Elescope';
import { SerializedError } from '@reduxjs/toolkit';
import { BaklansStationModel } from './Baklans';
import { ConnectionType, StationType } from './shared';
import { HrushchStationModel } from './Hrushch';
export * from './Status';
export * from './Elescope';
export * from './api';
export * from './shared';

export interface StyledComponent {
  className?: string;
  style?: CSSProperties;
}

export enum LocalizationLanguage {
  English = 'en',
  Ukrainian = 'uk'
}

export interface FetchingData<T> {
  loading: boolean;
  loaded: boolean;
  error: null | SerializedError;
  data: T;
}

export type ThunkOptions = {
  dispatch: any;
  extra: any;
  getState: any;
};

export function isStatusStation(station: StationModel): station is StatusStationModel {
  return (station as StatusStationModel).type === StationType.Status;
}

export function isElescopeStation(station: StationModel): station is ElescopeStationModel {
  return (station as ElescopeStationModel).type === StationType.Elescope;
}

export function isBaklansStation(station: StationModel): station is BaklansStationModel {
  return (station as BaklansStationModel).type === StationType.Baklans;
}

export function isHrushchStation(station: StationModel): station is HrushchStationModel {
  return (station as HrushchStationModel).type === StationType.Hrushch;
}

export enum PageRoutes {
  Dashboard = '/',
  Auth = '/auth',
  Stations = '/stations',
  Station = '/stations/:stationId',
  Settings = '/settings',
  StationSettings = '/settings/:stationId',
  Map = '/map',
  VMS = '/vms',
  AdminPanel = '/admin-panel',
  Events = '/events',
  AddUser = '/users/add',
  EditUser = '/users/:userId/edit',
  Account = '/account'
}

export enum UsersRole {
  Admin = 'ADMIN',
  Default = 'DEFAULT',
  SuperAdmin = 'SUPER_ADMIN'
}

export interface Frequency {
  count: string;
  frequency: string;
  maxDb: number;
  minDb: number;
  block: boolean;
  minBand: number;
  maxBand: number;
}

export interface StationModelBody {
  stationId: number;
  body: Partial<StationModel>;
}

export interface ConfigsBody {
  stationId: number;
  configs?: SettingsConfig[];
}

export interface User {
  id: number;
  name: string;
  login: string;
  role: UsersRole;
  stationIds?: number[];
  cameraIds?: number[];
}

export interface NewUser {
  name: string;
  login: string;
  role: UsersRole;
  stationIds: number[];
  cameraIds: number[];
  password: string;
}

// ********** RESPONSES **************
export type UsersResponse = User[];
export interface SettingsConfig {
  beginFrequency: string;
  endFrequency: string;
  intervalFrequency: string;
  bandWidth: string;
  minPower: string;
}
export interface SettingsConfigWithId extends SettingsConfig {
  id: string | number;
}

export type StationSettings = SettingsConfigWithId[];
export type ConfigsResponse = any;

// ********** STATISTIC **************

export interface StatisticItem {
  band: number;
  date: string;
  frequency: string;
  power: number;
  time: string;
  maxPower: number;
}

export interface PaginationData<T> {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  data: T;
}

export type StatisticsResponse = PaginationData<StatisticItem[]>;
export type StationStatistic = FetchingData<StatisticsResponse>;
export type StatisticsState = {
  [key: string]: StationStatistic;
};

// ********** STATISTIC FREQUENCIES **************

export interface PaginationData<T> {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  data: T;
}

export type StatisticsFrequenciesResponse = PaginationData<Frequency[]>;
export type StatisticsFrequencies = FetchingData<StatisticsFrequenciesResponse>;
export type StatisticsFrequenciesState = { [key: string]: StatisticsFrequencies };

/************* DRONES **************/
export type DronesResponse = { stationId: string; drones: DroneModel[] };
export type DronesReducerState = FetchingData<DroneModel[]>;

/************* EVENTS **************/
export enum EventType {
  ConnectionStatus = 'CONNECTION_STATUS',
  Status = 'STATUS'
}

export type StrausEvent = BaseEvent<StrausEventData>;
export type ElescopeEvent = BaseEvent<ElescopeEventData>;
export type HrushchEvent = BaseEvent<HrushchEventData>;
export type BaklansEvent = BaseEvent<null>;
export type ConnectionStatusEvent = BaseEvent<ConnectionStatusEventData>;
export type StatusEvent = StrausEvent | ElescopeEvent | BaklansEvent | HrushchEvent;
export type Event = ConnectionStatusEvent | StatusEvent;

export interface EventFromBE<T = any> {
  id: number;
  eventType: EventType;
  createdAt: string;
  data: T;
  box: {
    location: string;
    name: string;
    type: StationType;
  };
}

export interface BaseEvent<Data = null> {
  id: number;
  eventType: EventType;
  date: string;
  time: string;
  data: Data;
  box: {
    location: string;
    name: string;
    type: StationType;
  };
}

export interface ConnectionStatusEventData {
  previousConnectionStatus: ConnectionType;
  connectionStatus: ConnectionType;
}

export interface ElescopeEventData {
  status: string;
  droneId: string;
  droneType: string;
}

export interface StrausEventData {
  previousStatus: string;
  currentStatus: string;
  currentCount: number;
  normalCount: number;
}

export interface HrushchEventData {
  previousStatus: string;
  currentStatus: string;
  frequency: number;
  mac: number;
}

export type EventsResponse = PaginationData<Event[]>;
export type EventsState = FetchingData<EventsResponse>;

/************* ANALYTICS COUNTS **************/
export interface AnalyticsCountsDiff {
  frequency: number;
  count: number;
}

export interface AnalyticsCounts {
  increased: AnalyticsCountsDiff[];
  decreased: AnalyticsCountsDiff[];
}

export interface AnalyticsFrequencies {
  added: number[];
  removed: number[];
}

export type AnalyticsCountsState = {
  [key: number]: FetchingData<AnalyticsCounts>;
};

export type AnalyticsFrequenciesState = {
  [key: number]: FetchingData<AnalyticsFrequencies>;
};
