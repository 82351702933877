import { useDispatch } from 'react-redux';
import { getMeAction, sessionExpired } from '../store/authReducer';
import React from 'react';
import { AppDispatch } from '../store/types';
import useEventBus from '../contexts/EventBusContext';
import { SESSION_EXPIRED, USER_IS_UNATHORIZED } from '../services/event-bus';
import { resetStateAction } from '../store/shared';

export default function useInitStartApp() {
  const dispatch = useDispatch() as AppDispatch;
  const eventBus = useEventBus();

  React.useEffect(() => {
    dispatch(getMeAction());
  }, []);

  React.useEffect(() => {
    if (eventBus) {
      const unsubscribeSessionExpired = eventBus?.subscribe(SESSION_EXPIRED, () => {
        dispatch(sessionExpired());
      });

      const setDefaultStateUnsubscribe = eventBus?.subscribe(USER_IS_UNATHORIZED, () => {
        dispatch(resetStateAction());
      });

      return () => {
        unsubscribeSessionExpired();
        setDefaultStateUnsubscribe();
      };
    }
  }, [eventBus]);
}
