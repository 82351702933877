import React from 'react';
import { StationType, StyledComponent } from 'types';
import Text, { TextColor } from 'components/Text';
import { BsFilter } from 'react-icons/bs';
import { COLORS, QUERY_FILTER_STATION_KEY } from 'consts';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { ALL_STATIONS } from 'consts';

type FilterType = StationType | 'All';
const filters: FilterType[] = [ALL_STATIONS, StationType.Elescope, StationType.Status, StationType.Baklans];

const FilterStation: React.FC<StyledComponent> = ({ className }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedStationType = searchParams.get(QUERY_FILTER_STATION_KEY) || ALL_STATIONS;

  const onSelect = (stationType: FilterType) => () => {
    if (stationType === ALL_STATIONS) {
      searchParams.delete(QUERY_FILTER_STATION_KEY);
      setSearchParams(searchParams);
    } else {
      searchParams.set(QUERY_FILTER_STATION_KEY, stationType);
      setSearchParams(searchParams);
    }
  };

  return (
    <div className={classNames('d-flex align-items-center gap-1', className)}>
      <BsFilter size='22' color={COLORS.warning} />
      {filters.map(filterName => (
        <Text
          className='p-2 uppercase cursor-pointer transition-250 hover-white'
          key={filterName}
          color={selectedStationType === filterName ? TextColor.Warning : TextColor.Gray}
          onClick={onSelect(filterName)}
        >
          {filterName}
        </Text>
      ))}
    </div>
  );
};

export default FilterStation;
