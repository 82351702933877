import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AnalyticsFrequencies, AnalyticsFrequenciesState, FetchingData, ThunkOptions } from '../types';
import { createEmptyFetchedState } from './utils';
import { resetStateAction } from './shared';
import { extendUrlByQueries } from 'utils/api';
import { RootState } from './types';
import { isEmpty } from 'lodash';

const initialState: AnalyticsFrequenciesState = {};
const emptyData = { added: [], removed: [] };
const initStationState = createEmptyFetchedState(emptyData);

const SIGNAL_KEY_FREQUENCIES = 'resetStationAnalyticsFrequencies';

type GetAnalayticsQueryParams = {
  firstPeriodStart: number;
  firstPeriodEnd: number;
  secondPeriodStart: number;
  secondPeriodEnd: number;
};

export const getAnalyticsFrequenciesAction = createAsyncThunk<
  AnalyticsFrequencies,
  { stationId: number } & GetAnalayticsQueryParams
>(
  'STATIONS@GET_ANALYTICS_FREQUENCIES',
  (
    { stationId, firstPeriodEnd, firstPeriodStart, secondPeriodEnd, secondPeriodStart },
    { extra: { api } }: ThunkOptions
  ) =>
    api.get(
      extendUrlByQueries(`stations/${stationId}/analytics/addedRemoved`, {
        firstPeriodEnd,
        firstPeriodStart,
        secondPeriodEnd,
        secondPeriodStart
      }),
      SIGNAL_KEY_FREQUENCIES
    )
);

export const resetStationAnalyticsFrequencies = createAction<number>(SIGNAL_KEY_FREQUENCIES);

export const resetStationAnalyticsFrequenciesAndCancelPrevRequest = createAsyncThunk(
  'STATIONS@ABORT_ANALYTICS_FREQUENCIES',
  (stationId: number, { extra: { api }, dispatch }: ThunkOptions) => {
    api.abortController(SIGNAL_KEY_FREQUENCIES);
    dispatch(resetStationAnalyticsFrequencies(stationId));
  }
);

const analyticsFrequenciesSlice = createSlice({
  name: 'stationAnalyticsFrequencies',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAnalyticsFrequenciesAction.pending, (state, action) => {
      const stationId = action.meta.arg.stationId;
      const prevState = state[stationId] || initStationState;

      state[stationId] = {
        ...prevState,
        loading: true
      };
    });
    builder.addCase(getAnalyticsFrequenciesAction.rejected, (state, action) => {
      const stationId = action.meta.arg.stationId;

      state[stationId] = {
        ...state[stationId],
        error: action.error,
        loading: false
      };
    });
    builder.addCase(getAnalyticsFrequenciesAction.fulfilled, (state, action) => {
      const stationId = action.meta.arg.stationId;

      state[stationId] = {
        ...state[stationId],
        loading: false,
        loaded: true,
        data: action.payload
      };
    });
    builder.addCase(resetStationAnalyticsFrequencies, (state, action) => {
      const stationId = action.payload;

      state[stationId] = initStationState;
    });
    builder.addCase(resetStateAction.fulfilled, () => initialState);
  }
});

export const getAnalyticsFrequenciesSelector = (
  state: RootState,
  stationId: number
): FetchingData<AnalyticsFrequencies> => state.analyticsFrequencies[stationId] || initStationState;

const getAnalyticsFrequenciesData = (state: RootState, stationId: number) =>
  getAnalyticsFrequenciesSelector(state, stationId).data;

export const getAnalyticsFrequenciesDataIsEmptySelector = (state: RootState, stationId: number): boolean =>
  getAnalyticsFrequenciesSelector(state, stationId)?.loaded &&
  isEmpty(getAnalyticsFrequenciesData(state, stationId).added) &&
  isEmpty(getAnalyticsFrequenciesData(state, stationId).removed);

export const analyticsFrequenciesReducer = analyticsFrequenciesSlice.reducer;
