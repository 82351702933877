import io, { type Socket } from 'socket.io-client';
import EventBus, {
  STATION_ALARM,
  STATION_CONNECTED,
  STATION_DISCONNECTED,
  STATION_ISSUE,
  STATION_LEARNING,
  STATION_MEDIUM,
  STATION_NORMAL,
  STATION_UPDATED,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  VMS_CAMERA_OFFLINE,
  VMS_CAMERA_ONLINE,
  VMS_CAMERA_UPDATED,
  VMS_CONNECTION_STATUS_EVENT
} from 'services/event-bus';
import ColoredLogger from 'utils/ColoredLogger';
import {
  WS_STATION_UPDATED,
  WS_VMS_CAMERA_OFFLINE,
  WS_VMS_CAMERA_ONLINE,
  WS_VMS_CAMERA_UPDATED,
  WS_STATION_DISCONNECTED,
  WS_STATION_ALARM,
  WS_STATION_NORMAL,
  WS_STATION_MEDIUM,
  WS_STATION_ISSUE,
  WS_STATION_CONNECTED,
  WS_STATION_LEARNING,
  WS_VMS_CONNECTION_STATUS_EVENT
} from './wsEvents';

export default class ClientWebSocketService {
  socket: Socket | null = null;
  publicSocket: Socket | null = null;
  logger = new ColoredLogger('WS-CLIENT', 'orange');

  constructor(
    private eventBus: EventBus,
    private baseUr = ''
  ) {
    this.eventBus = eventBus;
    this.subscribeToEvents();
  }

  private subscribeToEvents() {
    if (process.env.NODE_ENV !== 'development') {
      this.eventBus.subscribe<{ sessionId: number }>(USER_LOGGED_IN, this.openPrivateSocket);
      this.eventBus.subscribe<{ sessionId: number }>(USER_LOGGED_IN, this.openPublicSocket);
    }
    this.eventBus.subscribe(USER_LOGGED_OUT, this.closeSockets);
  }

  private openPublicSocket = () => {
    const publicSocket = io(`${this.baseUr}/public`);

    publicSocket.on('connect', () => {
      this.logger.log('PUBLIC socket connected');
      this.publicSocket = publicSocket;
      this.applyInfraSocketEvents(publicSocket);
      this.subscribeSocketToPublicEvents(publicSocket);
    });
  };

  private subscribeSocketToPublicEvents(socket: Socket) {
    socket.on(WS_VMS_CONNECTION_STATUS_EVENT, data => {
      this.eventBus.publish(VMS_CONNECTION_STATUS_EVENT, data);
    });
  }

  private openPrivateSocket = ({ sessionId }: { sessionId: number }) => {
    if (!sessionId) {
      this.logger.warn('FAILED to subscribe to WS, there is missing sessionId');

      return;
    }
    const socket = io(`${this.baseUr}/session-${sessionId}`);

    this.logger.log('Try to log in into private websocket channel');
    this.applyInfraSocketEvents(socket);
  };

  private applyInfraSocketEvents(socket: Socket) {
    socket.on('connect', () => {
      this.logger.log('SOCKET connected');
      this.socket = socket;
      this.subscribeSocketToEvents(socket);
    });

    socket.on('error', event => {
      this.logger.error('error', event);
      this.offListeners(socket);
    });

    socket.on('disconnect', event => {
      this.logger.warn('disconnect', event);
      this.offListeners(socket);
    });

    socket.on('close', event => {
      this.logger.log('close', event);
      this.offListeners(socket);
    });

    socket.on('connect_error', err => {
      console.log(err);
      console.log(`connect_error due to ${err.message}`);
    });
  }

  private closeSockets = () => {
    if (this.socket) {
      this.socket.offAny();
      this.socket.off();
      this.socket.close();
    }

    if (this.publicSocket) {
      this.publicSocket.offAny();
      this.publicSocket.off();
      this.publicSocket.close();
    }
  };

  private offListeners(socket: Socket) {
    socket.offAny();
    socket.off();
  }

  private subscribeSocketToEvents(socket: Socket) {
    socket.on(WS_VMS_CAMERA_ONLINE, data => {
      this.eventBus.publish(VMS_CAMERA_ONLINE, data);
    });

    socket.on(WS_VMS_CAMERA_OFFLINE, data => {
      this.eventBus.publish(VMS_CAMERA_OFFLINE, data);
    });

    socket.on(WS_VMS_CAMERA_UPDATED, data => {
      this.eventBus.publish(VMS_CAMERA_UPDATED, data);
    });

    /******* STATION_EVENTS ********/

    socket.on(WS_STATION_UPDATED, data => {
      this.eventBus.publish(STATION_UPDATED, data);
    });

    socket.on(WS_STATION_CONNECTED, data => {
      this.eventBus.publish(STATION_CONNECTED, data);
    });

    socket.on(WS_STATION_DISCONNECTED, data => {
      this.eventBus.publish(STATION_DISCONNECTED, data);
    });

    socket.on(WS_STATION_ALARM, data => {
      this.eventBus.publish(STATION_ALARM, data);
    });

    socket.on(WS_STATION_MEDIUM, data => {
      this.eventBus.publish(STATION_MEDIUM, data);
    });

    socket.on(WS_STATION_NORMAL, data => {
      this.eventBus.publish(STATION_NORMAL, data);
    });

    socket.on(WS_STATION_ISSUE, data => {
      this.eventBus.publish(STATION_ISSUE, data);
    });

    socket.on(WS_STATION_LEARNING, data => {
      this.eventBus.publish(STATION_LEARNING, data);
    });
  }
}
