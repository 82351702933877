import React from 'react';
import { StatusStationModel } from 'types';
import TabsPanel from 'components/TabsPanel';
import StatisticWidget from '../StatisticWidget';
import AnalyticsWidget from '../AnalyticsWidget';
import TodayWidget from '../CurrentWidget/TodayWidget';
import StationName from 'components/StationName';
import AllFrequenciesWidget from '../AllFrequenciesWidget';
import { useSearchParams } from 'react-router-dom';
import Select from 'components/Select';
import useGetSortingValue from 'hooks/useGetSortingValue';
import { COUNT, DESC, FREQUENCY, TABS } from 'consts';
import { useGetAllFrequencies } from 'hooks';
import MultiSelectCheckBoxes from 'components/MultiSelect/MultiSelectCheckBoxes';
import Calendar from 'components/Calendar';
import { useGetStationEvents, StationEventsWidget } from 'pages/shared/StationEvents';
import useGetDocumentScrollTop from 'hooks/useGetDocumentScrollTop';
import { STRAUS_STATUSES } from 'pages/events/utils';
import { Option } from 'components/MultiSelect/types';

import './StationComponent.scss';

interface StationComponentProps {
  station: StatusStationModel;
}

const statusesFilterList = STRAUS_STATUSES.map(status => ({
  value: status,
  label: status
}));

const StationComponent: React.FC<StationComponentProps> = ({ station }) => {
  const [searchParams] = useSearchParams();
  const searchParamsTabIndex = searchParams.get('tabIndex');
  const { frequencies } = useGetAllFrequencies(station);
  const { ControlComponent, menuIsOpen, onChangeFilter, sortValue, direction, options } = useGetSortingValue(
    [FREQUENCY, COUNT],
    DESC
  );

  const { documentScrolledTop } = useGetDocumentScrollTop();
  const titleOpacity = documentScrolledTop > 0 ? 0 : 1;

  const tabs = React.useMemo(() => (station ? TABS : []), [station]);

  const onChangeSortFilter = React.useCallback(
    ({ value }: { value: string }) => {
      onChangeFilter({ value });
    },
    [onChangeFilter]
  );

  const {
    onSubmitCalendar,
    loading,
    eventsMap,
    fetchNextPage,
    onSetStatusFilter,
    onSetAllStatusesFilters,
    statusFilters
  } = useGetStationEvents(station, STRAUS_STATUSES);

  const onSelectStatus = (optionsData: Option) => {
    onSetStatusFilter(optionsData.value as string);
  };

  const tabsPanels = React.useMemo<React.ReactNode[]>(
    () =>
      station
        ? [
            <TodayWidget station={station} />,
            <StatisticWidget station={station} />,
            <AnalyticsWidget station={station} />,
            <AllFrequenciesWidget station={station} sortValue={sortValue} direction={direction} />,
            <StationEventsWidget loading={loading} eventsMap={eventsMap} fetchNextPage={fetchNextPage} />
          ]
        : [],
    [station, sortValue, direction, statusFilters, searchParamsTabIndex, eventsMap]
  );

  const getInterectiveSectionElements = () => {
    if (searchParamsTabIndex === '3' && frequencies.length > 0) {
      return (
        <Select
          className='mr-3 d-flex align-items-center'
          optionsData={options}
          defaultValue={options[0]}
          onChange={onChangeSortFilter}
          menuIsOpen={menuIsOpen}
          components={{ Control: ControlComponent }}
        />
      );
    }
    if (searchParamsTabIndex === '4') {
      return (
        <div className='d-flex gap-3 align-items-center justify-content-end'>
          <MultiSelectCheckBoxes
            placeholder='selectStatus'
            options={statusesFilterList}
            initialSelectedValues={STRAUS_STATUSES}
            onChange={onSelectStatus}
            onSetAllOptions={onSetAllStatusesFilters}
          />
          <Calendar
            placement='leftBottom'
            align={{ offset: [-90, -30] }}
            className='mr-2'
            onSubmit={onSubmitCalendar}
          />
        </div>
      );
    }
  };

  return (
    <TabsPanel
      tabs={tabs}
      tabsPanels={tabsPanels}
      title={
        <div
          style={{
            minWidth: '80px',
            transition: 'opacity .2s ease',
            opacity: searchParamsTabIndex === '4' ? titleOpacity : 1
          }}
        >
          <StationName className='d-flex align-items-center justify-content-center' station={station} />
        </div>
      }
      afterElements={getInterectiveSectionElements()}
      tabsListBodyClassName='layout-width position-fixed'
      tabPanelClassName='pt-5'
    />
  );
};

export default React.memo(StationComponent);
