import React from 'react';
import Text, { TextColor, TextSize } from 'components/Text';
import TextResourceContext from 'contexts/TextResource';
import IconSVG from 'components/IconSVG/IconSVG';
import { StatusStationModel } from 'types';
import { getStationBackgroundStatusColor, getStationIsConnected } from 'utils/stations';
import './DashboardStationCard.scss';

interface DashboardStatusStationBodyProps {
  station: StatusStationModel;
}

const DashboardStatusStationBody: React.FC<DashboardStatusStationBodyProps> = ({ station }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const currentCountColor =
    getStationBackgroundStatusColor(station).replace(TextColor.Success, TextColor.Gray) || TextColor.Gray;
  const currentCountColorReplaced = currentCountColor.replace(TextColor.Gray, TextColor.White);

  if (!station.stationData || !getStationIsConnected(station)) {
    return null;
  }

  return (
    <>
      {station.stationData.normalCount !== null ? (
        <div className='d-flex flex-column mb-1'>
          <Text className='dashboard-station-card__counts-title' size={TextSize.XXs}>
            {getTextResourceByKey('normalCount')}
          </Text>
          <Text size={TextSize.Sm}>{station.stationData.normalCount}</Text>
        </div>
      ) : (
        <IconSVG icon='clock-settings' fontSize={TextSize.Sm} className='mx-auto' color='gray' />
      )}
      <div className='d-flex flex-column'>
        <Text
          className='dashboard-station-card__counts-title'
          size={TextSize.XXs}
          color={currentCountColorReplaced as TextColor}
        >
          {getTextResourceByKey('currentCount')}
        </Text>
        <Text size={TextSize.SmMd} color={currentCountColorReplaced as TextColor}>
          {station.stationData.currentCount}
        </Text>
      </div>
    </>
  );
};

export default DashboardStatusStationBody;
