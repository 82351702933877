import React from 'react';
import useEventBus from 'contexts/EventBusContext';
import {
  STATION_ALARM,
  STATION_CONNECTED,
  STATION_DISCONNECTED,
  STATION_ISSUE,
  STATION_LEARNING,
  STATION_MEDIUM,
  STATION_NORMAL
} from 'services/event-bus';
import { ONE_MINUTE, ONE_SECOND } from 'utils/date';
import { useDispatch, useSelector } from 'react-redux';
import { toastNotification } from 'components/Toaster';
import { getAuthData, getCurrentUserLogin } from 'store/authReducer';
import { ConnectionType, Event, EventFromBE, StationStatusType } from '../types';
import { appendStationEvent, mapStationEvent } from '../store/allEventsReducer';
import { isConnectionStatusEvent, isElescopeStatusEvent, isStatusTypeEvent, isStrausEvent } from 'utils/events';
import { stationIconComponent } from 'components/StationLabel/StationLabel';
import { playSingleSound } from 'utils/sound';
import { AppDispatch } from '../store/types';

export function getMessage(event: Event) {
  if (isConnectionStatusEvent(event)) {
    return `${event.data.previousConnectionStatus} -> ${event.data.connectionStatus}`;
  }
  if (isElescopeStatusEvent(event)) {
    return `${event.data.droneType} - ${event.data.droneId}`;
  }
  if (isStatusTypeEvent(event) && isStrausEvent(event)) {
    return `${event.data.previousStatus} -> ${event.data.currentStatus}`;
  }

  return '';
}

export function getIsErrorNotif(event: Event) {
  if (isConnectionStatusEvent(event) && event.data.connectionStatus === ConnectionType.Connected) {
    return false;
  }

  if (isStatusTypeEvent(event) && isStrausEvent(event) && event.data.currentStatus === StationStatusType.Normal) {
    return false;
  }

  return true;
}

export const exposeEvent =
  (dispatch: AppDispatch) =>
  ({ eventData, withSound }: { eventName: string; eventData: EventFromBE; withSound?: boolean }) => {
    const event = mapStationEvent(eventData);

    dispatch(appendStationEvent(event));

    const isErrorNotif = getIsErrorNotif(event);

    toastNotification(
      {
        title: eventData.box.name,
        message: getMessage(event),
        type: isErrorNotif ? 'error' : 'success',
        date: eventData.createdAt
      },
      {
        IconComponent: stationIconComponent[eventData.box.type],
        duration: isErrorNotif ? ONE_MINUTE * 10 : ONE_SECOND * 10
      }
    );

    if (withSound) {
      playSingleSound(isErrorNotif ? 'negative_beeps.mp3' : 'success.mp3');
    }
  };

export default function useNotificationOfStationEvents() {
  const eventBus = useEventBus();
  const dispatch = useDispatch();
  const userLoggedIn = useSelector(getCurrentUserLogin);
  const authData = useSelector(getAuthData);
  const soundNotifications = authData?.soundNotifications;
  const soundDisconnected = soundNotifications?.stationDisconnected;
  const soundConnected = soundNotifications?.stationConnected;
  const soundAlarm = soundNotifications?.stationAlarm;
  const soundMedium = soundNotifications?.stationMedium;
  const soundNormal = soundNotifications?.stationNormal;
  const soundIssue = soundNotifications?.stationIssue;
  const soundLearning = soundNotifications?.stationLearning;

  React.useEffect(() => {
    if (eventBus && userLoggedIn) {
      const unsubscribeFromStationDiscEvent = eventBus.subscribe(STATION_DISCONNECTED, (eventData: EventFromBE) => {
        exposeEvent(dispatch)({ eventName: STATION_DISCONNECTED, eventData, withSound: soundDisconnected });
      });

      const unsubscribeFromStationConnectEvent = eventBus.subscribe(STATION_CONNECTED, (eventData: EventFromBE) => {
        exposeEvent(dispatch)({ eventName: STATION_CONNECTED, eventData, withSound: soundConnected });
      });

      const unsubscribeFromStationAlarmEvent = eventBus.subscribe(STATION_ALARM, (eventData: EventFromBE) => {
        exposeEvent(dispatch)({ eventName: STATION_ALARM, eventData, withSound: soundAlarm });
      });

      const unsubscribeFromStationMediumEvent = eventBus.subscribe(STATION_MEDIUM, (eventData: EventFromBE) => {
        exposeEvent(dispatch)({ eventName: STATION_MEDIUM, eventData, withSound: soundMedium });
      });

      const unsubscribeFromStationNormalEvent = eventBus.subscribe(STATION_NORMAL, (eventData: EventFromBE) => {
        exposeEvent(dispatch)({ eventName: STATION_NORMAL, eventData, withSound: soundNormal });
      });

      const unsubscribeFromStationIssueEvent = eventBus.subscribe(STATION_ISSUE, (eventData: EventFromBE) => {
        exposeEvent(dispatch)({ eventName: STATION_ISSUE, eventData, withSound: soundIssue });
      });

      const unsubscribeFromStationLearningEvent = eventBus.subscribe(STATION_LEARNING, (eventData: EventFromBE) => {
        exposeEvent(dispatch)({ eventName: STATION_LEARNING, eventData, withSound: soundLearning });
      });

      return () => {
        unsubscribeFromStationConnectEvent();
        unsubscribeFromStationDiscEvent();

        unsubscribeFromStationAlarmEvent();
        unsubscribeFromStationMediumEvent();
        unsubscribeFromStationNormalEvent();
        unsubscribeFromStationIssueEvent();
        unsubscribeFromStationLearningEvent();
      };
    }
  }, [eventBus, userLoggedIn, soundNotifications]);
}
