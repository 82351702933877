import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/types';
import React from 'react';
import { FetchingData, Frequency, StationModel } from '../types';
import {
  abortFrequenciesAction,
  getTodayFrequencies,
  getTodayFrequenciesSelector
} from '../store/todayFrequenciesReducer';

const useGetTodayFrequencies = (station?: StationModel): { frequencies: Frequency[]; loading: boolean } => {
  const dispatch = useDispatch() as AppDispatch;
  const getTodayFrequenciesSelectorCb = React.useMemo(
    () => (state: RootState) => getTodayFrequenciesSelector(state, station?.id),
    [station?.id]
  );

  const { data: frequencies, loading }: FetchingData<Frequency[]> = useSelector(getTodayFrequenciesSelectorCb);

  React.useEffect(() => {
    if (station?.id) {
      dispatch(getTodayFrequencies({ stationId: station.id }));
    }

    return () => {
      dispatch(abortFrequenciesAction());
    };
  }, [station?.id]);

  return { frequencies, loading };
};

export default useGetTodayFrequencies;
