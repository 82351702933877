import React from 'react';
import Pagination from 'components/Pagination';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import CardsLayout from 'components/CardsLayout';
import Card from 'components/Card';
import { FaClock } from 'react-icons/fa';
import { COLORS } from 'consts';
import { CiVideoOn, CiVideoOff } from 'react-icons/ci';
import { useDispatch, useSelector } from 'react-redux';
import { getVmsEventsAction, getVmsEventsState } from 'store/vmsReducer';
import { getDateOfSomeDaysAgo } from 'utils/date';
import { AppDispatch } from 'store/types';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { VmsEvent } from 'types/Vms';
import { VMS_CAMERA_OFFLINE, VMS_CAMERA_ONLINE } from 'services/event-bus';
import TruncatedText from 'components/Text/TruncatedText';
import Input from 'components/Input';
import TextResourceContext from 'contexts/TextResource';
import './Vms.scss';
import { getDebouncedSearch } from 'utils/seacrh';

const getBgCardColor = (event: VmsEvent) => (event.type === VMS_CAMERA_ONLINE ? 'success' : 'black');

const getIconComponent = (event: VmsEvent, props: { size: number }) => {
  if (event.type === VMS_CAMERA_OFFLINE) {
    return <CiVideoOff color={COLORS.white} size={props.size} />;
  }
  if (event.type === VMS_CAMERA_ONLINE) {
    return <CiVideoOn color={COLORS.white} size={props.size} />;
  }

  return null;
};

const VmsEvents = () => {
  const dispatch = useDispatch() as AppDispatch;
  const [searchFilter, setSearchFilter] = React.useState('');
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const [filteredEvents, setFilteredEvents] = React.useState<VmsEvent[]>([]);

  const {
    loaded,
    data: { currentPage, data: events, totalPages }
  } = useSelector(getVmsEventsState);

  React.useEffect(() => {
    debouncedSearch(searchFilter, {
      keys: ['cameraName', 'type']
    });
  }, [events]);

  const fetchNextPage = React.useCallback(() => {
    const nextPage = !loaded ? 0 : currentPage + 1;

    if (!loaded || nextPage < totalPages) {
      dispatch(
        getVmsEventsAction({
          page: nextPage,
          pageSize: 100,
          startDate: getDateOfSomeDaysAgo(30),
          endDate: getDateOfSomeDaysAgo(0)
        })
      );
    }
  }, [currentPage, loaded, totalPages]);

  React.useEffect(() => {
    fetchNextPage();
  }, []);

  const eventsMap = React.useMemo<Record<string, VmsEvent[]>>(
    () =>
      filteredEvents.reduce<Record<string, VmsEvent[]>>((acc, vmsEvent) => {
        if (!acc[vmsEvent.date]) {
          acc[vmsEvent.date] = [];
        }
        acc[vmsEvent.date].push(vmsEvent);

        return acc;
      }, {}),
    [filteredEvents]
  );

  const debouncedSearch = React.useMemo(
    () => getDebouncedSearch<VmsEvent>(setFilteredEvents, events),
    [setFilteredEvents, events]
  );

  const onSearchFilter = React.useCallback(
    (val: string) => {
      setSearchFilter(val);
      debouncedSearch(val, {
        keys: ['cameraName', 'type']
      });
    },
    [debouncedSearch, setSearchFilter]
  );

  React.useEffect(() => {
    debouncedSearch(searchFilter, {
      keys: ['cameraName', 'type']
    });
  }, [events]);

  return (
    <>
      <div className='overflow-y'>
        <Pagination callback={fetchNextPage}>
          <div className='position-relative'>
            <Input
              value={searchFilter}
              onSetValue={onSearchFilter}
              placeholder={getTextResourceByKey('search') as string}
              withEmptyStringValidation={false}
              className='ml-2 mb-2'
              wrapperClassName='position-sticky bg-dark-gray vms-events-search-input-wrapper'
              style={{ top: '130px', maxWidth: '300px' }}
            />
            {Object.entries(eventsMap).map(([date, eventsByDate]) => (
              <div className='mb-4' key={date}>
                <Text
                  color={TextColor.White}
                  className='mr-2 position-sticky text-end'
                  style={{ top: '95px', zIndex: 2 }}
                >
                  {date}
                </Text>
                <CardsLayout cardMinWidth='200px'>
                  {eventsByDate.map(event => (
                    <Card
                      key={event.id}
                      bodyClassName='p-2'
                      bottomBar={
                        <div
                          className={classNames(
                            'd-flex justify-content-around p-2',
                            `background-${getBgCardColor(event)}`
                          )}
                        >
                          <Text color={TextColor.White} size={TextSize.XSm}>
                            {event.type}
                          </Text>
                        </div>
                      }
                    >
                      <div className='d-flex justify-content-between align-items-center px-1 mb-1'>
                        {getIconComponent(event, { size: 20 })}
                        <div className='d-flex flex-row flex-justify-end align-items-center w-50'>
                          <FaClock size={14} className='mr-1' color={COLORS.gray} />
                          <Text size={TextSize.Sm} color={TextColor.HalfLight} className='no-white-space'>
                            {dayjs(new Date(event.createdAt)).format('HH:mm:ss')}
                          </Text>
                        </div>
                      </div>
                      <div style={{ height: '1.25rem' }}>
                        <TruncatedText color={TextColor.Light} size={TextSize.Sm}>
                          {event.cameraName}
                        </TruncatedText>
                      </div>
                    </Card>
                  ))}
                </CardsLayout>
              </div>
            ))}
          </div>
        </Pagination>
      </div>
    </>
  );
};

export default VmsEvents;
