import { createAction, createAsyncThunk, createSlice, isRejected } from '@reduxjs/toolkit';
import { ConfigsBody, ConfigsResponse, FetchingData, StationSettings, ThunkOptions } from '../types';
import { AppDispatch, RootState, ThunkExtraArgumentType } from './types';
import { createEmptyFetchedState } from './utils';
import snakecaseKeys from 'snakecase-keys';
import { v4 as uuidv4 } from 'uuid';
import { removeStationSettingSuccessAction, resetStateAction } from './shared';
import camelcaseKeys from 'camelcase-keys';

interface InitialState {
  [key: string]: ConfigsResponse;
}
const initialState: InitialState = {};

export const getStationConfigs = createAsyncThunk<ConfigsResponse, { stationId: number }>(
  'CONFIGS@GET_CONFIGS',
  ({ stationId }, { extra: { api } }: ThunkOptions) => api.get(`stations/${stationId}/settings`)
);

export const updateStationConfigs = createAsyncThunk<ConfigsResponse, ConfigsBody>(
  'CONFIGS@UPDATE_CONFIGS',
  ({ stationId, configs }, { dispatch, extra: { api } }: ThunkOptions) => {
    if (configs) {
      const snakeCaseConfig = snakecaseKeys(configs);

      return api.post(`stations/${stationId}/settings`, snakeCaseConfig).then((response: ConfigsResponse) => {
        if (!isRejected(response)) {
          return dispatch(getStationConfigs({ stationId }));
        }
      });
    }
  }
);

const updateLocalSettings = createAction<{ stationId: number; settings: StationSettings }>(
  'CONFIGS@UPDATE_FREQUENCY_SETTINGS'
);

export const updateStationWithoutRefetch =
  (stationId: number, settings: StationSettings) =>
  (dispatch: AppDispatch, _: () => RootState, extra: ThunkExtraArgumentType) => {
    const snakeCaseConfig = snakecaseKeys(settings);

    extra.api.post(`stations/${stationId}/settings`, snakeCaseConfig);
    dispatch(updateLocalSettings({ stationId, settings }));
  };

const configsSlice = createSlice({
  name: 'configStation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStationConfigs.pending, (state, action) => {
      const stationId = action.meta.arg.stationId;

      state[stationId] = {
        ...state[stationId],
        loading: true,
        error: null
      };
    });
    builder.addCase(getStationConfigs.rejected, (state, action) => {
      if (action.error) {
        const errorMsg = action.error.message;
        const stationId = action.meta.arg.stationId;

        state[stationId] = {
          ...state[stationId],
          loading: false,
          error: errorMsg
        };
      }
    });
    builder.addCase(getStationConfigs.fulfilled, (state, action) => {
      if (action.payload) {
        const response: StationSettings = action.payload;
        const stationId = action.meta.arg.stationId;

        state[stationId] = {
          loaded: true,
          error: null,
          data: response?.map(configItem => ({ ...camelcaseKeys(configItem), id: uuidv4() }))
        };
      }
    });

    builder.addCase(updateLocalSettings, (state, { payload: { stationId, settings } }) => {
      state[stationId] = {
        loaded: true,
        error: null,
        data: settings
      };
    });
    builder.addCase(resetStateAction.fulfilled, () => initialState);
    builder.addCase(removeStationSettingSuccessAction, (state, action) => {
      const stationId = action.payload;

      state[stationId] = createEmptyFetchedState(null);
    });
  }
});

const defaultConfigStationData = createEmptyFetchedState(null);

export const configsReducer = configsSlice.reducer;
export const getConfigsStationSelector = (state: RootState, stationId: number): FetchingData<StationSettings> =>
  stationId ? state.configs[stationId] || defaultConfigStationData : defaultConfigStationData;
