import React from 'react';
import Spinner from 'components/Spinner';
import { Event, StyledComponent } from 'types';
import { isEmpty } from 'lodash';
import EmptyState from 'components/EmptyState';
import EventsPaginationWidget from '../../events/EventsPaginationWidget';

interface EventsWidgetProps extends StyledComponent {
  loading: boolean;
  eventsMap: {
    [k: string]: Event[];
  };
  fetchNextPage: () => void;
}

const StationEventsWidget: React.FC<EventsWidgetProps> = ({ loading, eventsMap, fetchNextPage, className }) => (
  <div className={className}>
    {loading ? (
      <Spinner isFullSize />
    ) : (
      <>
        {isEmpty(eventsMap) ? (
          <EmptyState />
        ) : (
          <EventsPaginationWidget eventsMap={eventsMap} fetchNextPage={fetchNextPage} />
        )}
      </>
    )}
  </div>
);

export default StationEventsWidget;
