import React from 'react';
import { StatusStationModel } from 'types';
import TabsPanel from 'components/TabsPanel';
import StatisticGrid from './StatisticGrid';
import StatisticGraphics from './StatisticGraphics';
import { resetStationStatistic } from 'store/statisticReducer';
import { resetStationStatisticFrequencies } from 'store/statisticFrequenciesReducer';
import { useDispatch } from 'react-redux';
import useGetStatics from 'hooks/useGetStatics';
import Calendar from 'components/Calendar';
import { useSearchParams } from 'react-router-dom';

import '../StationRootComponent.scss';
import { SUBTAB_INDEX_PREFIX } from 'components/TabsPanel/TabsPanel';

interface StatisticComponentProps {
  station: StatusStationModel;
  onlyToday?: boolean;
}

const tabs = ['grid', 'graphic'];

const StatisticWidget: React.FC<StatisticComponentProps> = ({ station, onlyToday }) => {
  const dispatch = useDispatch();
  const { periodDate, setPeriodDate } = useGetStatics(station, onlyToday);

  const [searchParams] = useSearchParams();
  const searchParamsTabIndexStatistic = searchParams.get('subTabIndexStatistic');
  const searchParamsTabIndex = searchParams.get('tabIndex');
  const isPreElemetnsRender =
    !onlyToday &&
    ((searchParamsTabIndex === '1' && !searchParamsTabIndexStatistic) || searchParamsTabIndexStatistic === '0');

  const tabsPanels = React.useMemo<React.ReactNode[]>(
    () =>
      station
        ? [
            <StatisticGrid station={station} onlyToday={onlyToday} />,
            <StatisticGraphics station={station} onlyToday={onlyToday} />
          ]
        : [],
    [station]
  );

  React.useEffect(
    () => () => {
      dispatch(resetStationStatistic(station.id));
      dispatch(resetStationStatisticFrequencies(station.id));
    },
    []
  );

  return (
    <TabsPanel
      tabs={tabs}
      preElements={
        isPreElemetnsRender ? (
          <div className='align-self-center mr-2'>
            <Calendar
              onSubmit={setPeriodDate}
              placement='bottomRight'
              align={{ offset: [-90, 30] }}
              defaultValue={periodDate}
            />
          </div>
        ) : null
      }
      tabsPanels={tabsPanels}
      searchQuery={`${SUBTAB_INDEX_PREFIX}Statistic`}
      tabsListBodyClassName='inner-tabs-position-right position-fixed'
    />
  );
};

export default React.memo(StatisticWidget);
