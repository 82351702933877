import React from 'react';
import TabsPanel from 'components/TabsPanel';
import Frequencies from '../shared/Frequencies';
import StationCurrentCharts from './StationCurrentCharts';
import { StationModel } from 'types';
import useGetTodayFrequencies from 'hooks/useGetTodayFrequencies';
import { SUBTAB_INDEX_PREFIX } from 'components/TabsPanel/TabsPanel';
import Select from 'components/Select';
import { COUNT, DESC, FREQUENCY } from 'consts';
import useGetSortingValue from 'hooks/useGetSortingValue';
import { useSearchParams } from 'react-router-dom';

interface CurrentPanelProps {
  station: StationModel;
}

const tabs = ['grid', 'graphic'];

const TodayWidget: React.FC<CurrentPanelProps> = ({ station }) => {
  const { frequencies, loading } = useGetTodayFrequencies(station);
  const [searchParams] = useSearchParams();
  const searchParamsSubTabIndexCurrent = searchParams.get('subTabIndexCurrent');

  const { ControlComponent, menuIsOpen, onChangeFilter, sortValue, direction, options } = useGetSortingValue(
    [FREQUENCY, COUNT],
    DESC
  );

  const sortedFrequencies = React.useMemo(() => {
    const sign = direction === DESC ? -1 : 1;
    const sortedFreqs = frequencies && direction === DESC ? [...frequencies].reverse() : frequencies;

    return sortedFreqs && (sortValue === FREQUENCY || sortValue === COUNT)
      ? [...sortedFreqs].sort((freq1, freq2) => (freq1[sortValue] > freq2[sortValue] ? sign : -sign))
      : sortedFreqs;
  }, [sortValue, direction, frequencies]);

  const tabsPanels = React.useMemo<React.ReactNode[]>(
    () =>
      station
        ? [
            <Frequencies loading={loading} frequencies={sortedFrequencies} station={station} blockFreqEnabled={true} />,
            <StationCurrentCharts loading={loading} frequencies={frequencies} />
          ]
        : [],
    [station, frequencies, sortValue, direction]
  );

  return (
    <TabsPanel
      tabs={tabs}
      tabsPanels={tabsPanels}
      searchQuery={`${SUBTAB_INDEX_PREFIX}Current`}
      tabsListBodyClassName='inner-tabs-position-right position-fixed'
      preElements={
        searchParamsSubTabIndexCurrent === '0' &&
        frequencies?.length > 0 && (
          <Select
            className='mr-3 d-flex align-items-center'
            optionsData={options}
            defaultValue={options[0]}
            onChange={onChangeFilter}
            menuIsOpen={menuIsOpen}
            components={{ Control: ControlComponent }}
          />
        )
      }
    />
  );
};

export default TodayWidget;
