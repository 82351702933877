import React from 'react';
import { VmsCamera } from 'types/Vms';
import VideoPlayer from 'components/VideoPlayer';
import Text, { TextColor } from 'components/Text';
import { getUrl } from 'utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { getVideoWalls, updateCameraFromVideoWall } from 'store/vmsReducer';
import VideoPlayerPlaceholder from 'components/VideoPlayer/VideoPlayerPlaceholder';
import VmsCamerasDropdown from '../VmsCamerasDropdown';
import { AppDispatch } from 'store/types';
import TruncateMarkup from 'react-truncate-markup';

interface VmsLayoutsProps {
  cameras: VmsCamera[];
}

const VmsWalls: React.FC<VmsLayoutsProps> = () => {
  const cameras = useSelector(getVideoWalls);
  const dispatch = useDispatch() as AppDispatch;

  const onChangeCamera = React.useCallback(
    (index: number) => (camera: VmsCamera) => {
      dispatch(updateCameraFromVideoWall({ index, camera }));
    },
    []
  );

  const camerasWithPlaceholders = React.useMemo(() => Array.from(new Array(4)).map((_, i) => cameras[i]), [cameras]);

  return (
    <div className='w-100 relative flex flex-wrap gap-3'>
      {camerasWithPlaceholders.map((camera, i) => {
        if (!camera) {
          return (
            <div style={{ width: '49%' }} key={i}>
              <div className='w-100 relative' style={{ paddingTop: `${(9 / 16) * 100}%` }}>
                <div className='absolute-centered'>
                  <VideoPlayerPlaceholder />
                </div>
                <div className='p-absolute right-3 bottom-5'>
                  <VmsCamerasDropdown onSelectCamera={onChangeCamera(i)} />
                </div>
              </div>
            </div>
          );
        }

        const coords = [camera.latitude, camera.longitude];

        return (
          <div style={{ width: '49%' }} key={camera.id + i}>
            <div>
              <TruncateMarkup lines={1} ellipsis='...'>
                <div>
                  <Text color={TextColor.White}>{camera.name}</Text>
                </div>
              </TruncateMarkup>
              <Text color={TextColor.Silver}>{coords?.join(', ')}</Text>
            </div>
            <div className='relative'>
              <VideoPlayer src={getUrl(camera.src)} />
              <div className='p-absolute right-3 bottom-5'>
                <VmsCamerasDropdown onSelectCamera={onChangeCamera(i)} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VmsWalls;
