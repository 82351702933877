import React from 'react';
import TextResourceContext from 'contexts/TextResource';
import Text, { TextColor } from 'components/Text/Text';
import { useOnClickOutside, useToggle } from 'usehooks-ts';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { ASC, COLORS, DESC } from '../consts';

import './hooks.scss';
import { flatten } from 'lodash';

const getComponent = (key: string) =>
  key === 'desc' ? <BsArrowDown color={COLORS.gray} size='16' /> : <BsArrowUp color={COLORS.light} size='16' />;

const useGetSortingValue = (sortingKeys: string[], initDirection = DESC, defaultValue?: string) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const [sortValue, setSortValue] = React.useState(defaultValue || sortingKeys[0]);
  const [direction, setDirection] = React.useState(initDirection);
  const [menuIsOpen, toggleMenuIsOpen, setMenuIsOpen] = useToggle(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const closeMenu = React.useCallback(() => setMenuIsOpen(false), [menuIsOpen]);

  useOnClickOutside(ref, closeMenu);

  const onChangeFilter = ({ value }: { value: string }) => {
    const [sort, direct] = value.split('/');

    setSortValue(sort);
    setDirection(direct);
    setMenuIsOpen(false);
  };

  const options = React.useMemo(
    () =>
      flatten(
        sortingKeys.map(sortKey => {
          const labelDown = getTextResourceByKey(`${sortKey}Down`, {
            down: <BsArrowDown size='14' />
          });
          const labelUp = getTextResourceByKey(`${sortKey}Up`, { up: <BsArrowUp size='14' /> });

          return labelUp && labelDown
            ? [
                {
                  value: `${sortKey}/${DESC}`,
                  label: getTextResourceByKey(`${sortKey}Down`, {
                    down: <BsArrowDown size='14' />
                  })
                },
                {
                  value: `${sortKey}/${ASC}`,
                  label: getTextResourceByKey(`${sortKey}Up`, { up: <BsArrowUp size='14' /> })
                }
              ]
            : [];
        })
      ),
    [sortingKeys, getTextResourceByKey]
  );

  const getFilterValue = React.useCallback((filterValue: string) => {
    const [filter, sort] = filterValue.split('/');

    return (
      <div className='d-flex align-items-center gap-1 justify-content-center'>
        {/*<Text size={TextSize.SmMd}>{getTextResourceByKey('sortedBy')}</Text>*/}
        <Text color={TextColor.Light} className='d-inline uppercase'>
          {getTextResourceByKey(filter)}
        </Text>
        {getComponent(sort)}
      </div>
    );
  }, []);

  const ControlComponent = React.useCallback(
    (props: any) => {
      const { value } = props.getValue()[0];

      return (
        <div onClick={toggleMenuIsOpen} className='filter-control py-1' ref={ref}>
          {getFilterValue(value)}
        </div>
      );
    },
    [toggleMenuIsOpen]
  );

  return { ControlComponent, options, sortValue, direction, menuIsOpen, onChangeFilter };
};

export default useGetSortingValue;
