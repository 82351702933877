import React from 'react';
import useGetStatisticFrequencies, { OPTIONS } from 'hooks/useGetStatisticFrequencies';
import { StatusStationModel, StyledComponent } from 'types';
import TextResourceContext from 'contexts/TextResource';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import HTMLBarChart from 'components/HTMLBarChart/HTMLBarChart';
import Calendar from 'components/Calendar';
import Select from 'components/Select';
import { SelectOption } from 'components/Select/Select';
import ErrorMessage from 'components/ErrorMessage';

interface StatisticGraphicCountProps extends StyledComponent {
  station: StatusStationModel;
  onlyToday?: boolean;
}

const StatisticGraphicCount: React.FC<StatisticGraphicCountProps> = ({ station, className, onlyToday }) => {
  const { loading, fetchStatisticsFrequencies, data, error, setPeriodDate, periodDate, sortType, setSortType } =
    useGetStatisticFrequencies({ station, onlyToday });
  const isManageParamsVisible = !onlyToday;

  const formattedCountChartData = React.useMemo(
    () =>
      data.map(dataItem => ({
        x: dataItem.frequency,
        y: dataItem.count,
        id: dataItem.frequency + dataItem.count
      })),
    [data]
  );
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  const onSetSortTypeFormatted = React.useCallback(
    (option: SelectOption) => {
      const newSortType = option.value;

      if (newSortType !== sortType) {
        setSortType(option.value.toString());
      }
    },
    [sortType]
  );

  const options = React.useMemo(
    () => OPTIONS.map(opt => ({ value: opt.value, label: getTextResourceByKey(opt.label) as string })),
    []
  );
  const defaultOption = React.useMemo(
    () => ({ value: OPTIONS[0].value, label: getTextResourceByKey(OPTIONS[0].label) as string }),
    []
  );

  return (
    <div className={className}>
      <div className='flex-space-around mb-4'>
        <div className='d-flex'>
          <Text size={TextSize.MdLg} color={TextColor.Success} className='uppercase text-center align-self-center'>
            {getTextResourceByKey('statisticGraphicCount/Time')}
          </Text>
        </div>
        {isManageParamsVisible && (
          <div className='d-flex'>
            <div className='p-2'>
              <Calendar placement='bottomRight' onSubmit={setPeriodDate} defaultValue={periodDate} />
            </div>
            {data.length > 0 && (
              <Select
                value={sortType}
                optionsData={options}
                onChange={onSetSortTypeFormatted}
                defaultValue={defaultOption}
              />
            )}
          </div>
        )}
      </div>
      {error ? (
        <ErrorMessage message={error.message} />
      ) : (
        <HTMLBarChart
          data={formattedCountChartData}
          paginationCallback={fetchStatisticsFrequencies}
          xUnit={getTextResourceByKey('frequency') as string}
          yUnit={getTextResourceByKey('count') as string}
          isLoading={loading}
        />
      )}
    </div>
  );
};

export default StatisticGraphicCount;
