import React from 'react';
import { StationModel } from 'types';
import StationsStationCard from '../StationsStationCard';
import { ModalContext } from 'contexts/ModalContext';
import AddStationModal from '../AddStationModal';
import CardsLayout from 'components/CardsLayout';
import AddStationCard from '../AddStationCard';

import './StationsComponent.scss';

interface StationsComponentProps {
  stations: StationModel[];
}

const StationsComponent: React.FC<StationsComponentProps> = ({ stations }) => {
  const { openModal, closeModal } = React.useContext(ModalContext);

  const onAddStation = React.useCallback(() => {
    openModal(<AddStationModal closeModal={closeModal} />);
  }, []);

  if (!stations) {
    return null;
  }

  return (
    <div className='stations'>
      <CardsLayout cardMinWidth='240px'>
        <AddStationCard onClick={onAddStation} />
        {stations.map(station => (
          <StationsStationCard key={station.id} station={station} />
        ))}
      </CardsLayout>
    </div>
  );
};

export default StationsComponent;
