import React from 'react';
import AuthFormComponent from '../AuthFormComponent';
import TextResourceContext from 'contexts/TextResource';
import LocalizationSwitcher from 'components/LocalizationSwitcher';
import Text, { TextColor, TextSize } from 'components/Text';
import './AuthComponent.scss';

const AuthComponent = () => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  return (
    <div className='flex auth__wrapper container-fluid '>
      <div
        className='auth__logo flex-column-center w-100'
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/logo.svg'})` }}
      />
      <div className='absolute-centered-x auth__container__layout'>
        <div className='flex two auth__container'>
          <div className='w-100 flex-column-center color-white auth__form'>
            <div className='mb-4'>
              <Text size={TextSize.ExtraHg} color={TextColor.White} className='mb-2'>
                {getTextResourceByKey('authLoginTitle')}
              </Text>
              <Text size={TextSize.Md} color={TextColor.White}>
                {getTextResourceByKey('authLoginSubtitle')}
              </Text>
            </div>
            <AuthFormComponent />
          </div>
          <div className='auth__localization-container'>
            <LocalizationSwitcher />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthComponent;
