import React from 'react';
import { VmsCameraFolder } from 'types/Vms';
import CheckBoxCameraItem from './CheckBoxCameraItem';
import Text, { TextColor, TextSize } from 'components/Text';
import { COLORS } from 'consts';
import { FaCaretDown } from 'react-icons/fa';
import TextResourceContext from 'contexts/TextResource';
import { CiFolderOn } from 'react-icons/ci';

function getRecursivelyAllCamIds(camIds: number[], fold: VmsCameraFolder) {
  fold.cameras.forEach(cam => {
    camIds.push(cam.id);
  });

  fold.folders.forEach(innerFold => {
    getRecursivelyAllCamIds(camIds, innerFold);
  });

  return camIds;
}

interface CheckboxCamerasFolderProps {
  folder: VmsCameraFolder;
  onSelectCameras: (camIds: number[], mode: string) => void;
  isDefaultExpanded?: boolean;
  selectedCameraIds: number[];
}

const CheckboxCamerasFolder: React.FC<CheckboxCamerasFolderProps> = ({
  folder,
  onSelectCameras,
  isDefaultExpanded = false,
  selectedCameraIds
}) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const [isExpanded, setIsExpanded] = React.useState(isDefaultExpanded);
  const ref = React.useRef<HTMLDivElement>(null);

  const folderIsSelected = React.useMemo(() => {
    const folderCamIds = getRecursivelyAllCamIds([], folder);
    const selectedCameraIdsMap = selectedCameraIds.reduce<Record<number, boolean>>((acc, id) => {
      acc[id] = true;

      return acc;
    }, {});

    return folderCamIds.every(camId => Boolean(selectedCameraIdsMap[camId]));
  }, [selectedCameraIds]);

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const onClickFolder = () => {
    function pushRecursivelyCamIds(camIds: number[], fold: VmsCameraFolder) {
      fold.cameras.forEach(cam => {
        camIds.push(cam.id);
      });

      fold.folders.forEach(innerFold => {
        pushRecursivelyCamIds(camIds, innerFold);
      });

      return camIds;
    }

    const camIds = pushRecursivelyCamIds([], folder);

    onSelectCameras(camIds, folderIsSelected ? 'deselect' : 'select');
  };

  return (
    <div>
      <Text
        style={{ borderColor: COLORS.gray }}
        className='pr-1 d-flex justify-content-between align-items-start w-100'
        color={TextColor.Gray}
        size={TextSize.Sm}
      >
        <div className='d-flex align-items-center gap-1 cursor-pointer' onClick={toggleExpand}>
          <FaCaretDown style={{ transform: `rotate(${isExpanded ? '0' : '-90'}deg)` }} />
          <CiFolderOn size={16} />
          <span>{folder.name || getTextResourceByKey('All')}</span>
        </div>

        <input
          className='float-right'
          type='checkbox'
          checked={folderIsSelected}
          name='option'
          value='value'
          onChange={onClickFolder}
        />
      </Text>
      <span ref={ref} style={{ height: 0 }} />
      <div
        className='pl-2 position-relative w-100 pt-2 overflow-hidden'
        style={{ maxHeight: isExpanded ? '100%' : '0', height: '100%' }}
      >
        {folder.folders.map(subFolder => (
          <CheckboxCamerasFolder
            folder={subFolder}
            key={subFolder.name}
            onSelectCameras={onSelectCameras}
            selectedCameraIds={selectedCameraIds}
          />
        ))}
        {folder.cameras.map(camera => (
          <CheckBoxCameraItem
            camera={camera}
            onClick={() => onSelectCameras([camera.id], 'toggle')}
            isSelected={selectedCameraIds.includes(camera.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxCamerasFolder;
