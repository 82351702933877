import React from 'react';
import { SimpleFrequencies } from '../shared/Frequencies';
import { StatusStationModel } from 'types';
import { COUNT, DESC, FREQUENCY } from 'consts';
import { useGetAllFrequencies } from 'hooks';
import Pagination from 'components/Pagination';
import EmptyState from 'components/EmptyState';
import Spinner from 'components/Spinner';

interface BlockedFrequenciesWidgetProps {
  station: StatusStationModel;
  sortValue: string;
  direction: string;
}

const ITEMS_ON_PAGE = 100;

const AllFrequenciesWidget: React.FC<BlockedFrequenciesWidgetProps> = ({ station, sortValue, direction }) => {
  const { frequencies, loading } = useGetAllFrequencies(station);
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [sortValue, direction]);

  const sortedFrequencies = React.useMemo(() => {
    const sign = direction === DESC ? -1 : 1;
    const sortedFreqs = direction === DESC ? [...frequencies].reverse() : frequencies;
    const slicedFreqs = sortedFreqs.slice(0, currentPage * ITEMS_ON_PAGE);

    return sortValue === FREQUENCY || sortValue === COUNT
      ? [...slicedFreqs].sort((freq1, freq2) => (freq1[sortValue] > freq2[sortValue] ? sign : -sign))
      : slicedFreqs;
  }, [sortValue, direction, frequencies, currentPage]);

  const totalPages = Math.ceil(frequencies.length / ITEMS_ON_PAGE);

  const onNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (loading) {
    return <Spinner isFullSize />;
  }

  if (!loading && frequencies.length === 0) {
    return <EmptyState />;
  }

  return (
    <Pagination callback={onNextPage}>
      <SimpleFrequencies frequencies={sortedFrequencies} station={station} blockFreqEnabled />
    </Pagination>
  );
};

export default AllFrequenciesWidget;
