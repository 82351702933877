import React from 'react';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import Card from 'components/Card';
import TextResourceContext from 'contexts/TextResource';
import { first, last } from 'lodash';
import classNames from 'classnames';
import { Coordinate } from 'ol/coordinate';
import { FlightWithDroneDetails } from '../elescope/types';
import ButtonIcon from 'components/ButtonIcon';
import { ButtonSize, ButtonType } from 'components/Button';
import { MdBlockFlipped } from 'react-icons/md';
import { COLORS } from 'consts';
import { HiOutlineEye } from 'react-icons/hi';

interface DroneCardProps {
  flight: FlightWithDroneDetails;
  disabled?: boolean;
  onFlightCardClick: (id: string) => void;
  flyTo: (coords: Coordinate) => void;
}

const DroneFlightCard: React.FC<DroneCardProps> = ({ flight, disabled, onFlightCardClick, flyTo }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  const lastCoord = last(flight.coords) as Coordinate;
  const startCoord = first(flight.coords) as Coordinate;

  const labelStyle = React.useMemo(
    () => ({
      background: flight.color
    }),
    [flight.color]
  );

  const onClick = React.useCallback(() => {
    onFlightCardClick(flight.id);
  }, [flight]);

  const onShowBtnClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      flyTo([+lastCoord[0], +lastCoord[1]]);
    },
    [lastCoord]
  );

  if (!startCoord) {
    return null;
  }

  return (
    <Card className={classNames('elescope-drone-card m-0 mb-3 p-2 w-100 pr-3', { 'opacity-25': disabled })}>
      <div className='d-flex w-100 justify-content-between'>
        <div>
          <Text size={TextSize.Sm} color={TextColor.Warning} className='mb-1'>
            {flight.droneType}
          </Text>
          <Text color={TextColor.White} size={TextSize.Md} className='mb-2'>
            {flight.droneId}
          </Text>
        </div>
        <div>
          <Text size={TextSize.Sm} className='mb-1'>
            {getTextResourceByKey('detectedTime')}
          </Text>
          <Text size={TextSize.SmMd} color={TextColor.Light} className='mb-2'>
            {flight.startDate.split(' ')[0]}
          </Text>
        </div>

        <div className='flex'>
          <ButtonIcon size={ButtonSize.SmallMedium} className='mr-1 p-1' buttonType={ButtonType.Dark} onClick={onClick}>
            <MdBlockFlipped size={20} color={COLORS.danger} />
          </ButtonIcon>
          <ButtonIcon
            size={ButtonSize.SmallMedium}
            className='p-1'
            buttonType={ButtonType.Dark}
            onClick={onShowBtnClick}
          >
            <HiOutlineEye size={20} color={COLORS.warning} />
          </ButtonIcon>
        </div>
      </div>

      <div className='flex align-items-baseline justify-content-between mb-1'>
        <Text size={TextSize.Sm} color={TextColor.Light} className='mr-3'>
          {getTextResourceByKey('initCoordinate')}
        </Text>
        <Text size={TextSize.SmMd} color={TextColor.Light}>
          {startCoord.join(', ')}
        </Text>
      </div>

      <div className='flex align-items-baseline justify-content-between'>
        <Text size={TextSize.Sm} color={TextColor.Light} className='mr-3'>
          {getTextResourceByKey('currentCoordinate')}
        </Text>
        <Text size={TextSize.SmMd} color={TextColor.White}>
          {lastCoord.join(', ')}
        </Text>
      </div>

      <div className='elescope-drone-card__label' style={labelStyle} />
    </Card>
  );
};

export default DroneFlightCard;
