import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/types';
import { AddStationBody, StationModel } from 'types';
import { addStationAction, updateStationAction } from 'store/stationsReducer';
import BaseStationModal from './BaseStationModal';
import TextResourceContext from 'contexts/TextResource';
import { get, omit, pick } from 'lodash';

interface EditStationModalProps {
  closeModal: () => void;
  station: StationModel;
}

const EditStationModal: React.FC<EditStationModalProps> = ({ closeModal, station }) => {
  const dispatch = useDispatch() as AppDispatch;
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  const onEditStation = React.useCallback(
    (settings: Partial<AddStationBody>) => {
      const settingsToUpdate: Record<string, string | number> = {};

      for (const key in settings) {
        if (get(settings, key) !== get(station, key)) {
          settingsToUpdate[key] = get(settings, key);
        }
      }

      return dispatch(
        updateStationAction({
          stationId: station.id,
          body: omit(settingsToUpdate, 'type')
        })
      ).then(closeModal);
    },
    [closeModal, addStationAction, station]
  );

  const initialSettings = React.useMemo<Partial<AddStationBody>>(
    () => pick(station, ['name', 'ip', 'latitude', 'longitude', 'port', 'location']),
    [station]
  );

  return (
    <BaseStationModal
      onSubmit={onEditStation}
      title={getTextResourceByKey('updateSettings') as string}
      initialSettings={initialSettings}
      stationTypeProp={station.type}
    />
  );
};

export default EditStationModal;
