import React from 'react';
import UserTemplate from './UserTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/types';
import TextResourceContext from 'contexts/TextResource';
import { NewUser, PageRoutes } from 'types';
import { fetchUserAction, getUsersData, updateUserAction } from 'store/usersReducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { extendUrlByQueries } from 'utils/api';
import HeaderComponent from 'components/HeaderComponent';
import { COLORS, LOGO_SIZE } from 'consts';
import useFetchDataForUserPage from './useFetchDataForUserPage';
import { FaUserEdit } from 'react-icons/fa';

const EditUserComponent = () => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const navigate = useNavigate();
  const dispatch = useDispatch() as AppDispatch;
  const location = useLocation();
  const users = useSelector(getUsersData);

  useFetchDataForUserPage();
  const userId = location.pathname.match(/\/users\/(\d+)\/edit/)?.[1];
  const user = users.find(userItem => userId && userItem.id === +userId);

  React.useEffect(() => {
    if (user) {
      dispatch(fetchUserAction(user.id));
    }
  }, [user?.id]);

  const onEditUser = (userFields: Partial<NewUser>) => {
    const stationIds = userFields.stationIds;
    const cameraIds = userFields.cameraIds;

    if (!userFields || !user) {
      return;
    }

    dispatch(updateUserAction({ id: user.id, ...userFields, stationIds, cameraIds })).then(() => {
      navigate(extendUrlByQueries(PageRoutes.AdminPanel, { tabIndex: 1 }));
    });
  };

  const userPermissionsLoaded = user?.cameraIds && user?.stationIds;
  const initialSettings = React.useMemo(() => {
    if (!userPermissionsLoaded) {
      return null;
    }

    return {
      name: user.name,
      login: user.login,
      stationIds: user.stationIds || [],
      cameraIds: user.cameraIds || []
    };
  }, [userPermissionsLoaded]);

  if (!initialSettings) {
    return null;
  }

  return (
    <div>
      <HeaderComponent logo={<FaUserEdit size={LOGO_SIZE} color={COLORS.white} />} />
      <div className='py-5'>
        <UserTemplate
          initialState={initialSettings}
          onSubmit={onEditUser}
          title={getTextResourceByKey('editUser')}
          submitTitle={getTextResourceByKey('save')}
          isEditMode
        />
      </div>
    </div>
  );
};

export default EditUserComponent;
