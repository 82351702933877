import { Coordinate } from 'ol/coordinate';
import moment from 'moment';
import { AxiosError, AxiosResponse } from 'axios';

export const deepEqual = (x: any, y: any): boolean =>
  x && y && typeof x === 'object' && typeof y === 'object'
    ? Object.keys(x).length === Object.keys(y).length &&
      Object.keys(x).reduce(function (isEqual, key) {
        return isEqual && deepEqual(x[key], y[key]);
      }, true)
    : x === y;

export const stringCoords2OlCoords = (ccordsString: string): Coordinate => ccordsString.split(',').map(coord => +coord);
//
// export const getIntersectedRects = (el1:HTMLElement, el2: HTMLElement) {
//   const bounds1 = el1.getBoundingClientRect();
//   const bounds2 = el2.getBoundingClientRect();
//   console.log(bounds1, bounds1)
// }

export const getElementsIntersected = (el1: HTMLElement, el2: HTMLElement, isCircles = true) => {
  const bounds1 = el1.getBoundingClientRect();
  const bounds2 = el2.getBoundingClientRect();

  const center1 = {
    left: bounds1.left + bounds1.width,
    top: bounds1.top + bounds1.height
  };
  const center2 = {
    left: bounds2.left + bounds2.width,
    top: bounds2.top + bounds2.height
  };

  const radius1 = isCircles
    ? bounds1.width / 2
    : Math.sqrt(Math.pow(bounds1.width / 2, 2) + Math.pow(bounds1.height / 2, 2));
  const radius2 = isCircles
    ? bounds2.width / 2
    : Math.sqrt(Math.pow(bounds2.width / 2, 2) + Math.pow(bounds2.height / 2, 2));
  const distance = Math.sqrt(Math.pow(center1.left - center2.left, 2) + Math.pow(center1.top - center2.top, 2));

  return distance < radius1 + radius2;
};

export const isInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();

  return rect.top <= window.innerHeight && rect.right <= window.innerWidth;
};

export const daysToMs = (days: number) => 1000 * 60 * 60 * 24 * days;

export const getCustomDateFormat = (date: Date) => moment(date).format('YYYY-MM-DD');

export const getNextDayCustomDateFormat = (date: Date) => moment(date.valueOf() + daysToMs(1)).format('YYYY-MM-DD');

export const getCustomDateLabel = (date: string) => moment(date).format('DD / MM');

export const convertToDoubleDigits = (val: number) => (val > 9 ? `${val}` : `0${val}`);

export const getResponseIsRejected = (response: AxiosResponse | AxiosError) =>
  (response.status && response.status > 299) || response instanceof Error;

export const getTime = (date: string) => {
  const d = new Date(date);

  return `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
};
