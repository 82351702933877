import React from 'react';
import Card from 'components/Card';
import Carousel from 'components/Carousel';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import VideoPlayer from 'components/VideoPlayer';
import { extendUrlByQueries, getUrl } from 'utils/api';
import { VmsCamera } from 'types/Vms';
import { CiCirclePlus } from 'react-icons/ci';
import Tooltip from 'components/Tooltip';
import TextResourceContext from 'contexts/TextResource';
import classNames from 'classnames';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { MdCopyAll } from 'react-icons/md';
import { Coordinate } from 'ol/coordinate';
import { toastSuccess } from 'components/Toaster';
import { visualizeCoordinate } from 'utils/common';
import { IoCloseOutline } from 'react-icons/io5';
import './Vms.scss';
import { COLORS } from '../../consts';

interface VmsCameraPreviewProps {
  cameras: VmsCamera[];
}

const VIDEO_RES = {
  width: 352,
  height: 198
};

interface VmsCameraPreviewProps {
  cameras: VmsCamera[];
  onAddCameraToVideoWall: (camera: VmsCamera) => void;
  idsForVideoWall?: number[];
  closePreview: () => void;
}

const openGoogleMaps = (coords: Coordinate) => {
  window.open(`https://maps.google.com/?q=${coords[0]},${coords[1]}`, '_blank');
};

const VmsCameraPreview: React.FC<VmsCameraPreviewProps> = ({
  cameras,
  onAddCameraToVideoWall,
  idsForVideoWall = [],
  closePreview
}) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  const onClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    closePreview();
  };

  return (
    <Card className='vms-map-tooltip-card p-1' style={{ width: '200px' }}>
      <Carousel arrowsClassname='vms-preview-arrows'>
        {cameras.map(camera => {
          const cameraIsAlreadyAdded = idsForVideoWall?.includes(camera.id);
          const coords = [camera.latitude, camera.longitude];

          return (
            <div key={camera.id}>
              <div className='gap-1 d-inline-flex flex-justify-end mb-3'>
                {/*<Tooltip overlay={getTextResourceByKey('cameraEvents')} mouseEnterDelay={0.5}>*/}
                {/*  <div*/}
                {/*    className='cursor-pointer vms-tooltip-hint'*/}
                {/*    onClick={() => console.log('navigate to camera view')}*/}
                {/*  >*/}
                {/*    <BsCameraVideo size={22} />*/}
                {/*  </div>*/}
                {/*</Tooltip>*/}
                <Tooltip
                  overlay={getTextResourceByKey(
                    cameraIsAlreadyAdded ? 'cameraIsAlreadyAddVideoWall' : 'cameraAddVideoWall'
                  )}
                  mouseEnterDelay={0.5}
                >
                  <div
                    className={classNames('cursor-pointer vms-tooltip-hint', cameraIsAlreadyAdded && 'inactive')}
                    onClick={() => !cameraIsAlreadyAdded && onAddCameraToVideoWall(camera)}
                  >
                    <CiCirclePlus size={22} />
                  </div>
                </Tooltip>
                <IoCloseOutline color={COLORS.light} size={18} onMouseDown={onClose} className='cursor-pointer' />
                {/*<Tooltip overlay={getTextResourceByKey('cameraEvents')} mouseEnterDelay={0.5}>*/}
                {/*  <div*/}
                {/*    className='cursor-pointer vms-tooltip-hint'*/}
                {/*    onClick={() => console.log('navigate to camera view')}*/}
                {/*  >*/}
                {/*    <TbBellRinging size={20} />*/}
                {/*  </div>*/}
                {/*</Tooltip>*/}
              </div>
              <Text size={TextSize.Sm} color={TextColor.Light} className='mb-1'>
                {camera.name}
              </Text>
              <Text size={TextSize.XSm} color={TextColor.HalfLight} className='mb-1'>
                {camera.folderName}
              </Text>
              <div className='d-flex flex-align-center mb-3'>
                <Text size={TextSize.Sm} color={TextColor.Silver}>
                  {coords[0] && visualizeCoordinate(coords[0])}
                  {', '}
                  {coords[1] && visualizeCoordinate(coords[1])}
                </Text>
                <MdCopyAll
                  className='cursor-pointer mr-1'
                  onClick={() => {
                    navigator.clipboard.writeText(coords.toString());
                    toastSuccess('coordinates copied');
                  }}
                  color={TextColor.Silver}
                  size={14}
                />
                {coords[0] !== null && coords[1] !== null && (
                  <HiOutlineExternalLink
                    className='cursor-pointer'
                    onClick={() => openGoogleMaps(coords as Coordinate)}
                    color={TextColor.Silver}
                    size={14}
                  />
                )}
              </div>
              <VideoPlayer
                src={getUrl(extendUrlByQueries(camera.src, { resolution: `${VIDEO_RES.width}x${VIDEO_RES.height}` }))}
                spinnerSize='sm'
              />
            </div>
          );
        })}
      </Carousel>
    </Card>
  );
};

export default VmsCameraPreview;
