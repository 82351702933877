import React from 'react';
import CardsLayout from 'components/CardsLayout';
import FrequencyCard from './FrequencyCard';
import { Frequency, StatusStationModel } from 'types';
import EmptyState from 'components/EmptyState';
import useGetBlockFrequency from './useGetBLockFrequency';
import Spinner from 'components/Spinner';
import Pagination from 'components/Pagination';

interface FrequenciesProps {
  frequencies: Frequency[];
  station: StatusStationModel;
  blockFreqEnabled?: boolean;
  loading?: boolean;
}

const ITEMS_ON_PAGE = 100;

const Frequencies: React.FC<FrequenciesProps> = ({ frequencies, station, blockFreqEnabled = false, loading }) => {
  const onBlockFrequencies = useGetBlockFrequency(station, blockFreqEnabled);
  const [currentPage, setCurrentPage] = React.useState(1);

  const totalPages = Math.ceil(frequencies?.length / ITEMS_ON_PAGE);
  const paginatedFrequencies = frequencies?.slice(0, currentPage * ITEMS_ON_PAGE);

  const onNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (loading) {
    return <Spinner isFullSize />;
  }

  if (frequencies.length === 0) {
    return <EmptyState />;
  }

  return (
    <Pagination callback={onNextPage}>
      <CardsLayout className='flex-1' cardMinWidth='300px'>
        {paginatedFrequencies.map(frequency => (
          <FrequencyCard
            key={frequency.frequency}
            frequency={frequency}
            onBlockFrequencies={blockFreqEnabled ? onBlockFrequencies : undefined}
          />
        ))}
      </CardsLayout>
    </Pagination>
  );
};

export default Frequencies;
