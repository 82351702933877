import React from 'react';
import Text, { TextColor, TextSize } from 'components/Text';
import { VmsCamera, VmsCameraFolder } from 'types/Vms';
import VideoPlayer from 'components/VideoPlayer';
import { getUrl } from 'utils/api';
import TextResourceContext from 'contexts/TextResource';
import { visualizeCoordinate } from 'utils/common';
import { getIsCameraOffline } from 'utils/vms';
import { CiVideoOff } from 'react-icons/ci';
import { COLORS } from 'consts';
import VmsCamerasFolder from './VmsCamerasFolder';

interface VmsCamerasProps {
  camerasFolder: VmsCameraFolder;
}

const getFirstCamera = (folder: VmsCameraFolder): VmsCamera | null => {
  if (folder.cameras.length > 0) {
    return folder.cameras[0];
  } else {
    return getFirstCamera(folder.folders[0]) || null;
  }
};

const VmsCameras: React.FC<VmsCamerasProps> = ({ camerasFolder }) => {
  const [currentCamera, setCurrentCamera] = React.useState<VmsCamera | null>(getFirstCamera(camerasFolder));
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  return (
    <div className='flex' style={{ height: 'calc(100vh - 5rem - 60px)' }}>
      <div className='flex flex-column w-40 mr-3 overflow-auto' style={{ maxWidth: '400px' }}>
        <VmsCamerasFolder
          folder={camerasFolder}
          onSelectCamera={setCurrentCamera}
          selectedCamera={currentCamera}
          isDefaultExpanded
        />
      </div>
      <div className='flex flex-column w-100 flex-justify-center position-sticky top-0 camera-border-left'>
        {currentCamera && (
          <div className='ml-4 h-100 position-relative d-flex justify-content-center flex-column'>
            {getIsCameraOffline(currentCamera) ? (
              <div
                className='w-100 d-flex flex-column align-items-center justify-content-center mb-3'
                style={{ height: `${(9 / 16) * 100}%`, marginTop: '-20%' }}
              >
                <CiVideoOff color={COLORS.danger} size={140} className='mb-2' />
                <Text color={TextColor.Danger} size={TextSize.Md} className='text-uppercase'>
                  {getTextResourceByKey('cameraIsOffline')}
                </Text>
              </div>
            ) : (
              <VideoPlayer className='mb-4 ' src={getUrl(currentCamera.src)} />
            )}
            <div className='text-center'>
              <Text size={TextSize.MdLg} color={TextColor.White} className='mb-2'>
                {currentCamera.name}
              </Text>
              {currentCamera.latitude && currentCamera.longitude && (
                <Text size={TextSize.Md} color={TextColor.Warning} className='mb-1'>
                  {visualizeCoordinate(currentCamera.latitude)}, {visualizeCoordinate(currentCamera.longitude)}
                </Text>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VmsCameras;
