import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'components/Card';
import { isBaklansStation, isHrushchStation, PageRoutes, StationModel } from 'types';
import TextResourceContext from 'contexts/TextResource';
import { ButtonSize, ButtonType } from 'components/Button/Button';
import Text, { TextColor, TextSize } from 'components/Text';
import { useDispatch } from 'react-redux';
import { removeStationAction } from 'store/stationsReducer';
import { AppDispatch } from 'store/types';
import { ModalContext } from 'contexts/ModalContext';
import ConfirmModalBody from 'components/ConfirmModalBody';
import { CiSettings, CiTrash } from 'react-icons/ci';
import { HiOutlineEye } from 'react-icons/hi';
import StationLabel from 'components/StationLabel';
import ButtonIcon from 'components/ButtonIcon';
import { COLORS } from 'consts';
import TruncatedText from 'components/Text/TruncatedText';
import { BsLink45Deg } from 'react-icons/bs';
import { CiLocationOn } from 'react-icons/ci';
import EditStationModal from '../AddStationModal/EditStationModal';
import { visualizeCoordinate } from 'utils/common';

import './StationsStationCard.scss';

interface StationCardProps {
  station: StationModel;
}

const StationsStationCard: React.FC<StationCardProps> = ({ station }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const navigate = useNavigate();
  const dispatch = useDispatch() as AppDispatch;
  const { openModal, closeModal } = React.useContext(ModalContext);

  const onShowStation = React.useCallback(() => {
    navigate(`${PageRoutes.Stations}/${station.id}`);
  }, [station]);

  const onEditClick = React.useCallback(() => {
    if (isBaklansStation(station) || isHrushchStation(station)) {
      openModal(<EditStationModal station={station} closeModal={closeModal} />);
    } else {
      navigate(`${PageRoutes.Settings}/${station.id}`);
    }
  }, [station]);

  const onDeleteClick = React.useCallback(() => {
    const onSubmit = () => {
      dispatch(removeStationAction(station.id)).then(closeModal);
    };

    openModal(
      <ConfirmModalBody
        onClose={closeModal}
        onSubmit={onSubmit}
        title={getTextResourceByKey('deleteStation') as string}
        type='delete'
      >
        {getTextResourceByKey('deleteStationBody', {
          name: <span className='text-bold text-underline color-white'>{station.name}</span>
        })}
      </ConfirmModalBody>,
      { modalType: 'confirm' }
    );
  }, [station.id, station.type]);

  return (
    <Card bodyClassName='p-2'>
      <div className='mb-3'>
        <div className='d-flex align-items-center mb-2'>
          <StationLabel stationType={station.type} className='mr-2' />
          <TruncatedText color={TextColor.White} size={TextSize.Md}>
            {station.name}
          </TruncatedText>
        </div>
        <Text className='mb-1 flex-align-center' color={TextColor.Gray} size={TextSize.Sm}>
          <BsLink45Deg className='mr-2' size={16} color={COLORS.white} />
          {station.ip}
        </Text>
        <Text className='flex-align-center' color={TextColor.Gray} size={TextSize.Sm}>
          <CiLocationOn className='mr-2' size={16} color={COLORS.white} />
          {visualizeCoordinate(station.latitude)}, {visualizeCoordinate(station.longitude)}
        </Text>
      </div>
      <div className='flex flex-justify-end'>
        {station.stationData && (
          <ButtonIcon
            size={ButtonSize.SmallMedium}
            className='mr-1 p-1'
            buttonType={ButtonType.Dark}
            onClick={onShowStation}
          >
            <HiOutlineEye size={24} color={COLORS.gray} />
          </ButtonIcon>
        )}
        <ButtonIcon
          size={ButtonSize.SmallMedium}
          className='mr-1 p-1'
          buttonType={ButtonType.Dark}
          onClick={onEditClick}
        >
          <CiSettings size={24} color={COLORS.warning} />
        </ButtonIcon>
        <ButtonIcon size={ButtonSize.SmallMedium} className='p-1' buttonType={ButtonType.Dark} onClick={onDeleteClick}>
          <CiTrash size={24} color={COLORS.danger} />
        </ButtonIcon>
      </div>
    </Card>
  );
};

export default React.memo(StationsStationCard);
