import React from 'react';
import { VmsCamera, VmsCameraFolder } from 'types/Vms';
import VmsCameraListItem from './VmsCameraListItem';
import Text, { TextColor, TextSize } from 'components/Text';
import { COLORS } from 'consts';
import { FaCaretDown } from 'react-icons/fa';

interface VmsCamerasFolderProps {
  folder: VmsCameraFolder;
  onSelectCamera: (camera: VmsCamera) => void;
  isDefaultExpanded?: boolean;
  selectedCamera: VmsCamera | null;
}

const VmsCamerasFolder: React.FC<VmsCamerasFolderProps> = ({
  folder,
  onSelectCamera,
  isDefaultExpanded = false,
  selectedCamera
}) => {
  const [isExpanded, setIsExpanded] = React.useState(isDefaultExpanded);
  const ref = React.useRef<HTMLDivElement>(null);

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      {folder.name && (
        <Text
          style={{ borderColor: COLORS.gray }}
          className='p-1 cursor-pointer border-bottom d-flex align-items-start'
          color={TextColor.Silver}
          onClick={toggleExpand}
          size={TextSize.Sm}
        >
          <FaCaretDown className='mr-1' style={{ transform: `rotate(${isExpanded ? '0' : '-90'}deg)` }} />
          <span>{folder.name}</span>
        </Text>
      )}
      <span ref={ref} style={{ height: 0 }} />
      <div
        className='pl-2 position-relative w-100 pt-2 overflow-hidden'
        style={{ maxHeight: isExpanded ? '100%' : '0', height: '100%' }}
      >
        {folder.folders.map(subFolder => (
          <VmsCamerasFolder
            folder={subFolder}
            key={subFolder.name}
            onSelectCamera={onSelectCamera}
            selectedCamera={selectedCamera}
          />
        ))}
        {folder.cameras.map(camera => (
          <VmsCameraListItem
            camera={camera}
            onClick={() => onSelectCamera(camera)}
            isSelected={Boolean(selectedCamera && selectedCamera.id === camera.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default VmsCamerasFolder;
