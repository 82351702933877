import React from 'react';
import config from 'config.json';
import OiMapService from 'services/OiMapService/OiMapService';

const { mapSettings } = config;

export const MapServiceContext = React.createContext<OiMapService | null>(null);

interface MapServiceContextProviderProps {
  children: React.ReactNode;
}

const MapServiceContextProvider: React.FC<MapServiceContextProviderProps> = ({ children }) => {
  const [mapService, setMapService] = React.useState<OiMapService | null>(null);

  React.useEffect(() => {
    const { latitude, longitude, zoom } = mapSettings;

    setMapService(new OiMapService([latitude, longitude], zoom));
  }, []);

  return <MapServiceContext.Provider value={mapService}>{children}</MapServiceContext.Provider>;
};

export default MapServiceContextProvider;
