import React from 'react';
import { useSelector } from 'react-redux';
import { getVmsCameras } from 'store/vmsReducer';
import Dropdown from 'components/Dropdown';
import DropdownItem from 'components/Dropdown/DropdownItem';
import { SlOptionsVertical } from 'react-icons/sl';
import { COLORS } from 'consts';
import { VmsCamera } from 'types/Vms';
import './Vms.scss';

interface VmsCamerasDropdownProps {
  onSelectCamera: (camera: VmsCamera) => void;
}

const VmsCamerasDropdown: React.FC<VmsCamerasDropdownProps> = ({ onSelectCamera }) => {
  const cameras = useSelector(getVmsCameras);

  const items = React.useMemo(
    () =>
      cameras.map(camera => (
        <DropdownItem
          onClick={() => {
            onSelectCamera(camera);
          }}
        >
          {camera.name}
        </DropdownItem>
      )),
    [cameras]
  );

  return (
    <Dropdown items={items} overlayClassName='vms-cameras-dropdown-overlay'>
      <div className='rounded-circle p-1 d-flex align-items-center background-dark-transparent'>
        <SlOptionsVertical color={COLORS.white} />
      </div>
    </Dropdown>
  );
};

export default VmsCamerasDropdown;
