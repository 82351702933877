import React, { PropsWithChildren } from 'react';
import EventBus from 'services/event-bus';
import { eventBus as storeEventBus } from 'store';

const EventBusContext = React.createContext<EventBus | null>(null);

export const EventBusProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [eventBus, setEventBus] = React.useState<EventBus | null>(null);

  React.useEffect(() => {
    setEventBus(storeEventBus);
  }, []);

  return <EventBusContext.Provider value={eventBus}>{children}</EventBusContext.Provider>;
};

export default function useEventBus() {
  return React.useContext(EventBusContext);
}
