import React, { ReactElement } from 'react';
import { ElescopeStationModel, PageRoutes } from 'types';
import Button, { ButtonSize, ButtonType } from 'components/Button';
import { updateStationAction } from 'store/stationsReducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/types';
import { useNavigate } from 'react-router-dom';
import TextResourceContext from 'contexts/TextResource';
import { isObject, pick } from 'lodash';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import Input from 'components/Input/Input';
import { coordinateValidationError, urlValidationError } from 'components/Input/validations';
import { getDefaultCoordsView } from 'utils/common';

interface ElescopeSettingsComponentProps {
  station: ElescopeStationModel;
  setExtraNavbarComponent: (comp: ReactElement) => void;
}

const FIELDS_TO_BE_CHANGED = ['name', 'latitude', 'longitude', 'url', 'enabled'];

const ElescopeSettingsComponent: React.FC<ElescopeSettingsComponentProps> = ({ station, setExtraNavbarComponent }) => {
  const initialSettings = pick(station, Object.values(FIELDS_TO_BE_CHANGED));

  const [isSaving, setIsSaving] = React.useState(false);
  const [newStationSettings, setNewStationSettings] = React.useState<Partial<ElescopeStationModel>>(
    getDefaultCoordsView(initialSettings)
  );
  const navigate = useNavigate();
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  const dispatch = useDispatch() as AppDispatch;
  const onuUpdateSettings = React.useCallback(() => {
    setIsSaving(true);
    dispatch(updateStationAction({ stationId: station.id, body: newStationSettings }))
      .then(() => {
        navigate(PageRoutes.Stations);
      })
      .finally(() => setIsSaving(false));
  }, [newStationSettings, station]);

  const onChangeSettings = React.useCallback(
    (key: string) => (value: string | number | boolean | { value: string | number }) => {
      const valueFormatted = isObject(value) ? value.value : value;

      setNewStationSettings(set => ({ ...set, [key]: valueFormatted }));
    },
    []
  );

  React.useEffect(() => {
    setExtraNavbarComponent(
      <Button onClick={onuUpdateSettings} className='uppercase mr-2' buttonType={ButtonType.Success} loading={isSaving}>
        {getTextResourceByKey('save')}
      </Button>
    );
  }, [onuUpdateSettings, isSaving]);

  const setButtonDisabled = React.useCallback(() => {
    onChangeSettings('enabled')(false);
  }, []);

  const setButtonEnabled = React.useCallback(() => {
    onChangeSettings('enabled')(true);
  }, []);

  return (
    <div className='pt-5'>
      <div className='w-100 mx-auto' style={{ maxWidth: '1000px' }}>
        <div className='flex mb-5 flex-justify-end'>
          <Button
            buttonType={newStationSettings.enabled ? ButtonType.Success : ButtonType.Transparent}
            size={ButtonSize.SmallMedium}
            className='mr-2'
            onClick={setButtonEnabled}
          >
            <Text color={TextColor.White}>ENABLE</Text>
          </Button>

          <Button
            className='color-white'
            buttonType={newStationSettings.enabled ? ButtonType.Transparent : ButtonType.Danger}
            size={ButtonSize.SmallMedium}
            onClick={setButtonDisabled}
          >
            <Text color={TextColor.White}>DISABLE</Text>
          </Button>
        </div>

        <div className='mb-3'>
          <Text size={TextSize.Sm} color={TextColor.Silver} className='ml-2 capitalize'>
            {getTextResourceByKey('name')}
          </Text>
          <Input
            value={newStationSettings.name as string}
            onSetValue={onChangeSettings('name')}
            placeholder={getTextResourceByKey('stationName') as string}
          />
        </div>

        <div className='mb-3'>
          <Text size={TextSize.Sm} color={TextColor.Silver} className='ml-2 capitalize'>
            {getTextResourceByKey('ip')}
          </Text>
          <Input
            value={newStationSettings.ip as string}
            onSetValue={onChangeSettings('ip')}
            placeholder={getTextResourceByKey('ip') as string}
            validations={[urlValidationError]}
          />
        </div>

        <div className='grid-table-1-1 mb-3'>
          <Text size={TextSize.Sm} color={TextColor.Silver} className='ml-2 capitalize'>
            {getTextResourceByKey('latitude')}
          </Text>
          <Text size={TextSize.Sm} color={TextColor.Silver} className='ml-2 capitalize'>
            {getTextResourceByKey('longitude')}
          </Text>
          <Input
            value={newStationSettings.latitude}
            onSetValue={onChangeSettings('latitude')}
            placeholder={getTextResourceByKey('latitude') as string}
            validations={[coordinateValidationError]}
            wrapperClassName='mr-3'
          />
          <Input
            value={newStationSettings.longitude}
            onSetValue={onChangeSettings('longitude')}
            placeholder={getTextResourceByKey('longitude') as string}
            validations={[coordinateValidationError]}
          />
        </div>
      </div>
    </div>
  );
};

export default ElescopeSettingsComponent;
