import React from 'react';
import { StationModel } from 'types';

import StatisticGraphicAmplitude from './StatisticGraphicAmplitude';
import StatisticGraphicCount from './StatisticGraphicCount';

interface StatisticGraphicsProps {
  station: StationModel;
  onlyToday?: boolean;
}

const StatisticGraphics: React.FC<StatisticGraphicsProps> = ({ station, onlyToday }) => (
  <>
    <StatisticGraphicAmplitude station={station} className='mb-4' onlyToday={onlyToday} />
    <StatisticGraphicCount station={station} className='mb-4' onlyToday={onlyToday} />
  </>
);

export default StatisticGraphics;
