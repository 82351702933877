import { useDispatch, useSelector } from 'react-redux';

import {
  getStationMapEventsAction,
  getStationMapEventsSelector,
  resetStationMapEvents
} from 'store/stationMapEventsReducer';
import React from 'react';
import { AppDispatch, RootState } from 'store/types';
import { Event, StationModel } from 'types';
import { ONE_DAY } from 'utils/date';
import dayjs from 'dayjs';
import { getFilteredEventsByStatusType } from 'pages/shared/utils';
import { xor } from 'lodash';

const PAGE_SIZE = 100;

export default function useGetStationEvents(station: StationModel, initialFilters: string[]) {
  const {
    loading,
    data: { totalPages, currentPage, data: stationEvents }
  } = useSelector((state: RootState) => getStationMapEventsSelector(state, station.id));

  const dispatch = useDispatch() as AppDispatch;
  const [dates, setDates] = React.useState([new Date(Date.now() - ONE_DAY * 3), new Date()]);

  const [statusFilters, setStatusFilters] = React.useState<string[]>(initialFilters);

  const onSetStatusFilter = (filterValue: string) => {
    setStatusFilters(xor(statusFilters, [filterValue]));
  };

  const onSetAllStatusesFilters = () => {
    statusFilters.length === initialFilters.length ? setStatusFilters([]) : setStatusFilters(initialFilters);
  };

  const filteredEventsByStatusType = React.useMemo(
    () => getFilteredEventsByStatusType(stationEvents, statusFilters),
    [stationEvents, statusFilters]
  );

  React.useEffect(() => {
    const startDate = dayjs(dates[0]).format('YYYY-MM-DD');
    const endDate = dayjs(dates[1]).format('YYYY-MM-DD');

    dispatch(resetStationMapEvents());
    if (station.id) {
      dispatch(
        getStationMapEventsAction({
          startDate,
          endDate,
          page: 0,
          size: PAGE_SIZE,
          stationId: station.id
        })
      );
    }
  }, [dates, station.id]);

  const eventsMap = React.useMemo(() => {
    const entries = Object.entries(
      filteredEventsByStatusType.reduce<Record<string, Event[]>>((acc, event) => {
        const key = event.date;

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(event);

        return acc;
      }, {})
    ).sort((a, b) => (a[0] < b[0] ? 1 : -1));

    return Object.fromEntries(entries);
  }, [filteredEventsByStatusType, station.id]);

  const fetchNextPage = React.useCallback(() => {
    const startDate = dayjs(dates[0]).format('YYYY-MM-DD');
    const endDate = dayjs(dates[11]).format('YYYY-MM-DD');

    if (currentPage < totalPages - 1) {
      dispatch(
        getStationMapEventsAction({
          startDate,
          endDate,
          page: currentPage + 1,
          size: PAGE_SIZE,
          stationId: station.id
        })
      );
    }
  }, [currentPage, totalPages, dates, station.id]);

  return {
    loading: loading && stationEvents.length === 0,
    onSubmitCalendar: setDates,
    eventsMap,
    fetchNextPage,
    onSetStatusFilter,
    onSetAllStatusesFilters,
    statusFilters
  };
}
