import { CameraStatus, VmsApiEvent, VmsCamera, VmsCameraFolder } from '../types/Vms';
import dayjs from 'dayjs';

export const getIsCameraOnline = (camera: VmsCamera) => camera.status === CameraStatus.Online;
export const getIsCameraOffline = (camera: VmsCamera) => camera.status === CameraStatus.Offline;

export const vmsApiEvent2VmsEvent = (event: VmsApiEvent) => ({
  ...event,
  date: dayjs(new Date(event.createdAt)).format('YYYY:MM:DD')
});

export const createEmptyCamerasFolder = (name?: string): VmsCameraFolder => ({
  folders: [],
  cameras: [],
  name
});

const updateRecursivelyFolderWithCameras = (
  folder: VmsCameraFolder,
  camera: VmsCamera,
  sequenceFolderNames: string[]
): VmsCameraFolder => {
  const currentFolderName = sequenceFolderNames[0];
  let innerFolder = folder.folders.find(f => f.name === currentFolderName);

  if (!innerFolder) {
    innerFolder = createEmptyCamerasFolder(currentFolderName);
    folder.folders.push(innerFolder);
  }

  if (sequenceFolderNames.length === 1) {
    innerFolder.cameras.push(camera);

    return folder;
  } else {
    updateRecursivelyFolderWithCameras(innerFolder, camera, sequenceFolderNames.slice(1));
  }

  return folder;
};

export const transformCamerasListInfoFolder = (cameras: VmsCamera[]) =>
  cameras.reduce<VmsCameraFolder>((acc, camera) => {
    const folderName = camera.folderName;

    if (!folderName) {
      acc.cameras.push(camera);
    } else {
      acc = updateRecursivelyFolderWithCameras(acc, camera, folderName.split('\n'));
    }

    return acc;
  }, createEmptyCamerasFolder());
