import React from 'react';
import Input from 'components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVmsSettings, getVmsSettings, updateVmsSettings } from 'store/vmsReducer';
import Spinner from 'components/Spinner';
import { AppDispatch } from 'store/types';
import Text from 'components/Text';
import TextResourceContext from 'contexts/TextResource';
import { ipValidation, portValidation } from 'components/Input/validations';
import Button from 'components/Button';
import { VmsSettings as VmsSettingsType } from 'types/Vms';
import VmsConnectionStatus from '../../shared/VmsConnectionStatus';

const VmsSettings = () => {
  const { loading, data } = useSelector(getVmsSettings);
  const dispatch = useDispatch() as AppDispatch;
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  const [settingsState, setSettingsState] = React.useState<VmsSettingsType | null>(null);

  const onChange = (key: string) => (value: string) => {
    if (settingsState) {
      setSettingsState((s: VmsSettingsType) => ({ ...s, [key]: value }));
    }
  };

  React.useEffect(() => {
    if (data) {
      setSettingsState(data);
    }
  }, [data]);

  React.useEffect(() => {
    dispatch(fetchVmsSettings());
  }, []);

  if (loading && !settingsState) {
    return <Spinner isFullSize />;
  }

  if (!settingsState) {
    return null;
  }

  const onSubmit = () => {
    if (settingsState) {
      dispatch(updateVmsSettings(settingsState));
    }
  };

  return (
    <div className='mx-auto mt-5' style={{ maxWidth: '700px' }}>
      <div className='grid-table-1-3 mb-5'>
        <Text className='align-self-end text-uppercase'>{getTextResourceByKey('status')}</Text>
        <VmsConnectionStatus />

        <Text className='align-self-end text-uppercase'>{getTextResourceByKey('ip')}</Text>
        <Input value={settingsState.ip} onSetValue={onChange('ip')} validations={[ipValidation]} />

        <Text className='align-self-end text-uppercase'>{getTextResourceByKey('port')}</Text>
        <Input value={settingsState.port} onSetValue={onChange('port')} validations={[portValidation]} />

        <Text className='align-self-end text-uppercase'>{getTextResourceByKey('login')}</Text>
        <Input value={settingsState.login} onSetValue={onChange('login')} />

        <Text className='align-self-end text-uppercase'>{getTextResourceByKey('password')}</Text>
        <Input type='password' value={settingsState.password} onSetValue={onChange('password')} />
      </div>

      <div className='d-flex justify-content-end'>
        <Button onClick={onSubmit}>{getTextResourceByKey('Submit')}</Button>
      </div>
    </div>
  );
};

export default VmsSettings;
