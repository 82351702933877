import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Frequency,
  StatisticsFrequencies,
  StatisticsFrequenciesResponse,
  StatisticsFrequenciesState,
  ThunkOptions
} from '../types';
import { AppDispatch, RootState, ThunkExtraArgumentType } from './types';
import { createEmptyPaginationState } from './utils';
import { removeStationSettingSuccessAction, resetStateAction } from './shared';
import { get, omit } from 'lodash';

const initialState: StatisticsFrequenciesState = {};

const SIGNAL_KEY_STATISTICS_FREQUENCIES = 'getStatisticsFrequenciesAction';

export const getStatisticsFrequenciesAction = createAsyncThunk<
  StatisticsFrequenciesResponse,
  {
    stationId: number;
    page: number;
    startDate: string;
    endDate: string;
    sortType: string;
    size: number | undefined;
  }
>(
  'STATIONS@GET_STATISTICS_FREQUENCIES',
  ({ stationId, page, startDate, endDate, sortType, size = 50 }, { extra: { api } }: ThunkOptions) =>
    api.get(
      `signals/station/${stationId}/frequencies?page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}&sortParam=${sortType}`,
      SIGNAL_KEY_STATISTICS_FREQUENCIES
    )
);

export const resetStationStatisticFrequencies = createAction<number>('resetStationStatisticFrequencies');

export const resetStationStatisticFrequenciesAndCancelPrevRequest =
  (stationId: number) =>
  (dispatch: AppDispatch, _: () => RootState, { api }: ThunkExtraArgumentType) => {
    api.abortController(SIGNAL_KEY_STATISTICS_FREQUENCIES);
    dispatch(resetStationStatisticFrequencies(stationId));
  };

const statisticsFrequenciesSlice = createSlice({
  name: 'statisticsFrequencies',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStatisticsFrequenciesAction.pending, (state, action) => {
      const stationId = action.meta.arg.stationId;

      const station = state[stationId] || createEmptyPaginationState();

      state[stationId] = {
        ...station,
        loading: true
      };
    });
    builder.addCase(getStatisticsFrequenciesAction.rejected, (state, action) => {
      const stationId = action.meta.arg.stationId;
      const error = action.error;

      state[stationId] = {
        ...state[stationId],
        error,
        loading: false
      };
    });
    builder.addCase(getStatisticsFrequenciesAction.fulfilled, (state, action) => {
      const stationId = action.meta.arg.stationId;
      const response = action.payload;
      const station: StatisticsFrequencies = state[stationId];

      //@ts-ignore
      const updatedResponse: Frequency[] = response?.data.map((d: Frequency) => {
        const minDb = get(d, 'minDB');
        const maxDb = get(d, 'maxDB');

        return omit({ ...d, minDb, maxDb }, ['minDB', 'maxDB']);
      });

      if (!station.loaded || station.data.currentPage + 1 === response.currentPage) {
        state[stationId] = {
          ...station,
          loading: false,
          loaded: true,
          error: null,
          data: {
            ...response,
            data: [...station.data.data, ...updatedResponse]
          }
        };
      }
    });
    builder.addCase(resetStationStatisticFrequencies, (state, action) => {
      const stationId = action.payload;

      state[stationId] = createEmptyPaginationState();
    });
    builder.addCase(resetStateAction.fulfilled, () => initialState);
    builder.addCase(removeStationSettingSuccessAction, (state, action) => {
      const stationId = action.payload;

      state[stationId] = createEmptyPaginationState();
    });
  }
});

const defaultStatisticsFrequenciesData = createEmptyPaginationState();

export const statisticsFrequenciesReducer = statisticsFrequenciesSlice.reducer;
export const getStatisticsFrequenciesSelector = (state: RootState, stationId?: number): StatisticsFrequencies =>
  stationId
    ? state.statisticsFrequencies[stationId] || defaultStatisticsFrequenciesData
    : defaultStatisticsFrequenciesData;
