import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardsLayout from 'components/CardsLayout';
import { fetchUsersAction, getUsersData } from 'store/usersReducer';
import { AppDispatch } from 'store/types';
import UserCard from '../UserCard';
import AddUserCardComponent from '../AddUserCardComponent';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from 'types';
import './UsersComponent.scss';

const UsersComponent = () => {
  const dispatch = useDispatch() as AppDispatch;
  const users = useSelector(getUsersData);
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(fetchUsersAction());
  }, []);

  const onAddUser = React.useCallback(() => {
    navigate(PageRoutes.AddUser);
  }, []);

  return (
    <div className='users'>
      <CardsLayout cardMinWidth='240px'>
        <AddUserCardComponent onClick={onAddUser} />
        {users?.map((user, i) => <UserCard user={user} key={`${user.login}-${i}`} />)}
      </CardsLayout>
    </div>
  );
};

export default React.memo(UsersComponent);
