import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthData, updateSettings } from 'store/authReducer';
import TextResourceContext from 'contexts/TextResource';
import EditableText from 'components/Text/EditableText';
import HeaderComponent from 'components/HeaderComponent';
import { MdManageAccounts } from 'react-icons/md';
import { COLORS, LOGO_SIZE } from 'consts';
import { TextSize } from 'components/Text';
import { get, isEmpty } from 'lodash';
import { Option } from 'components/MultiSelect/types';
import ListNotifications from './ListNotifications';
import Button, { ButtonType } from 'components/Button';
import { AppDispatch } from 'store/types';
import Text from 'components/Text/Text';

const AccountPage = () => {
  const authData = useSelector(getAuthData);
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const [newName, setNewName] = React.useState<string | null | number>();
  const [newLogin, setNewLogin] = React.useState<string | null | number>();
  const [selectedNewSoundOptions, setSelectedNewSoundOptions] = React.useState<Record<string, boolean>>({});
  const [selectedNewFirebaseOptions, setSelectedNewFirebaseOptions] = React.useState<Record<string, boolean>>({});
  const [inactiveSessionMode, setInactiveSessionMode] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch() as AppDispatch;

  if (!authData) {
    return null;
  }

  React.useEffect(() => {
    setInactiveSessionMode(authData.inactiveSessionMode);
  }, [authData.inactiveSessionMode]);

  const soundNotifications = authData.soundNotifications;

  React.useEffect(() => {
    setSelectedNewSoundOptions({});
  }, [soundNotifications]);

  const onToggleSounds = (option: Option) => {
    const key = option.value;

    const sourceObject = selectedNewSoundOptions.hasOwnProperty(option.value)
      ? selectedNewSoundOptions
      : soundNotifications;
    const value = get(sourceObject, key);

    setSelectedNewSoundOptions({ ...selectedNewSoundOptions, [key]: !value });
  };

  const firebaseNotifications = authData.firebaseNotifications;

  React.useEffect(() => {
    setSelectedNewFirebaseOptions({});
  }, [firebaseNotifications]);

  const onToggleFirebase = (option: Option) => {
    const key = option.value;

    const sourceObject = selectedNewFirebaseOptions.hasOwnProperty(option.value)
      ? selectedNewFirebaseOptions
      : firebaseNotifications;
    const value = get(sourceObject, key);

    setSelectedNewFirebaseOptions({ ...selectedNewFirebaseOptions, [key]: !value });
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const soundsAreEmpty = isEmpty(selectedNewSoundOptions);
    const firebaseAreEmpty = isEmpty(selectedNewFirebaseOptions);
    const inActiveSessionModeChanged = inactiveSessionMode !== authData.inactiveSessionMode;
    const newSettings = {
      ...(newName ? { name: newName as string } : {}),
      ...(newLogin ? { login: newLogin as string } : {}),
      ...(inActiveSessionModeChanged ? { inactiveSessionMode } : {}),
      ...(soundsAreEmpty ? {} : { soundNotifications: selectedNewSoundOptions }),
      ...(firebaseAreEmpty ? {} : { firebaseNotifications: selectedNewFirebaseOptions })
    };

    await dispatch(updateSettings(newSettings));
    setIsLoading(false);
  };

  return (
    <div>
      <HeaderComponent logo={<MdManageAccounts size={LOGO_SIZE} color={COLORS.white} />} />
      <div className='pt-5'>
        <div className='mb-5'>
          <EditableText
            iconSize={16}
            size={TextSize.Md}
            initialText={authData.name}
            onConfirm={setNewName}
            wrapperClassname='mb-3'
          />
          <EditableText iconSize={16} size={TextSize.Md} initialText={authData.login} onConfirm={setNewLogin} />
          <div className='flex gap-2 cursor-pointer my-3' onClick={() => setInactiveSessionMode(!inactiveSessionMode)}>
            <input
              className='checkbox'
              type='checkbox'
              name='inactiveSessionMode'
              checked={inactiveSessionMode}
              onChange={() => setInactiveSessionMode(!inactiveSessionMode)}
            />
            <Text className='d-block cursor-pointer'>
              <label htmlFor='inactiveSessionMode' className='cursor-pointer'>
                {getTextResourceByKey('inactiveSessionMode')}
              </label>
            </Text>
          </div>
        </div>

        <ListNotifications
          className='mb-5'
          title={getTextResourceByKey('soundNotifications')}
          notifications={soundNotifications}
          onToggleCheckBox={onToggleSounds}
          newOptions={selectedNewSoundOptions}
        />
        <ListNotifications
          className='mb-4'
          title={getTextResourceByKey('androidNotifications')}
          notifications={firebaseNotifications}
          onToggleCheckBox={onToggleFirebase}
          newOptions={selectedNewFirebaseOptions}
        />
      </div>

      <div className='flex-justify-end pt-4'>
        <Button
          buttonType={ButtonType.Success}
          className='add-station-component__add-btn'
          role='button'
          onClick={onSubmit}
          loading={isLoading}
        >
          {getTextResourceByKey('submit')}
        </Button>
      </div>
    </div>
  );
};

export default AccountPage;
