import React from 'react';
import { Frequency } from 'types';
import TextResourceContext from 'contexts/TextResource';
import Text, { TextColor, TextSize } from 'components/Text';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getIsAdmin } from 'store/authReducer';
import Card from 'components/Card';

interface FrequencyCardProps {
  frequency: Frequency;
  onBlockFrequencies?: (freq: Frequency) => void;
}

const FrequencyCard: React.FC<FrequencyCardProps> = ({ frequency, onBlockFrequencies }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const isAdmin = useSelector(getIsAdmin);

  const channelWidth =
    frequency.minBand === frequency.maxBand
      ? frequency.minBand
      : `${frequency.minBand.toFixed(3)}-${frequency.maxBand.toFixed(3)}`;

  const power =
    frequency.minDb && frequency.maxDb
      ? frequency.minDb.toFixed(2) === frequency.maxDb.toFixed(2)
        ? `${frequency.minDb} dB`
        : `${frequency.minDb.toFixed(2)}-${frequency.maxDb.toFixed(2)} dB`
      : getTextResourceByKey('not provided');

  const onClickBlockFreq = React.useCallback(() => {
    onBlockFrequencies?.(frequency);
  }, [onBlockFrequencies, frequency]);

  return (
    <Card
      className='d-flex'
      rightBar={
        isAdmin ? (
          <Text
            className={classNames(
              frequency.block ? 'background-danger' : 'background-light-gray',
              'writing-mode-vertical-rl text-center p-1 cursor-pointer '
            )}
            size={TextSize.Sm}
            color={frequency.block ? TextColor.White : TextColor.Dark}
            onClick={onClickBlockFreq}
          >
            <div style={{ transform: 'rotate(180deg)' }}>
              {getTextResourceByKey(frequency.block ? 'blocked' : 'active')}
            </div>
          </Text>
        ) : undefined
      }
    >
      <div className={classNames(frequency.block && 'opacity-25')}>
        <div className={classNames('row mb-2 w-100')}>
          <div className='col-6 text-center'>
            <Text className='mb-1' size={TextSize.Sm}>
              {getTextResourceByKey('frequency')}
            </Text>
            <Text size={TextSize.SmMd} color={TextColor.White}>
              {frequency.frequency}
            </Text>
          </div>
          <div className='col-6 h-100 align-items-center d-flex'>
            <Text size={TextSize.Hg} color={TextColor.Warning} className='text-center w-100'>
              {frequency.count}
            </Text>
          </div>
        </div>
        <div className='row w-100'>
          <div className='col-6 text-center'>
            <Text color={TextColor.Silver} size={TextSize.Sm} className='mb-1'>
              {getTextResourceByKey('channelWidth')}
            </Text>
            <Text color={TextColor.Silver} size={TextSize.SmMd}>
              <span className='no-white-space'>{channelWidth}</span>
            </Text>
          </div>

          <div className='col-6 text-center'>
            <Text color={TextColor.Success} size={TextSize.Sm} className='mb-1'>
              {getTextResourceByKey('power')}
            </Text>
            <Text color={TextColor.Success} size={TextSize.SmMd}>
              <span className='no-white-space'>{power}</span>
            </Text>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default FrequencyCard;
