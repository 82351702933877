import React from 'react';
import { HTMLBarChart } from 'components/HTMLBarChart';
import { Frequency } from 'types';
import TextResourceContext from 'contexts/TextResource';
import Text, { TextColor, TextSize } from 'components/Text';
import FrequencyCard from '../shared/Frequencies/FrequencyCard';

interface FrequenciesChartsProps {
  frequencies: Frequency[];
}

const CurrentFrequenciesAmplitudeCharDB: React.FC<FrequenciesChartsProps> = ({ frequencies }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  const formattedFrequencies = React.useMemo(
    () =>
      [...frequencies]

        .sort((a, b) => +a.frequency - +b.frequency)
        .map(freq => ({
          x: freq.frequency,
          y: freq.count,
          tooltip: <FrequencyCard frequency={freq} />,
          id: freq.frequency
        })),
    [frequencies]
  );

  return (
    <>
      <Text color={TextColor.Success} size={TextSize.MdLg} className='mb-5 text-center uppercase'>
        {getTextResourceByKey('frequencyCountChart')}
      </Text>
      <HTMLBarChart
        data={formattedFrequencies}
        paginationCallback={() => null}
        xUnit={getTextResourceByKey('frequency') as string}
        yUnit={getTextResourceByKey('count') as string}
      />
    </>
  );
};

export default React.memo(CurrentFrequenciesAmplitudeCharDB);
