import React from 'react';
import { Coordinate } from 'ol/coordinate';
import Marker from 'components/MapComponent/layers/Marker';
import { VmsCamera } from 'types/Vms';

export interface CameraMarkerProps {
  camera: VmsCamera;
  strokeColor?: string;
  fillColor?: string;
}

const CameraMarker: React.FC<CameraMarkerProps> = ({ camera }) => {
  if (!camera.latitude || !camera.longitude) {
    return null;
  }
  const coords: Coordinate = React.useMemo(() => [camera.latitude as number, camera.longitude as number], [camera]);

  return (
    <Marker id={camera.id} coords={coords} src={`cameras/${camera.color}-camera${camera.isPTZ ? '-ptz' : ''}.svg`} />
  );
};

export default CameraMarker;
