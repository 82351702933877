import React from 'react';

const useGetDocumentScrollTop = () => {
  const [documentScrolledTop, setDocumentScrolledTop] = React.useState(document.documentElement.scrollTop);

  React.useEffect(() => {
    const onScroll = () => {
      setDocumentScrolledTop(document.documentElement.scrollTop);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return { documentScrolledTop, isScrolled: Boolean(documentScrolledTop) };
};

export default useGetDocumentScrollTop;
