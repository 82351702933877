import React from 'react';
import MapComponent from 'components/MapComponent';
import HeaderComponent from 'components/HeaderComponent';
import { CiMap } from 'react-icons/ci';
import { COLORS, LOGO_SIZE } from 'consts';
import StationLayer from 'components/MapComponent/layers/StationLayer';
import useGetFilteredSearchedStations from 'hooks/useGetFilteredSearchedStations';
import FilterStation from 'pages/shared/FilterStation';
import { MapServiceContext } from 'contexts/MapServiceContext';
import Input from 'components/Input';
import TextResourceContext from 'contexts/TextResource';

const CENTER_OF_UKRAINE_COORDS = [48.21, 31.2];
const ZOOM_OF_UKRAINE = 6;

const MapStations = () => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const { stations, searchFilter, setSearchFilter } = useGetFilteredSearchedStations();
  const mapService = React.useContext(MapServiceContext);

  React.useEffect(() => {
    if (mapService) {
      setTimeout(() => mapService.flyTo(CENTER_OF_UKRAINE_COORDS, ZOOM_OF_UKRAINE), 0);
    }
  }, []);

  return (
    <div>
      <HeaderComponent logo={<CiMap size={LOGO_SIZE} color={COLORS.white} />} />
      <div className='filters-placeholder d-flex gap-3 align-items-center justify-content-end position-fixed layout-width'>
        <Input
          value={searchFilter}
          onSetValue={setSearchFilter}
          placeholder={getTextResourceByKey('search') as string}
          withEmptyStringValidation={false}
          className='mr-3 mb-2'
        />
        <FilterStation />
      </div>
      <div className='pt-5 w-100 relative h-100'>
        <MapComponent>{stations?.map(station => <StationLayer station={station} key={station.id} />)}</MapComponent>
      </div>
    </div>
  );
};

export default MapStations;
