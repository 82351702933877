import {
  getStationStatisticsAction,
  getStatisticStationSelector,
  resetStationStatisticAndCancelPrevRequest
} from '../store/statisticReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/types';
import { StatusStationModel } from '../types';
import React from 'react';
import { getCustomDateFormat } from '../utils';
import { getStationConfigurationAction } from '../store/stationsReducer';

const ONE_DAY = 1000 * 60 * 60 * 24;

export default function useGetStatics(station: StatusStationModel, onlyToday?: boolean) {
  const startDate = station.configuration?.expirationStatisticDuration ? new Date(Date.now() - ONE_DAY) : new Date();
  const initialDate = onlyToday ? [new Date(), new Date()] : [startDate, new Date()];
  const [periodDate, setPeriodDate] = React.useState<Date[]>(initialDate);

  React.useEffect(() => {
    if (!station.configuration?.expirationStatisticDuration) {
      dispatch(getStationConfigurationAction(station.id));
    }
  }, []);

  const getStatisticsSelectorCb = React.useMemo(
    () => (state: RootState) => getStatisticStationSelector(state, station?.id),
    [station?.id]
  );
  const dispatch = useDispatch() as AppDispatch;
  const {
    data: { currentPage, data },
    error,
    loading,
    loaded
  } = useSelector(getStatisticsSelectorCb);

  const fetchStatistic = React.useCallback(
    (range = periodDate) => {
      if (station.id && !loading) {
        const page = loaded ? currentPage + 1 : 0;
        const endDate = getCustomDateFormat(range[1]);
        const startDateL = getCustomDateFormat(range[0]);

        dispatch(getStationStatisticsAction({ stationId: station.id, page, startDate: startDateL, endDate }));
      }
    },
    [currentPage, station, periodDate, loading, loaded]
  );

  React.useEffect(() => {
    fetchStatistic();

    return () => {
      dispatch(resetStationStatisticAndCancelPrevRequest(station.id));
    };
  }, [periodDate]);

  const setPeriodDateProxy = React.useCallback((range: Date[]) => {
    dispatch(resetStationStatisticAndCancelPrevRequest(station.id));
    setTimeout(() => setPeriodDate(range));
  }, []);

  return { loading, fetchStatistic, data, error, setPeriodDate: setPeriodDateProxy, periodDate };
}
