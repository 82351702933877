import React from 'react';
import Card from 'components/Card';
import Text, { TextColor, TextSize } from 'components/Text';
import TextResourceContext from 'contexts/TextResource';
import { HiPlus } from 'react-icons/hi';
import { COLORS } from 'consts';

interface AddUserCardProps {
  onClick: () => void;
}

const AddUserCardComponent: React.FC<AddUserCardProps> = ({ onClick }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  return (
    <Card onClick={onClick} bodyClassName='d-flex flex-column justify-content-center h-100'>
      <div className='text-center'>
        <HiPlus size='40' color={COLORS.gray} />
      </div>
      <div>
        <Text size={TextSize.Md} className='text-center mb-2 uppercase' color={TextColor.White}>
          {getTextResourceByKey('newUser')}
        </Text>
        <Text className='text-center' size={TextSize.Sm}>
          {getTextResourceByKey('clickToAddNewUser')}
        </Text>
      </div>
    </Card>
  );
};

export default React.memo(AddUserCardComponent);
