import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  EventFromBE,
  Event,
  EventsResponse,
  EventsState,
  FetchingData,
  PaginationData,
  ThunkOptions,
  StationType
} from '../types';
import { RootState } from './types';
import { createEmptyPaginationState } from './utils';
import { extendUrlByQueries } from '../utils/api';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import { resetStateAction } from './shared';

const initialState: FetchingData<PaginationData<Event[]>> = createEmptyPaginationState();

const SIGNAL_KEY = 'allEvents';

export const mapStationEvent = ({ createdAt, ...ev }: EventFromBE) => ({
  ...ev,
  time: dayjs(createdAt).format('HH:mm:ss'),
  date: dayjs(createdAt).format('YYYY-MM-DD')
});

export const getAllEventsAction = createAsyncThunk<
  EventsResponse,
  { startDate: string; endDate: string; page: number; size: number; stationType?: StationType }
>('EVENTS@GET_ALL_EVENTS', (queries, { extra }: ThunkOptions) =>
  extra.api.get(extendUrlByQueries('events', queries), SIGNAL_KEY).then((response: PaginationData<EventFromBE[]>) => ({
    ...response,
    data: response.data.map(ev => mapStationEvent(ev))
  }))
);

export const resetAllEvents = createAction('EVENTS@RESET_ALL_EVENTS_STATE');

const allEventsSlice = createSlice({
  name: 'allEvents',
  initialState,
  reducers: {
    appendStationEvent: (state, action) => {
      if (state.data && state.data.data.length > 0) {
        state.data.data.unshift(action.payload);
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(getAllEventsAction.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllEventsAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(getAllEventsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.loaded = true;

      if (state.data.currentPage + 1 === action.payload.currentPage) {
        state.data.data = uniqBy([...state.data.data, ...action.payload.data], 'id');
      } else {
        state.data.data = action.payload.data;
      }

      state.data.currentPage = action.payload.currentPage;
      state.data.totalItems = action.payload.totalItems;
      state.data.totalPages = action.payload.totalPages;
    });

    builder.addCase(resetStateAction.fulfilled, () => initialState);
    builder.addCase(resetAllEvents, () => initialState);
  }
});

export const getAllEventsSelector = (state: RootState): EventsState => state.allEvents;

export const allEventsReducer = allEventsSlice.reducer;

export const { appendStationEvent } = allEventsSlice.actions;
