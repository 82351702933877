import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkOptions, FetchingData, Frequency } from '../types';
import { RootState } from './types';
import { createEmptyFetchedState } from './utils';
import { removeStationSettingSuccessAction, resetStateAction } from './shared';
import { blockFrequency, unblockFrequency } from './frequenciesReducer';

interface InitialState {
  [key: string]: FetchingData<Frequency[]>;
}
const initialState: InitialState = {};

const getTodayFrequenciesRequestIsPending = createAction<{ stationId: string }>('getTodayFrequenciesRequestIsPending');

const GET_TODAY_FREQUENCIES_SIGNAL = 'getTodayFrequencies';

export const abortFrequenciesAction = createAsyncThunk('abortTodayFrequencies', (_, { extra }: ThunkOptions) => {
  extra.api.abortController(GET_TODAY_FREQUENCIES_SIGNAL);

  return { type: 'abortTodayFrequencies' };
});

export const getTodayFrequencies = createAsyncThunk<Frequency[], { stationId: string }>(
  'CONFIGS@GET_TODAY_FREQUENCIES',
  ({ stationId }, { dispatch, extra }: ThunkOptions) => {
    const api = extra.api;

    dispatch(getTodayFrequenciesRequestIsPending({ stationId }));

    return api.get(`signals/station/${stationId}/frequencies/today`, GET_TODAY_FREQUENCIES_SIGNAL);
  }
);

const todayFrequenciesSlice = createSlice({
  name: 'todayFrequenciesStation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getTodayFrequencies.pending, (state, action) => {
      const stationId = action.meta.arg.stationId;

      state[stationId] = {
        ...state[stationId],
        loading: true
      };
    });

    builder.addCase(getTodayFrequencies.rejected, (state, action) => {
      const stationId = action.meta.arg.stationId;

      state[stationId] = {
        ...state[stationId],
        data: [],
        loading: false,
        loaded: true,
        error: action.error
      };
    });

    builder.addCase(getTodayFrequencies.fulfilled, (state, action) => {
      const stationId = action.meta.arg.stationId;

      state[stationId] = {
        loading: false,
        error: null,
        loaded: true,
        data: action.payload
      };
    });
    builder.addCase(resetStateAction.fulfilled, () => initialState);

    builder.addCase(removeStationSettingSuccessAction, (state, action) => {
      const stationId = action.payload;

      state[stationId] = createEmptyFetchedState([]);
    });

    builder.addCase(blockFrequency.fulfilled, (state, action) => {
      const { stationId, frequency } = action.meta.arg;
      const station = state[stationId];

      station
        ? station.data.forEach(freqModel => {
            if (frequency === freqModel.frequency) {
              freqModel.block = true;
            }
          })
        : null;
    });

    builder.addCase(unblockFrequency.fulfilled, (state, action) => {
      const { stationId, frequency } = action.meta.arg;
      const station = state[stationId];

      station
        ? station.data.forEach(freqModel => {
            if (frequency === freqModel.frequency) {
              freqModel.block = false;
            }
          })
        : null;
    });
  }
});

const defaultTodayFrequenciesStationData = createEmptyFetchedState([]);

export const todayFrequenciesReducer = todayFrequenciesSlice.reducer;
export const getTodayFrequenciesSelector = (state: RootState, stationId?: number): FetchingData<Frequency[]> =>
  stationId
    ? state.todayFrequencies[stationId] || defaultTodayFrequenciesStationData
    : defaultTodayFrequenciesStationData;
