import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
/***** pages *****/
import AuthComponent from './auth';
import DashboardComponent from './dashboard';
import AdminPanelComponent from './admin-panel';
import StationComponent from './station';
import VMSComponent from './vms';
import { AddUserComponent, EditUserComponent } from './user';
import AllEventsWidget from './events';

// import SettingsComponent from './settings';
/******** *******/
import { getIsAdmin, getIsAuthenticated } from '../store/authReducer';

import Spinner from '../components/Spinner';
import { getPageIsReadySelector } from '../store/uiReducer';
import { initIntervalStationsFetching } from '../store/stationsReducer';
import { AppDispatch } from '../store/types';
import { PageRoutes } from '../types';
import SettingsComponent from './settings';
import MapStations from './map';
import { useInitStartApp, useVmsEvents, useNotificationOfStationEvents, useHeartBeatsPing } from 'hooks';
import AccountPage from './account';

const RouterComponent = () => {
  const userIsAuthenticated = useSelector(getIsAuthenticated);
  const pageIsReady = useSelector(getPageIsReadySelector);
  const dispatch = useDispatch() as AppDispatch;

  useHeartBeatsPing();
  useInitStartApp();

  useVmsEvents();
  useNotificationOfStationEvents();

  const isAdmin = useSelector(getIsAdmin);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (pageIsReady) {
      if (!userIsAuthenticated && location.pathname !== PageRoutes.Auth) {
        navigate(PageRoutes.Auth);
      }

      if (userIsAuthenticated && location.pathname === PageRoutes.Auth) {
        navigate(PageRoutes.Dashboard);
      }
    }
  }, [userIsAuthenticated, location.pathname, pageIsReady]);

  React.useEffect(() => {
    if (userIsAuthenticated) {
      dispatch(initIntervalStationsFetching());
    }
  }, [userIsAuthenticated]);

  if (!pageIsReady) {
    return <Spinner isFullSize />;
  }

  return (
    <Routes>
      <Route path={PageRoutes.Auth} element={<AuthComponent />} />
      {userIsAuthenticated && (
        <>
          <Route path={PageRoutes.Dashboard} element={<DashboardComponent />} />
          {isAdmin && <Route path={PageRoutes.AdminPanel} element={<AdminPanelComponent />} />}
          <Route path={PageRoutes.Station} element={<StationComponent />} />
          <Route path={PageRoutes.StationSettings} element={<SettingsComponent />} />
          <Route path={PageRoutes.Map} element={<MapStations />} />
          <Route path={PageRoutes.VMS} element={<VMSComponent />} />
          <Route path={PageRoutes.Events} element={<AllEventsWidget />} />
          {isAdmin && <Route path={PageRoutes.AddUser} element={<AddUserComponent />} />}
          {isAdmin && <Route path={PageRoutes.EditUser} element={<EditUserComponent />} />}
          {isAdmin && <Route path={PageRoutes.Account} element={<AccountPage />} />}
          {/*<Route path={PageRoutes.Settings} element={<SettingsComponent />} />*/}
          <Route path='*' element={<Navigate to={PageRoutes.Dashboard} />} />
        </>
      )}
    </Routes>
  );
};

export default RouterComponent;
