import {
  ConnectionType,
  isElescopeStation,
  isStatusStation,
  isBaklansStation,
  StationModel,
  StationStatusType,
  ElescopeStationModel
} from 'types';
import { round } from 'lodash';
import { COLOR_MIXIN_VARIABLES, STATION_STATUS_PRIOIRITY } from '../consts';
import { TbAntennaOff, TbBellRinging, TbPlugConnectedX, TbProgressCheck, TbProgressHelp } from 'react-icons/tb';
import { FaRegClock } from 'react-icons/fa';

export const getNumberOfDrones = (station: ElescopeStationModel) => station?.stationData?.drones?.length;

export const getStationIsDisconnected = (station: StationModel) =>
  station.connectionStatus === ConnectionType.Disconnected;

export const getStationIsConnected = (station: StationModel) => station.connectionStatus === ConnectionType.Connected;

export const getStationIsAlarm = (station: StationModel) => station.status === StationStatusType.Alarm;

export const getStationIsMedium = (station: StationModel) => station.status === StationStatusType.Medium;
export const getStationIsLearning = (station: StationModel) => station.status === StationStatusType.Learning;
export const getStationStatusIsNormal = (station: StationModel) => station.status === StationStatusType.Normal;

export const getStationIsNotFound = (station: StationModel) => station.connectionStatus === ConnectionType.NotFound;
export const getStationIsIssued = (station: StationModel) => station.connectionStatus === ConnectionType.StationIssue;

export const getIsStationInfoDisabled = (station: StationModel) =>
  (getStationIsDisconnected(station) && !isBaklansStation(station)) ||
  getStationIsNotFound(station) ||
  getNumberOfDrones(station as ElescopeStationModel) === 0;

export const getStationStatus = (station: StationModel): string => {
  if (getStationIsDisconnected(station) && isBaklansStation(station)) {
    return StationStatusType.Alarm;
  }
  if (isBaklansStation(station) && getStationIsConnected(station)) {
    return station.connectionStatus;
  }

  if (!getStationIsConnected(station)) {
    return station.connectionStatus;
  }

  if (isElescopeStation(station) && station.stationData) {
    return station.stationData.status;
  }

  return station.status as StationStatusType;
};

export const getStationStatusIconComponent = (station: StationModel) => {
  if (getStationIsNotFound(station)) {
    return TbProgressHelp;
  }

  if (getStationIsIssued(station)) {
    return TbAntennaOff;
  }

  if (getStationIsDisconnected(station)) {
    return isBaklansStation(station) ? TbBellRinging : TbPlugConnectedX;
  }

  if (getStationIsMedium(station) || getStationIsAlarm(station)) {
    return TbBellRinging;
  }

  if (getStationIsLearning(station)) {
    return FaRegClock;
  }

  return TbProgressCheck;
};

export const getStationBackgroundStatusColor = (station: StationModel) => {
  const status = getStationStatus(station);

  switch (status) {
    case ConnectionType.Disconnected:
      return COLOR_MIXIN_VARIABLES.Black;
    case ConnectionType.NotFound:
      return COLOR_MIXIN_VARIABLES.Primary;
    case StationStatusType.Alarm:
      return COLOR_MIXIN_VARIABLES.Danger;
    case ConnectionType.StationIssue:
    case StationStatusType.Medium:
      return COLOR_MIXIN_VARIABLES.Warning;
    case StationStatusType.Learning:
      return COLOR_MIXIN_VARIABLES.Light;
    case StationStatusType.Normal:
    case ConnectionType.Connected:
      return COLOR_MIXIN_VARIABLES.Success;
    default:
      return COLOR_MIXIN_VARIABLES.Transparent;
  }
};

export const getRoundCoordinate = (coord?: string | number) => round(Number(coord), 5);

export const getStationPriority = (station: StationModel) => {
  if (!getStationIsConnected(station)) {
    return STATION_STATUS_PRIOIRITY[station.connectionStatus as ConnectionType];
  }
  if (isStatusStation(station) && station.status) {
    return STATION_STATUS_PRIOIRITY[station.status];
  }
  if (isElescopeStation(station) && station.stationData) {
    return STATION_STATUS_PRIOIRITY[station.stationData.status];
  }

  return 1000;
};

export const getStationUrl = (station: StationModel) => `${station.ip}${station.port ? ':' + station.port : ''}`;

export const getStationIsNavigatable = (station: StationModel) => {
  if (getStationIsNotFound(station)) {
    return false;
  }

  return true;
};
