import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkOptions, FetchingData, Frequency } from '../types';
import { RootState } from './types';
import { createEmptyFetchedState } from './utils';
import { uniqBy } from 'lodash';
import { removeStationSettingSuccessAction, resetStateAction } from './shared';

interface AllFrequencies {
  allFrequencies: Frequency[];
}

interface InitialState {
  [key: string]: FetchingData<Frequency[]> & AllFrequencies;
}
const initialState: InitialState = {};

export const abortFrequenciesAction = createAsyncThunk('abortCurrentFrequencies', (_, { extra }: ThunkOptions) => {
  extra.api.abortController('abortCurrentFrequencies');

  return { type: 'abortCurrentFrequencies' };
});

export const abortAllFrequenciesAction = createAsyncThunk('abortAllFrequencies', (_, { extra }: ThunkOptions) => {
  extra.api.abortController('abortAllFrequencies');

  return { type: 'abortAllFrequencies' };
});

export const getStationCurrentFrequencies = createAsyncThunk<Frequency[], { stationId: number }>(
  'CONFIGS@GET_CURRENT_FREQUENCIES',
  ({ stationId }, { extra }: ThunkOptions) => {
    const api = extra.api;

    return api.get(`stations/${stationId}/currentFrequencies`, 'abortCurrentFrequencies');
  }
);

export const getStationAllFrequencies = createAsyncThunk<Frequency[], { stationId: number }>(
  'CONFIGS@GET_ALL_FREQUENCIES',
  ({ stationId }, { extra }: ThunkOptions) => {
    const api = extra.api;

    return api.get(`stations/${stationId}/allFrequencies`, 'abortAllFrequencies');
  }
);

export const blockFrequency = createAsyncThunk<number, { stationId: number; frequency: string }>(
  'CONFIGS@BLOCK_FREQUENCY',
  ({ stationId, frequency }, { extra }: ThunkOptions) => {
    const api = extra.api;

    return api.put(`stations/${stationId}/blockFrequency`, { freqBlock: frequency });
  }
);

export const unblockFrequency = createAsyncThunk<number, { stationId: number; frequency: string }>(
  'CONFIGS@UNBLOCK_FREQUENCY',
  ({ stationId, frequency }, { extra }: ThunkOptions) => {
    const api = extra.api;

    return api.put(`stations/${stationId}/deleteBlockFrequency`, { freqBlock: frequency });
  }
);

const frequenciesSlice = createSlice({
  name: 'frequenciesStation',
  initialState,
  reducers: {
    updateFrequencies: (state, action) => {
      const { stationId, freqBlock } = action.payload;

      state[stationId].data.forEach(dataItem => {
        dataItem.block = freqBlock.includes(dataItem.frequency);
      });
    }
  },
  extraReducers: builder => {
    builder.addCase(
      getStationCurrentFrequencies.pending,
      (state, action: { type: string; meta: { arg: { stationId: number } } }) => {
        const { stationId } = action.meta.arg;
        const station = state[stationId];

        if (!station) {
          state[stationId] = { ...createEmptyFetchedState([]), allFrequencies: [], loading: true };
        } else {
          state[stationId].loading = true;
        }
      }
    );
    builder.addCase(
      getStationCurrentFrequencies.rejected,
      (state, action: { type: string; payload: any; meta: { arg: { stationId: number } } }) => {
        const { stationId } = action.meta.arg;

        if (stationId) {
          state[stationId] = {
            ...state[stationId],
            error: action.payload
          };
        }
      }
    );
    builder.addCase(
      getStationCurrentFrequencies.fulfilled,
      (state, action: { type: string; meta: { arg: { stationId: number } }; payload: Frequency[] }) => {
        const { stationId } = action.meta.arg;

        const uniqFrequencies = uniqBy(action.payload, 'frequency');

        state[stationId].loading = false;
        state[stationId].error = null;
        state[stationId].data = uniqFrequencies;
      }
    );
    builder.addCase(resetStateAction.fulfilled, () => initialState);

    builder.addCase(removeStationSettingSuccessAction, (state, action) => {
      const stationId = action.payload;

      state[stationId] = { ...createEmptyFetchedState([]), allFrequencies: [] };
    });

    builder.addCase(getStationAllFrequencies.pending, (state, action: any) => {
      const { stationId } = action.meta.arg;
      const station = state[stationId];

      if (!station) {
        state[stationId] = { ...createEmptyFetchedState([]), allFrequencies: [], loading: true };
      } else {
        state[stationId].loading = true;
      }
    });

    builder.addCase(getStationAllFrequencies.rejected, (state, action: any) => {
      const { stationId } = action.meta.arg;
      const station = state[stationId];

      station.error = action.error;
      station.loading = false;
    });

    builder.addCase(getStationAllFrequencies.fulfilled, (state, action: any) => {
      const { stationId } = action.meta.arg;
      const station = state[stationId];

      station.error = null;
      station.loading = false;
      station.allFrequencies = action.payload;
    });

    builder.addCase(blockFrequency.fulfilled, (state, action) => {
      const { stationId, frequency } = action.meta.arg;
      const station = state[stationId];

      station.data.forEach(freqModel => {
        if (frequency === freqModel.frequency) {
          freqModel.block = true;
        }
      });

      station.allFrequencies.forEach(freqModel => {
        if (frequency === freqModel.frequency) {
          freqModel.block = true;
        }
      });
    });

    builder.addCase(unblockFrequency.fulfilled, (state, action) => {
      const { stationId, frequency } = action.meta.arg;
      const station = state[stationId];

      station.data.forEach(freqModel => {
        if (frequency === freqModel.frequency) {
          freqModel.block = false;
        }
      });

      station.allFrequencies.forEach(freqModel => {
        if (frequency === freqModel.frequency) {
          freqModel.block = false;
        }
      });
    });
  }
});

const defaultFrequenciesStationData = createEmptyFetchedState([]);

export const frequenciesReducer = frequenciesSlice.reducer;
export const getStationCurrentFrequenciesSelector = (
  state: RootState,
  stationId?: number
): FetchingData<Frequency[]> =>
  stationId ? state.frequencies[stationId] || defaultFrequenciesStationData : defaultFrequenciesStationData;

export const getFrequencies = (state: RootState, stationId?: number) => {
  const frequencies = getStationCurrentFrequenciesSelector(state, stationId);

  return frequencies.data || [];
};

export const getFrequenciesIsLoading = (state: RootState, stationId?: number) => {
  const stationState = getStationCurrentFrequenciesSelector(state, stationId);

  return stationState.loading;
};

export const getAllFrequenciesSelector = (state: RootState, stationId: number) =>
  stationId ? state.frequencies[stationId]?.allFrequencies || [] : [];
