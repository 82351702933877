import React from 'react';
import { TextColor, TextSize } from 'components/Text/Text';
import { VmsCamera } from 'types/Vms';

import TruncatedText from 'components/Text/TruncatedText';
import { CiVideoOn } from 'react-icons/ci';

interface VmsCameraListItemProps {
  onClick: () => void;
  camera: VmsCamera;
  isSelected?: boolean;
}

const CheckBoxCameraItem: React.FC<VmsCameraListItemProps> = ({ onClick, camera, isSelected }) => (
  <div onClick={onClick} className='flex flex-row vms-camera-name-wrapper mb-2 position-relative'>
    <div className='d-flex align-items-center w-100 pr-1'>
      <div className='d-flex align-items-center w-100 gap-1'>
        <CiVideoOn size={16} />
        <TruncatedText
          color={TextColor.Light}
          size={TextSize.Sm}
          className='cursor-pointer no-white-space text-overflow-elipsis'
          onClick={onClick}
        >
          {camera.name}
        </TruncatedText>
      </div>
      <input
        className='float-right cursor-pointer'
        type='checkbox'
        checked={isSelected}
        name='option'
        value='value'
        onChange={onClick}
      />
    </div>
  </div>
);

export default CheckBoxCameraItem;
