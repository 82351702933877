import React from 'react';
import { FlightWithDroneDetails } from './types';
import Text from 'components/Text';
import DroneFlightCard from '../DroneCard/DroneFlightCard';
import { Coordinate } from 'ol/coordinate';

interface ElescopeStationOneDayCardsProps {
  date: string;
  flights: FlightWithDroneDetails[];
  onFlightCardClick: (id: string) => void;
  flyTo: (coords: Coordinate) => void;
  disabledFlights: string[];
}

const ElescopeStationOneDayCards: React.FC<ElescopeStationOneDayCardsProps> = ({
  flights,
  date,
  onFlightCardClick,
  flyTo,
  disabledFlights
}) => (
  <>
    <Text className='px-3 mb-1'>{date}</Text>
    {flights.map(flight => (
      <DroneFlightCard
        key={flight.id}
        flight={flight}
        onFlightCardClick={onFlightCardClick}
        flyTo={flyTo}
        disabled={disabledFlights.includes(flight.id)}
      />
    ))}
  </>
);

export default ElescopeStationOneDayCards;
