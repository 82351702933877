import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/types';
import { StatusStationModel } from '../types';
import React from 'react';
import {
  getStatisticsFrequenciesAction,
  getStatisticsFrequenciesSelector,
  resetStationStatisticFrequenciesAndCancelPrevRequest
} from '../store/statisticFrequenciesReducer';
import { getCustomDateFormat } from '../utils';

export const OPTIONS = [
  {
    value: 'freq',
    label: 'selectOptionFreq'
  },
  {
    value: 'count',
    label: 'selectOptionCount'
  }
];

const ONE_DAY = 1000 * 60 * 60 * 24;

export default function useGetStatisticFrequencies({
  station,
  size,
  onlyToday
}: {
  station: StatusStationModel;
  size?: number;
  onlyToday?: boolean;
}) {
  const startDate = station.configuration?.expirationStatisticDuration ? new Date(Date.now() - ONE_DAY) : new Date();
  const initialDate = onlyToday && station.configuration ? [new Date(), new Date()] : [startDate, new Date()];
  const [periodDate, setPeriodDate] = React.useState<Date[]>(initialDate);
  const [sortType, setSortType] = React.useState<string>(OPTIONS[0].value);

  const getStatisticsFrequenciesSelectorCb = React.useMemo(
    () => (state: RootState) => getStatisticsFrequenciesSelector(state, station?.id),
    [station?.id]
  );
  const dispatch = useDispatch() as AppDispatch;
  const {
    data: { currentPage, data },
    loading,
    loaded,
    error
  } = useSelector(getStatisticsFrequenciesSelectorCb);

  const fetchStatisticsFrequencies = React.useCallback(
    (range = periodDate) => {
      const page = loaded ? currentPage + 1 : 0;

      if (station.id && !loading) {
        const endDate = getCustomDateFormat(range[1]);
        const startDateL = getCustomDateFormat(range[0]);

        dispatch(
          getStatisticsFrequenciesAction({
            stationId: station.id,
            page,
            startDate: startDateL,
            endDate,
            sortType,
            size
          })
        );
      }
    },
    [currentPage, station, periodDate, loading, loaded, sortType]
  );

  React.useEffect(() => {
    fetchStatisticsFrequencies();
  }, [periodDate, sortType]);

  const setPeriodDateProxy = React.useCallback((range: Date[]) => {
    dispatch(resetStationStatisticFrequenciesAndCancelPrevRequest(station.id));
    setTimeout(() => setPeriodDate(range));
  }, []);

  const setSortTypeProxy = React.useCallback((value: string) => {
    dispatch(resetStationStatisticFrequenciesAndCancelPrevRequest(station.id));
    setTimeout(() => setSortType(value));
  }, []);

  React.useEffect(
    () => () => {
      dispatch(resetStationStatisticFrequenciesAndCancelPrevRequest(station.id));
    },
    []
  );

  return {
    loading,
    fetchStatisticsFrequencies,
    data,
    error,
    setPeriodDate: setPeriodDateProxy,
    periodDate,
    sortType,
    setSortType: setSortTypeProxy
  };
}
