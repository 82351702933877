import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './types';

const uiSlice = createSlice({
  name: 'UI',
  initialState: {
    pageIsReady: false
  },
  reducers: {
    pageIsReady(state, action) {
      state.pageIsReady = action.payload;
    }
  }
});

export const uiReducer = uiSlice.reducer;
export const pageIsReadyAction = uiSlice.actions.pageIsReady;
export const getPageIsReadySelector = (state: RootState) => state.ui.pageIsReady;
