import React from 'react';
import Input from '../components/Input';
import TextResourceContext from '../contexts/TextResource';
import { passwordValidation } from '../components/Input/validations';
import Text, { TextColor, TextSize } from '../components/Text/Text';
import Button, { ButtonType } from '../components/Button';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/types';
import { changePassword } from '../store/authReducer';
import { getActionIsRejected } from '../store/utils';

interface ChangePasswordModalProps {
  closeModal: () => void;
}
const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ closeModal }) => {
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch() as AppDispatch;
  const [validationFailed, setValidationFailed] = React.useState(false);

  const resetValidation = () => {
    setValidationFailed(false);
  };

  const onSetError = () => {
    setValidationFailed(true);
  };

  const passwordsAreEqualValidation = () => {
    if (newPassword !== confirmPassword) {
      return 'passwordAreNotEqualValidation';
    }

    return null;
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await dispatch(changePassword({ newPassword, oldPassword })).then(response => {
      if (getActionIsRejected(response)) {
        setIsLoading(false);
      } else {
        closeModal();
      }
    });
  };

  const submitDisabled = !newPassword || !oldPassword || !confirmPassword || validationFailed;

  return (
    <div>
      <Text size={TextSize.MdLg} className='text-center uppercase mb-4' color={TextColor.White}>
        {getTextResourceByKey('changePassword')}
      </Text>
      <div className='grid-table-1-3'>
        <Text className='mt-1'>{getTextResourceByKey('oldPassword')}</Text>
        <Input
          value={oldPassword}
          wrapperClassName='mb-3'
          type='password'
          onSetValue={setOldPassword}
          placeholder={getTextResourceByKey('oldPassword') as string}
          onSetError={onSetError}
          onFocus={resetValidation}
        />

        <Text className='mt-1'>{getTextResourceByKey('newPassword')}</Text>
        <Input
          value={newPassword}
          type='password'
          onSetValue={setNewPassword}
          wrapperClassName='mb-3'
          placeholder={getTextResourceByKey('newPassword') as string}
          validations={[passwordValidation]}
          onSetError={onSetError}
          onFocus={resetValidation}
        />

        <Text className='mt-1'>{getTextResourceByKey('confirmPassword')}</Text>
        <Input
          value={confirmPassword}
          type='password'
          onSetValue={setConfirmPassword}
          wrapperClassName='mb-5'
          placeholder={getTextResourceByKey('confirmPassword') as string}
          validations={[passwordValidation, passwordsAreEqualValidation]}
          onSetError={onSetError}
          onFocus={resetValidation}
        />
      </div>

      <div className='flex-justify-end pt-4'>
        <Button
          buttonType={ButtonType.Success}
          className='add-station-component__add-btn'
          role='button'
          onClick={onSubmit}
          disabled={submitDisabled}
          loading={isLoading}
        >
          {getTextResourceByKey('submit')}
        </Button>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
