import React from 'react';
import { ElescopeStationModel } from 'types';
import TabsPanel from 'components/TabsPanel';
import ElescopeCurrentWidget from './ElescopeCurrentWidget';
import ElescopeHistoryWidget from './ElescopeHistoryWidget';
import Text, { TextSize } from 'components/Text';
import StationName from 'components/StationName';
import { compact } from 'lodash';
import { getStationIsAlarm } from 'utils/stations';
import { visualizeCoordinate } from 'utils/common';
import MultiSelectCheckBoxes from 'components/MultiSelect/MultiSelectCheckBoxes';
import { useGetStationEvents, StationEventsWidget } from 'pages/shared/StationEvents';
import Calendar from 'components/Calendar';
import { useSearchParams } from 'react-router-dom';
import useGetDocumentScrollTop from 'hooks/useGetDocumentScrollTop';
import { ELESCOPE_STATUSES } from 'pages/events/utils';
import { Option } from 'components/MultiSelect/types';

interface ElescopeStationComponentProps {
  station: ElescopeStationModel;
}

const statusesFilterList = ELESCOPE_STATUSES.map(status => ({
  value: status,
  label: status
}));

const ElescopeStationComponent: React.FC<ElescopeStationComponentProps> = ({ station }) => {
  const [searchParams] = useSearchParams();
  const searchParamsTabIndex = searchParams.get('tabIndex');
  const { documentScrolledTop } = useGetDocumentScrollTop();
  const titleOpacity = documentScrolledTop > 0 ? 0 : 1;

  const {
    onSubmitCalendar,
    loading,
    eventsMap,
    fetchNextPage,
    onSetStatusFilter,
    onSetAllStatusesFilters,
    statusFilters
  } = useGetStationEvents(station, ELESCOPE_STATUSES);

  const onSelectStatus = (optionsData: Option) => {
    onSetStatusFilter(optionsData.value as string);
  };

  const tabs = React.useMemo(
    () => (station ? compact([getStationIsAlarm(station) && 'current', 'history', 'events']) : []),
    [station]
  );

  const isAlarm = getStationIsAlarm(station);
  const tabsPanel = React.useMemo(
    () =>
      station
        ? compact([
            getStationIsAlarm(station) && <ElescopeCurrentWidget station={station} />,
            <ElescopeHistoryWidget station={station} />,
            <StationEventsWidget loading={loading} eventsMap={eventsMap} fetchNextPage={fetchNextPage} />
          ])
        : [],
    [station, isAlarm, statusFilters, searchParamsTabIndex, eventsMap]
  );

  const title = React.useMemo(
    () => (
      <div className='d-flex gap-3 align-items-center justify-content-end mr-1'>
        <div style={{ minWidth: '80px', transition: 'opacity .2s ease', opacity: titleOpacity }}>
          <StationName className='d-flex align-items-center justify-content-center' station={station} />
        </div>
        <Text size={TextSize.Sm}>
          ({visualizeCoordinate(station.latitude)}, {visualizeCoordinate(station.longitude)})
        </Text>
        {/*<div className='flex'>*/}
        {/*  <MapLayers className='mr-3' />*/}
        {/*</div>*/}
      </div>
    ),
    [station, titleOpacity]
  );

  const stationEventsTabIndex = getStationIsAlarm(station) ? '2' : '1';

  const getInterectiveSectionElements = () => {
    if (searchParamsTabIndex === stationEventsTabIndex) {
      return (
        <div className='d-flex gap-3 align-items-center justify-content-end'>
          <MultiSelectCheckBoxes
            placeholder='selectStatus'
            options={statusesFilterList}
            initialSelectedValues={ELESCOPE_STATUSES}
            onChange={onSelectStatus}
            onSetAllOptions={onSetAllStatusesFilters}
          />
          <Calendar
            placement='leftBottom'
            align={{ offset: [-90, -30] }}
            className='mr-2'
            onSubmit={onSubmitCalendar}
          />
        </div>
      );
    }
  };

  return (
    <TabsPanel
      tabs={tabs}
      tabsPanels={tabsPanel}
      title={title}
      tabsListBodyClassName='layout-width position-fixed'
      tabPanelClassName='pt-5'
      afterElements={getInterectiveSectionElements()}
    />
  );
};

export default React.memo(ElescopeStationComponent);
