import React, { ReactElement } from 'react';
import TextResourceContext from 'contexts/TextResource';
import { StatusStationModel } from 'types';
import { get, isEqual, isObject } from 'lodash';
import Button, { ButtonSize, ButtonType } from 'components/Button';
import { getStationConfigurationAction, updateStationAction } from 'store/stationsReducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/types';
import Text, { TextColor, TextSize } from 'components/Text';
import { COLORS, COLOR_MIXIN_VARIABLES } from 'consts';
import Input from 'components/Input';
import { coordinateValidationError, portValidation, ipValidation } from 'components/Input/validations';
import Spinner from 'components/Spinner';
import { removeAllStationSettings } from 'store/shared';
import ConfirmModalBody from 'components/ConfirmModalBody';
import MultiThumbSlider from 'components/MultiThumbsSlider';
import { ModalContext } from 'contexts/ModalContext';
import classNames from 'classnames';
import { MdOutlineLightbulb, MdFrontHand, MdIncompleteCircle, MdQueryStats } from 'react-icons/md';
import { CiSettings, CiTrash } from 'react-icons/ci';
import { PiDotOutlineFill } from 'react-icons/pi';
import { TbBellRinging, TbBellRinging2 } from 'react-icons/tb';
import { GiDuration } from 'react-icons/gi';
import { getDefaultCoordsView } from 'utils/common';

import './StationSettings.scss';

const RECOMENDATIONS = [
  'recommendation/names',
  'recommendation/coordinates',
  'recommendation/locations',
  'recommendation/ip'
];

const WARNINGS = [
  'warningCleanStatusSettings/cleanAllPrevSettings',
  'warningCleanStatusSettings/cleanAllPrevStatistic'
];

interface StationFieldsProps {
  station: StatusStationModel;
  setExtraNavbarComponent: (comp: ReactElement) => void;
}

const SLIDER_NAMES = {
  ALARM: 'alarm',
  MEDIUM: 'medium',
  STATISTIC: 'statistic',
  TIME: 'time',
  LEARNING: 'learning'
};

const FIELDS_TO_BE_CHANGED = {
  name: 'name',
  alarmThreshold: ['configuration', 'alarmThreshold'],
  latitude: 'latitude',
  longitude: 'longitude',
  mediumThreshold: ['configuration', 'mediumThreshold'],
  timeLearning: ['configuration', 'timeLearning'],
  timeRangeAveraging: ['configuration', 'timeRangeAveraging'],
  expirationStatisticDuration: ['configuration', 'expirationStatisticDuration'],
  powerMinThreshold: ['configuration', 'powerMinThreshold'],
  enabled: 'enabled',
  ip: 'ip',
  port: 'port'
};

const inputLabelHeight = {
  height: '0.75rem'
};

const inputWrapperHeight = {
  height: '2.5rem'
};

const RANGE_SLIDER_CONFIGURATION = {
  step: 1,
  min: 0,
  max: 30
};

const ALARM_RANGE = [40, 50, 60, 70, 80];
const MEDIUM_RANGE = [20, 30, 40, 50, 60];
const STATISTIC_RANGE = [30, 45, 60, 90, 120];
const TIME_RANGE = [0, 4, 6, 8, 12];
const LEARNING_RANGE = [1, 2, 3, 4, 5];

interface NewSettingsBody {
  [key: string]: string | number | boolean;
}

const getInitialSettings = (settings: StatusStationModel) =>
  Object.entries(FIELDS_TO_BE_CHANGED).reduce<NewSettingsBody>((acc, [key, value]) => {
    acc[key] = get(settings, value);

    return acc;
  }, {});

const StationSettings: React.FC<StationFieldsProps> = ({ station, setExtraNavbarComponent }) => {
  const initialSettings = getInitialSettings(station);

  const [newStationSettings, setNewStationSettings] = React.useState<NewSettingsBody>(
    getDefaultCoordsView(initialSettings)
  );

  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const dispatch = useDispatch() as AppDispatch;
  const { openModal, closeModal } = React.useContext(ModalContext);
  const [isSaving, setIsSaving] = React.useState(false);

  const statusAlarm = getTextResourceByKey('ALARM');
  const statusMedium = getTextResourceByKey('MEDIUM');

  React.useEffect(() => {
    setNewStationSettings(initialSettings);
  }, [JSON.stringify(station)]);

  React.useEffect(() => {
    setNewStationSettings({ ...newStationSettings, ...initialSettings });
  }, [station.configuration]);

  React.useEffect(() => {
    dispatch(getStationConfigurationAction(station.id));
  }, []);

  const setConfigValueMesuareUnit = (radioBtnName: string) => (selectedValue: number) => {
    if (radioBtnName === SLIDER_NAMES.ALARM || radioBtnName === SLIDER_NAMES.MEDIUM) {
      return selectedValue + '%';
    }

    if (radioBtnName === SLIDER_NAMES.STATISTIC || radioBtnName === SLIDER_NAMES.LEARNING) {
      return `${selectedValue} ${getTextResourceByKey('days')}`;
    }

    if (radioBtnName === SLIDER_NAMES.TIME) {
      return `${selectedValue} ${getTextResourceByKey('hours')}`;
    }

    return selectedValue;
  };

  const onChangeSettings = (key: string) => (value: string | number | boolean | { value: string | number }) => {
    const valueFormatted = isObject(value) ? value.value : value;

    setNewStationSettings(set => ({ ...set, [key]: valueFormatted }));
  };

  const onUpdateSettings = React.useCallback(() => {
    if (!station) {
      return;
    }
    setIsSaving(true);
    const localInitialSettings = getInitialSettings(station);

    if (!isEqual(newStationSettings, localInitialSettings)) {
      const body = Object.entries(newStationSettings).reduce((acc: Partial<NewSettingsBody>, [key, value]) => {
        if (localInitialSettings[key] !== value) {
          acc[key] = value;
        }

        return acc;
      }, {});

      dispatch(updateStationAction({ stationId: station.id, body })).finally(() => setIsSaving(false));
    }
  }, [station, newStationSettings]);

  const onDeleteSettings = React.useCallback(() => {
    const onSubmit = () => {
      if (station?.id) {
        dispatch(removeAllStationSettings(station?.id)).then(closeModal);
      }
    };

    openModal(
      <ConfirmModalBody
        onClose={closeModal}
        onSubmit={onSubmit}
        title={getTextResourceByKey('removeSettingsModalTitle') as string}
        type='delete'
      >
        {getTextResourceByKey('removeSettingsModalBody', {
          name: <span className='text-bold text-underline color-white'>{station?.name}</span>
        })}
      </ConfirmModalBody>,
      { modalType: 'confirm' }
    );
  }, [station?.id]);

  React.useEffect(() => {
    setExtraNavbarComponent(
      <Button onClick={onUpdateSettings} className='uppercase mr-05' buttonType={ButtonType.Success} loading={isSaving}>
        {getTextResourceByKey('save')}
      </Button>
    );
  }, [onUpdateSettings, isSaving]);

  const setButtonEnabled = React.useCallback(() => {
    onChangeSettings('enabled')(true);
  }, []);

  const setButtonDisabled = React.useCallback(() => {
    onChangeSettings('enabled')(false);
  }, []);

  return (
    <div className='pt-5'>
      <div className='align-items-center flex-space-between dashed-border p-3 rounded-1'>
        <div className='d-flex'>
          <CiSettings size={40} color={COLORS.white} className='mr-1' />
          <div>
            <Text size={TextSize.SmMd} color={TextColor.Warning} className='mb-1'>
              {getTextResourceByKey('settings/moduleActivation')}
            </Text>
            <Text size={TextSize.SmMd} color={TextColor.Gray}>
              {getTextResourceByKey('settings/moduleActivation/description')}
            </Text>
          </div>
        </div>
        <div className='d-flex gap-2'>
          <Button
            buttonType={newStationSettings.enabled ? ButtonType.Success : ButtonType.Transparent}
            size={ButtonSize.SmallMedium}
            className='mr-05'
            onClick={setButtonEnabled}
          >
            <Text className='uppercase' color={TextColor.White}>
              {getTextResourceByKey('enable')}
            </Text>
          </Button>

          <Button
            className='color-white'
            buttonType={newStationSettings.enabled ? ButtonType.Transparent : ButtonType.Danger}
            size={ButtonSize.SmallMedium}
            onClick={setButtonDisabled}
          >
            <Text className='uppercase' color={TextColor.White}>
              {getTextResourceByKey('disable')}
            </Text>
          </Button>
        </div>
      </div>
      <div className={classNames(!newStationSettings.enabled && 'opacity-50 pointer-events-none', 'd-flex')}>
        <div className='w-100 mt-5'>
          <div className='w-75'>
            <div className='mb-2'>
              <div style={inputLabelHeight}>
                {newStationSettings.name && (
                  <Text size={TextSize.Sm} color={TextColor.Silver} className='mx-2'>
                    {getTextResourceByKey('stationName')}
                  </Text>
                )}
              </div>
              <div style={inputWrapperHeight}>
                <Input
                  value={newStationSettings.name as string}
                  onSetValue={onChangeSettings('name')}
                  placeholder={getTextResourceByKey('stationName') as string}
                  className='h-50'
                />
              </div>
            </div>
            <div className='d-flex gap-3 mb-2'>
              <div className='w-50'>
                <div style={inputLabelHeight}>
                  {newStationSettings.ip && (
                    <Text size={TextSize.Sm} color={TextColor.Silver} className='mx-2'>
                      {getTextResourceByKey('ip')}
                    </Text>
                  )}
                </div>
                <div style={inputWrapperHeight}>
                  <Input
                    value={newStationSettings.ip as string}
                    onSetValue={onChangeSettings('ip')}
                    placeholder={getTextResourceByKey('ip') as string}
                    validations={[ipValidation]}
                    className='h-50'
                  />
                </div>
              </div>
              <div className='w-50'>
                <div style={inputLabelHeight}>
                  {newStationSettings.port && (
                    <Text size={TextSize.Sm} color={TextColor.Silver} className='mx-2'>
                      {getTextResourceByKey('port')}
                    </Text>
                  )}
                </div>
                <div style={inputWrapperHeight}>
                  <Input
                    value={newStationSettings.port as string}
                    onSetValue={onChangeSettings('port')}
                    placeholder={getTextResourceByKey('port') as string}
                    validations={[portValidation]}
                    className='h-50'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex gap-3'>
              <div className='w-50'>
                <div style={inputLabelHeight}>
                  {newStationSettings.latitude && (
                    <Text size={TextSize.Sm} color={TextColor.Silver} className='mx-2'>
                      {getTextResourceByKey('latitude')}
                    </Text>
                  )}
                </div>
                <div style={inputWrapperHeight}>
                  <Input
                    value={newStationSettings.latitude as string}
                    onSetValue={onChangeSettings('latitude')}
                    placeholder={getTextResourceByKey('latitude') as string}
                    validations={[coordinateValidationError]}
                    className='h-50'
                  />
                </div>
              </div>
              <div className='w-50'>
                <div style={inputLabelHeight}>
                  {newStationSettings.longitude && (
                    <Text size={TextSize.Sm} color={TextColor.Silver} className='mx-2'>
                      {getTextResourceByKey('longitude')}
                    </Text>
                  )}
                </div>
                <div style={inputWrapperHeight}>
                  <Input
                    value={newStationSettings.longitude as string}
                    onSetValue={onChangeSettings('longitude')}
                    placeholder={getTextResourceByKey('longitude') as string}
                    validations={[coordinateValidationError]}
                    className='h-50'
                  />
                </div>
              </div>
            </div>
          </div>
          {station.configuration ? (
            <div className='mt-5 w-75'>
              <MultiThumbSlider
                preElement={<TbBellRinging color={COLORS.danger} size={25} />}
                title={getTextResourceByKey('threshold', { status: statusAlarm })}
                options={ALARM_RANGE}
                radioBtnName={SLIDER_NAMES.ALARM}
                getSelectedValueTittle={setConfigValueMesuareUnit(SLIDER_NAMES.ALARM)}
                color={COLOR_MIXIN_VARIABLES.Danger}
                onChange={onChangeSettings('alarmThreshold')}
                selectedValue={newStationSettings.alarmThreshold as number}
              />
              <MultiThumbSlider
                preElement={<TbBellRinging2 className='rotate--45' color={COLORS.warning} size={25} />}
                title={getTextResourceByKey('threshold', { status: statusMedium })}
                options={MEDIUM_RANGE}
                radioBtnName={SLIDER_NAMES.MEDIUM}
                getSelectedValueTittle={setConfigValueMesuareUnit(SLIDER_NAMES.MEDIUM)}
                color={COLOR_MIXIN_VARIABLES.Warning}
                onChange={onChangeSettings('mediumThreshold')}
                selectedValue={newStationSettings.mediumThreshold as number}
              />
              <MultiThumbSlider
                className='opacity-50 pointer-events-none'
                preElement={<MdQueryStats color={COLORS.white} size={25} />}
                title={getTextResourceByKey('expirationStatisticDuration')}
                options={STATISTIC_RANGE}
                radioBtnName={SLIDER_NAMES.STATISTIC}
                getSelectedValueTittle={setConfigValueMesuareUnit(SLIDER_NAMES.STATISTIC)}
                onChange={onChangeSettings('expirationStatisticDuration')}
                selectedValue={newStationSettings.expirationStatisticDuration as number}
              />
              <MultiThumbSlider
                preElement={<GiDuration color={COLORS.white} size={25} />}
                title={getTextResourceByKey('timeRangeAveraging')}
                options={TIME_RANGE}
                radioBtnName={SLIDER_NAMES.TIME}
                getSelectedValueTittle={setConfigValueMesuareUnit(SLIDER_NAMES.TIME)}
                onChange={onChangeSettings('timeRangeAveraging')}
                selectedValue={newStationSettings.timeRangeAveraging as number}
              />
              <MultiThumbSlider
                preElement={<MdIncompleteCircle color={COLORS.white} size={25} />}
                title={getTextResourceByKey('timeLearning')}
                options={LEARNING_RANGE}
                radioBtnName={SLIDER_NAMES.LEARNING}
                getSelectedValueTittle={setConfigValueMesuareUnit(SLIDER_NAMES.LEARNING)}
                onChange={onChangeSettings('timeLearning')}
                selectedValue={newStationSettings.timeLearning as number}
              />
            </div>
          ) : (
            <div className='mt-3'>
              <Spinner />
            </div>
          )}
        </div>

        <div className='w-100 mt-3'>
          <div className='d-flex flex-column rounded-1 p-3 station-settings__recommendations mb-4'>
            <div className='d-flex'>
              <Text className='uppercase mb-1' size={TextSize.Md} color={TextColor.White}>
                {getTextResourceByKey('amplitudeValueForFilter')}
              </Text>
            </div>
            <div className='d-flex mb-2'>
              <Text className='font-weight-bold text-justify'>
                {getTextResourceByKey('amplitudeValueForFilter/description')}
              </Text>
            </div>
            <div className='d-flex gap-2 w-100'>
              <Input
                value={newStationSettings['powerMinThreshold'] as string}
                onSetValue={onChangeSettings('powerMinThreshold')}
                type='range'
                wrapperClassName='d-flex w-90'
                className='station-settings__slider'
                withEmptyStringValidation={false}
                {...RANGE_SLIDER_CONFIGURATION}
              />
              <Text size={TextSize.Md} className='text-center station-settings__slider-units'>
                {newStationSettings['powerMinThreshold']} {getTextResourceByKey('decibelUnit')}
              </Text>
            </div>
          </div>
          <div className='d-flex rounded-1 p-3 flex-align-center station-settings__recommendations mb-4'>
            <div className='text-center mr-2'>
              <MdOutlineLightbulb color={COLORS.warning} size={100} />
            </div>
            <ul>
              <Text className='uppercase mb-1' size={TextSize.Md} color={TextColor.Warning}>
                {getTextResourceByKey('recommendations')}
              </Text>
              {RECOMENDATIONS.map(rec => (
                <div className='d-flex align-items-center' key={rec}>
                  <PiDotOutlineFill color={COLORS.light} />
                  <Text className='font-weight-bold'>{getTextResourceByKey(rec)}</Text>
                </div>
              ))}
            </ul>
          </div>
          <div className='d-flex dashed-border-danger p-3 rounded-1'>
            <div className='d-flex w-100'>
              <div
                className='rounded-circle align-self-center p-05 stop-hand-container ml-1 mr-3'
                style={{ height: 'fit-content' }}
              >
                <MdFrontHand className='p-2' color={COLORS.danger} size={50} />
              </div>
              <div className='d-flex w-100'>
                <div className='flex-column mr-1'>
                  <Text color={TextColor.Danger} size={TextSize.Md} className='mb-1 uppercase'>
                    {getTextResourceByKey('settings/cleanStatistic')}
                  </Text>
                  {WARNINGS.map(warn => (
                    <div className='mr-2 flex-align-center' key={warn}>
                      <PiDotOutlineFill color={COLORS.light} />
                      <Text className='font-weight-bold'>{getTextResourceByKey(warn)}</Text>
                    </div>
                  ))}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <Button buttonType={ButtonType.Danger} className='cursor-pointer' onClick={onDeleteSettings}>
                  <CiTrash color={COLORS.white} size={25} />
                  <Text className='p-2' color={TextColor.White}>
                    {getTextResourceByKey('clean')}
                  </Text>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StationSettings);
