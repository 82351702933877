import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EventFromBE, Event, EventsResponse, EventsState, FetchingData, PaginationData, ThunkOptions } from '../types';
import { RootState } from './types';
import { createEmptyPaginationState } from './utils';
import { extendUrlByQueries } from '../utils/api';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import { resetStateAction } from './shared';

interface InitialState {
  [key: number]: FetchingData<PaginationData<Event[]>>;
}

const initialState: InitialState = {};
const SIGNAL_KEY = 'stationMapEventsEvents';

export const getStationMapEventsAction = createAsyncThunk<
  EventsResponse,
  { startDate: string; endDate: string; page: number; size: number; stationId: number }
>('EVENTS@GET_STATION_EVENTS', (queries, { extra }: ThunkOptions) =>
  extra.api.get(extendUrlByQueries('events', queries), SIGNAL_KEY).then((response: PaginationData<EventFromBE[]>) => ({
    ...response,
    data: response.data.map(({ createdAt, ...ev }) => ({
      ...ev,
      time: dayjs(createdAt).format('HH:mm:ss'),
      date: dayjs(createdAt).format('YYYY-MM-DD')
    }))
  }))
);

export const resetStationMapEvents = createAction('EVENTS@RESET_STATION_EVENTS_STATE');

const stationMapEventsSlice = createSlice({
  name: 'stationMapEvents',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStationMapEventsAction.pending, (state, action) => {
      const stationId = action.meta.arg.stationId;
      const stationEventData = state[stationId];

      if (!stationEventData) {
        state[stationId] = { ...createEmptyPaginationState(), loading: true };
      } else {
        stationEventData.loading = true;
      }
    });
    builder.addCase(getStationMapEventsAction.rejected, (state, action) => {
      const stationId = action.meta.arg.stationId;
      const stationEventData = state[stationId];

      stationEventData.error = action.error;
      stationEventData.loading = false;
    });
    builder.addCase(getStationMapEventsAction.fulfilled, (state, action) => {
      const stationId = action.meta.arg.stationId;
      const stationEventData = state[stationId];

      stationEventData.error = null;
      stationEventData.loading = false;
      stationEventData.data.data = uniqBy([...stationEventData.data.data, ...action.payload.data], 'id');
      stationEventData.data.currentPage = action.payload.currentPage;
      stationEventData.data.totalItems = action.payload.totalItems;
      stationEventData.data.totalPages = action.payload.totalPages;
    });
    builder.addCase(resetStateAction.fulfilled, () => initialState);
    builder.addCase(resetStationMapEvents, () => initialState);
  }
});

export const getStationMapEventsSelector = (state: RootState, stationId: number): EventsState =>
  state.stationMapEvents[stationId] || createEmptyPaginationState();

export const stationMapEventsReducer = stationMapEventsSlice.reducer;
