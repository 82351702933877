import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthResponse, RootState } from './types';
import { FetchingData, ThunkOptions, UsersRole } from 'types';
import { resetStateAction } from './shared';
import { pageIsReadyAction } from './uiReducer';
import { USER_LOGGED_IN } from 'services/event-bus';
import { toastError, toastSuccess } from '../components/Toaster';
import { isString } from 'lodash';
type AuthState = FetchingData<AuthResponse | null>;

const initialState: AuthState = {
  loading: false,
  loaded: false,
  error: null,
  data: null
};

export const signIn = createAsyncThunk<AuthResponse, { login: string; password: string }>(
  'AUTH@SIGN_IN',
  (body, { extra: { api, eventBus } }: ThunkOptions) =>
    api
      .auth('auth/sign-in', body)
      .then((authResponse: AuthResponse) => {
        const { sessionId, ...rest } = authResponse;

        eventBus.publish(USER_LOGGED_IN, { sessionId });
        localStorage.setItem('sessionId', sessionId);

        return rest;
      })
      .catch(() => {
        throw new Error('authFailed');
      })
);

export const signOut = createAsyncThunk('AUTH@SIGN_OUT', (_, { extra: { api }, dispatch }: ThunkOptions) =>
  api.post('auth/sign-out').then(() => {
    dispatch(resetStateAction());
  })
);

export const getMeAction = createAsyncThunk('AUTH@GET_ME', (_, { extra, dispatch }: ThunkOptions) => {
  const { api, eventBus } = extra;

  return api
    .get('users/me')
    .then((userResponse: AuthResponse) => {
      const sessionId = localStorage.getItem('sessionId');

      eventBus.publish(USER_LOGGED_IN, { sessionId });

      return userResponse;
    })
    .finally(() => {
      setTimeout(() => dispatch(pageIsReadyAction(true)), 0);
    });
});

export const updateSettings = createAsyncThunk<
  void,
  {
    language?: string;
    soundNotifications?: Partial<Notification>;
    firebaseNotifications?: Partial<Notification>;
    name?: string;
    login?: string;
  }
>('AUTH@UPDATE_SETTINGS', (settings, { extra, dispatch }: ThunkOptions) => {
  const { api, getLocalizedTextValue } = extra;

  api
    .patch('users/me', settings)
    .then(() => {
      toastSuccess(getLocalizedTextValue('userUpdatedSuccessfully'));

      return dispatch(getMeAction());
    })
    .catch((e: Error) => {
      toastError(isString(e) ? e : getLocalizedTextValue('userUpdatedFailed'));
    });
});

export const changePassword = createAsyncThunk(
  'AUTH@CHANGE_PASSWORD',
  (body: { oldPassword: string; newPassword: string }, { extra }: ThunkOptions) => {
    const api = extra.api;
    const getLocalizedTextValue = extra.getLocalizedTextValue;

    return api
      .post('users/me/change-password', body)
      .then(() => {
        toastSuccess(getLocalizedTextValue('updatedSuccessfully'));
      })
      .catch((e: Error | string) => {
        toastError(isString(e) ? e : getLocalizedTextValue('userUpdatedFailed'));

        throw e;
      });
  }
);

export const clearErrorAction = createAction('clearErrorAction');

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    sessionExpired(state) {
      state.error = { message: 'sessionExpired' };
    }
  },
  extraReducers: builder => {
    builder.addCase(signIn.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.loaded = true;
      state.data = action.payload;
    });
    builder.addCase(resetStateAction.fulfilled, () => initialState);
    builder.addCase(clearErrorAction.name, state => {
      state.error = null;
    });
    builder.addCase(getMeAction.pending, state => {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    });
    builder.addCase(getMeAction.rejected, (state, action) => {
      state.loading = false;
      state.loaded = false;
      state.error = action.error || null;
    });
    builder.addCase(getMeAction.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.loaded = true;
      state.data = action.payload;
    });
  }
});

export const getAuth = (state: RootState) => (state.auth || initialState) as AuthState;
export const getAuthData = (state: RootState) => getAuth(state).data;
export const getAuthRole = (state: RootState) => getAuthData(state)?.role || UsersRole.Default;
export const getIsAdmin = (state: RootState) =>
  getAuthData(state)?.role === UsersRole.Admin || getAuthData(state)?.role === UsersRole.SuperAdmin;
export const getIsAuthenticated = (state: RootState) => !!getAuthData(state);
export const getCurrentUserLogin = (state: RootState) => getAuthData(state)?.login;

export const authReducer = authSlice.reducer;
export const { sessionExpired } = authSlice.actions;
