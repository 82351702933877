import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  isElescopeStation,
  isStatusStation,
  isBaklansStation,
  PageRoutes,
  StationModel,
  isHrushchStation
} from 'types';
import StatusStationComponent from './StationComponent/StationComponent';
import HeaderComponent from 'components/HeaderComponent';
import useGetStation from 'hooks/useGetStation';
import Spinner from 'components/Spinner';
import { GiWifiRouter } from 'react-icons/gi';
import { COLORS, LOGO_SIZE } from 'consts';
import ElescopeStationComponent from './elescope/ElescopeStationComponent';
import BaklansStationComponent from './baklans/BaklansStationComponent';
import HrushchStationComponent from './hrushch/HrushchStationComponent';

const getStationComponent = (station: StationModel) => {
  if (isStatusStation(station)) {
    return <StatusStationComponent station={station} />;
  }

  if (isElescopeStation(station)) {
    return <ElescopeStationComponent station={station} />;
  }

  if (isBaklansStation(station)) {
    return <BaklansStationComponent station={station} />;
  }

  if (isHrushchStation(station)) {
    return <HrushchStationComponent station={station} />;
  }
};

const StationRootComponent = () => {
  const { loading, loaded, station } = useGetStation();
  const navigate = useNavigate();

  if (loaded && !station) {
    navigate(PageRoutes.Dashboard);
  }

  if (loading || !station) {
    return <Spinner isFullSize />;
  }

  return (
    <div className='station'>
      <HeaderComponent logo={<GiWifiRouter size={LOGO_SIZE} color={COLORS.white} />} />
      {getStationComponent(station)}
    </div>
  );
};

export default StationRootComponent;
