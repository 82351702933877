import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getStations } from '../store/stationsReducer';
import React from 'react';

const useGetStation = () => {
  const { stationId } = useParams();
  const { data: stations, loading, loaded } = useSelector(getStations);
  const station = React.useMemo(() => stations?.find(st => st.id.toString() === stationId), [stations, stationId]);

  return { station, loaded, loading: loading && !stations };
};

export default useGetStation;
