import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/types';
import { throttle } from 'lodash';
import { ONE_SECOND } from '../utils/date';
import { getAuthData, getIsAuthenticated } from 'store/authReducer';
import { heartBeatAction } from 'store/shared';

const timeOut = 10 * ONE_SECOND;

export default function useHeartBeatsPing() {
  const dispatch = useDispatch() as AppDispatch;
  const isLoggedIn = useSelector(getIsAuthenticated);
  const authData = useSelector(getAuthData);
  const inactiveSessionMode = authData?.inactiveSessionMode;

  React.useEffect(() => {
    if (isLoggedIn && inactiveSessionMode) {
      const callback = throttle(
        () => {
          dispatch(heartBeatAction());
        },
        timeOut,
        { trailing: false }
      );

      window.addEventListener('mousemove', callback);

      return () => {
        window.removeEventListener('mousemove', callback);
      };
    } else if (isLoggedIn && !inactiveSessionMode) {
      const interval = setInterval(() => {
        dispatch(heartBeatAction());
      }, timeOut);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isLoggedIn, inactiveSessionMode]);
}
