import React from 'react';
import { StatusStationModel, AnalyticsCountsDiff } from 'types';
import EmptyState from 'components/EmptyState';
import ErrorMessage from 'components/ErrorMessage';
import Spinner from 'components/Spinner';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import TextResourceContext from 'contexts/TextResource';
import { BiSolidUpArrow, BiSolidDownArrow } from 'react-icons/bi';
import { COLORS } from 'consts';
import {
  getAnalyticsCountAction,
  getAnalyticsCountsSelector,
  getAnalyticsCountsDataIsEmptySelector,
  resetStationAnalyticsCountsAndCancelPrevRequest
} from 'store/analyticsCountsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/types';
import Pagination from 'components/Pagination';
import Calendar from 'components/Calendar';

import './AnalyticsWidget.scss';

interface AnalyticsCountsProps {
  station: StatusStationModel;
  pageSize: number;
  firstPeriodDefaultVal: Date[];
  secondPeriodDefaultVal: Date[];
}

const AnalyticsCounts: React.FC<AnalyticsCountsProps> = ({
  station,
  pageSize,
  firstPeriodDefaultVal,
  secondPeriodDefaultVal
}) => {
  const dispatch = useDispatch() as AppDispatch;
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const [firstPeriod, setFirstPeriod] = React.useState(firstPeriodDefaultVal);
  const [secondPeriod, setSecondPeriod] = React.useState(secondPeriodDefaultVal);
  const [currentPage, setCurrentPage] = React.useState(1);

  const {
    error: countsError,
    loading: isCountsLoading,
    data: { increased: countsIncreased, decreased: countsDecreased }
  } = useSelector((state: RootState) => getAnalyticsCountsSelector(state, station.id));

  const countsDataMaxLength =
    countsIncreased.length >= countsDecreased.length ? countsIncreased.length : countsDecreased.length;
  const totalPages = Math.ceil(countsDataMaxLength / pageSize);
  const countsPaginatedData = (arr: AnalyticsCountsDiff[]) => arr.slice(0, currentPage * pageSize);
  const countsIncreasedPaginated = countsPaginatedData(countsIncreased);
  const countsDecreasedPaginated = countsPaginatedData(countsDecreased);

  const onNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [firstPeriod, secondPeriod]);

  const isCountsDataEmpty = useSelector((state: RootState) => getAnalyticsCountsDataIsEmptySelector(state, station.id));

  React.useEffect(() => {
    dispatch(
      getAnalyticsCountAction({
        stationId: station.id,
        firstPeriodStart: firstPeriod[0].getTime(),
        firstPeriodEnd: firstPeriod[1].getTime(),
        secondPeriodStart: secondPeriod[0].getTime(),
        secondPeriodEnd: secondPeriod[1].getTime()
      })
    );

    return () => {
      dispatch(resetStationAnalyticsCountsAndCancelPrevRequest(station.id));
    };
  }, [firstPeriod, secondPeriod]);

  return (
    <div className='mr-3 ml-3'>
      <div className='d-flex analytics__calendars-gap mb-4 flex-row'>
        <div className='d-flex flex-column'>
          <Text size={TextSize.Sm} className='mb-2 '>
            {getTextResourceByKey('primaryPeriod')}
          </Text>
          <Calendar
            placement='bottomRight'
            onSubmit={setFirstPeriod}
            textColor={TextColor.Orange}
            defaultValue={firstPeriodDefaultVal}
          />
        </div>
        <div className='d-flex flex-column'>
          <Text size={TextSize.Sm} className='mb-2 '>
            {getTextResourceByKey('secondaryPeriod')}
          </Text>
          <Calendar
            placement='bottomRight'
            onSubmit={setSecondPeriod}
            textColor={TextColor.Orange}
            defaultValue={secondPeriodDefaultVal}
          />
        </div>
      </div>
      {isCountsLoading ? (
        <Spinner className='w-100' isFullSize />
      ) : (
        <>
          {isCountsDataEmpty || countsError?.message ? (
            <>
              {isCountsDataEmpty && <EmptyState />}
              {countsError?.message && (
                <ErrorMessage className='analytics__error-section' message={countsError?.message} />
              )}
            </>
          ) : (
            <Pagination callback={onNextPage}>
              <div className='d-flex mb-4'>
                <div className='d-flex flex-column w-100'>
                  <Text className='mb-2'>{getTextResourceByKey('analytics/counts/description')}</Text>
                  <div className='d-flex gap-3 w-100'>
                    <div className='analytics__section w-50 p-3 rounded-1'>
                      {countsIncreasedPaginated.map(incFrequency => (
                        <div key={incFrequency.frequency}>
                          <div className='analytics__section__card-counts d-flex rounded-1 align-itmes-center gap-2'>
                            <Text
                              color={TextColor.White}
                              size={TextSize.SmMd}
                              className='font-weight-light align-self-center'
                            >
                              {incFrequency.frequency}
                            </Text>
                            <div className='analytics__section__card-counts__diff-indicator d-flex flex-column flex-align-center'>
                              <BiSolidUpArrow color={COLORS.success} size={15} />
                              <Text color={TextColor.Success}>{incFrequency.count}</Text>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='analytics__section w-50 p-3 rounded-1'>
                      {countsDecreasedPaginated.map(decFrequency => (
                        <div
                          key={decFrequency.frequency}
                          className='analytics__section__card-counts d-flex rounded-1 align-itmes-center gap-2'
                        >
                          <Text
                            color={TextColor.White}
                            size={TextSize.SmMd}
                            className='font-weight-light align-self-center'
                          >
                            {decFrequency.frequency}
                          </Text>
                          <div className='analytics__section__card-counts__diff-indicator d-flex flex-column flex-align-center'>
                            <Text color={TextColor.Danger}>{decFrequency.count}</Text>
                            <BiSolidDownArrow color={COLORS.danger} size={15} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Pagination>
          )}
        </>
      )}
    </div>
  );
};

export default AnalyticsCounts;
