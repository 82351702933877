import React from 'react';
import { SettingsConfigWithId } from 'types';
import UpdateSettingsModal from './UpdateSettingsModal';

interface EditSettingsModalProps {
  onSubmit: (options: SettingsConfigWithId) => void;
  initialSettings: SettingsConfigWithId;
}

const EditSettingsModal: React.FC<EditSettingsModalProps> = ({ onSubmit, initialSettings }) => (
  <UpdateSettingsModal
    onSubmit={onSubmit}
    initialSettings={initialSettings}
    title={'editSettingsFrequencyModalTitle'}
  />
);

export default EditSettingsModal;
