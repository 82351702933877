import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDronesStationSelector, getStationDrones, resetStationDrones } from 'store/dronesReducer';
import { AppDispatch, RootState } from 'store/types';
import { transform } from 'lodash';
import { FlightWithDroneDetailsMap } from './ElescopeStationLayout';
import { COLORS } from 'consts';
import { DroneFlight } from 'types';

interface DateRange {
  startDate?: string;
  endDate?: string;
}

const colorsList = [COLORS.white, COLORS.danger, COLORS.purple, COLORS.pink, COLORS.primary, COLORS.black];

interface FlightWithDroneId {
  droneId: string;
  droneType: string;
  flight: DroneFlight;
}

const useGetDrones = (stationId: number, { startDate, endDate }: DateRange = {}) => {
  const dispatch = useDispatch() as AppDispatch;

  const getDroneSelectorCb = React.useCallback(
    (state: RootState) => getDronesStationSelector(state, stationId),
    [stationId]
  );

  const { loading, data } = useSelector(getDroneSelectorCb);

  const droneWithFlights = React.useMemo(() => data.filter(d => d.flights.length > 0), [data]);

  React.useEffect(() => {
    dispatch(getStationDrones({ stationId, startDate, endDate }));

    return () => {
      dispatch(resetStationDrones(stationId));
    };
  }, [stationId, startDate, endDate]);

  const isCurrentFlight = !startDate;
  const flightsMap = React.useMemo(() => {
    const flightsData = transform(
      droneWithFlights,
      (acc: FlightWithDroneId[], drone) => {
        drone.flights.forEach(flight => {
          acc.push({ droneId: drone.droneId, droneType: drone.droneType, flight });
        });

        return acc;
      },
      []
    );

    return flightsData.reduce((acc: FlightWithDroneDetailsMap, flightItem, i) => {
      const date = flightItem.flight.startDate.split(' ')[1] as string;

      if (!acc[date]) {
        acc[date] = [];
      }
      const coords = flightItem.flight.locationHistory.map(loc => [+loc.latitude, +loc.longitude]);

      acc[date].push({
        droneId: flightItem.droneId,
        droneType: flightItem.droneType,
        id: `${flightItem.droneId}-${flightItem.flight.startDate}`,
        startDate: flightItem.flight.startDate,
        endDate: flightItem.flight.endDate,
        coords,
        color: colorsList[i % colorsList.length],
        radius: isCurrentFlight ? 5000 : undefined
        // areaPolygonCoordinates: !isCurrentFlight ? getPolygonOfDetectedAreaCoords(coords) : undefined
      });

      return acc;
    }, {});
  }, [droneWithFlights, isCurrentFlight]);

  return { loading, drones: droneWithFlights, flightsMap };
};

export default useGetDrones;
