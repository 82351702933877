import React from 'react';
import MenuCheckBoxes from 'components/MultiSelect/MenuCheckBoxes';
import { Option } from 'components/MultiSelect/types';
import { NotificationType } from 'store/types';
import Text, { TextColor, TextSize } from 'components/Text/Text';
import { StyledComponent } from 'types';

interface ListNotificationsProps extends StyledComponent {
  notifications: NotificationType;
  onToggleCheckBox: (option: Option) => void;
  title: React.ReactNode;
  newOptions: Record<string, boolean>;
}

const notificationsMap: Record<string, string> = {
  cameraOnline: 'CAMERA ONLINE',
  cameraOffline: 'CAMERA OFFLINE',
  stationDisconnected: 'STATION DISCONNECTED',
  stationConnected: 'STATION CONNECTED',
  stationAlarm: 'STATION ALARM',
  stationNormal: 'STATION NORMAL',
  stationMedium: 'STATION MEDIUM',
  stationIssue: 'STATION ISSUE',
  stationLearning: 'STATION LEARNING'
};

const ListNotifications: React.FC<ListNotificationsProps> = ({
  notifications,
  onToggleCheckBox,
  title,
  className,
  newOptions
}) => {
  const selectedOptions = Object.entries({ ...notifications, ...newOptions }).reduce<string[]>((acc, [key, value]) => {
    if (value) {
      acc.push(key);
    }

    return acc;
  }, []);

  const soundOptions = React.useMemo(
    () =>
      Object.entries(notifications).map(([key]) => ({
        value: key,
        label: notificationsMap[key]
      })),
    [notifications]
  );

  return (
    <div className={className} style={{ maxWidth: '300px' }}>
      <Text color={TextColor.Silver} className='text-center text-uppercase text-underline mb-4' size={TextSize.Md}>
        {title}
      </Text>
      <MenuCheckBoxes
        options={soundOptions}
        onToggleCheckBox={onToggleCheckBox}
        selectedOptionValues={selectedOptions}
      />
    </div>
  );
};

export default ListNotifications;
