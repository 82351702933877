import React from 'react';
import useGetAllEvents from './useGetAllEvents';
import HeaderComponent from 'components/HeaderComponent';
import { CiSettings } from 'react-icons/ci';
import { COLORS, LOGO_SIZE } from 'consts';
import Spinner from 'components/Spinner';
import Calendar from 'components/Calendar';
import FilterStation from '../shared/FilterStation';
import Input from 'components/Input';
import TextResourceContext from 'contexts/TextResource';
import EventsPaginationWidget from './EventsPaginationWidget';

const AllEventsWidget = () => {
  const { loading, eventsMap, onSubmitCalendar, fetchNextPage, setSearchFilter, searchFilter } = useGetAllEvents();
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  return (
    <div className='events-widget'>
      <HeaderComponent logo={<CiSettings size={LOGO_SIZE} color={COLORS.white} />} />

      {loading ? (
        <Spinner isFullSize />
      ) : (
        <>
          <div className='filters-placeholder d-flex gap-3 align-items-center justify-content-end position-fixed layout-width'>
            <Input
              value={searchFilter}
              onSetValue={setSearchFilter}
              placeholder={getTextResourceByKey('search') as string}
              withEmptyStringValidation={false}
              className='mr-3 mb-2'
            />
            <Calendar onSubmit={onSubmitCalendar} />
            <FilterStation />
          </div>
          <div className='pt-5'>
            <EventsPaginationWidget eventsMap={eventsMap} fetchNextPage={fetchNextPage} />
          </div>
        </>
      )}
    </div>
  );
};

export default AllEventsWidget;
