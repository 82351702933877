import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/types';
import { addStationAction } from 'store/stationsReducer';
import BaseStationModal from './BaseStationModal';
import { AddStationBody } from 'types';
import './AddStationModal.scss';
import TextResourceContext from 'contexts/TextResource';

interface AddStationComponentProps {
  closeModal: () => void;
}

const AddStationModal: React.FC<AddStationComponentProps> = ({ closeModal }) => {
  const dispatch = useDispatch() as AppDispatch;
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  const onAddStation = React.useCallback(
    (body: Partial<AddStationBody>) => dispatch(addStationAction(body as AddStationBody)).then(closeModal),
    [closeModal, addStationAction]
  );

  return (
    <BaseStationModal
      onSubmit={onAddStation}
      isAddStationMode
      title={getTextResourceByKey('addNewStation') as string}
    />
  );
};

export default AddStationModal;
