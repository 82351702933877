import { debounce, get } from 'lodash';

type Options = { keys: string[] };
export const getDebouncedSearch = function <T>(callback: (args: T[]) => void, items: T[]) {
  return debounce(function (search: string, options: Options) {
    const filteredItems = getFilteredValues(items, search, options);

    callback(filteredItems);
  }, 500);
};

export function getFilteredValues<T>(items: T[], search: string, options: Options) {
  if (search === null || search === '') {
    return items;
  }

  return items.filter(item => {
    for (const key of options.keys) {
      const itemValue = get(item, key) as string;

      if (itemValue && itemValue.toLowerCase().match(search.toLowerCase())) {
        return true;
      }
    }

    return false;
  });
}
