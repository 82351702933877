import React from 'react';
import { useSelector } from 'react-redux';
import { getNextTimeUpdate } from '../store/stationsReducer';
import { convertToDoubleDigits } from '../utils';
import moment from 'moment';

const useGetNextTimeUpdate = () => {
  const nextTimeUpdate = useSelector(getNextTimeUpdate);
  const [remainingTime, setRemainingTime] = React.useState({ min: '00', sec: '00' });

  const updateTime = React.useCallback(() => {
    const duration = moment.duration(nextTimeUpdate - Date.now());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    setRemainingTime({
      min: convertToDoubleDigits(minutes < 0 ? 0 : minutes),
      sec: convertToDoubleDigits(seconds < 0 ? 0 : seconds)
    });
  }, [nextTimeUpdate]);

  React.useEffect(() => {
    updateTime();

    const interval = setInterval(() => {
      updateTime();
    }, 1000);

    return () => clearInterval(interval);
  }, [updateTime]);

  return remainingTime;
};

export default useGetNextTimeUpdate;
