import {
  BaklansEvent,
  ConnectionStatusEvent,
  ElescopeEvent,
  ElescopeEventData,
  Event,
  EventType,
  HrushchEvent,
  StationType,
  StatusEvent,
  StrausEvent
} from '../types';

export const isConnectionStatusEvent = (event: Event): event is ConnectionStatusEvent =>
  event.eventType === EventType.ConnectionStatus;

export const isStatusTypeEvent = (event: Event): event is StatusEvent => event.eventType === EventType.Status;

export const isStrausEvent = (event: Event): event is StrausEvent => event.box.type === StationType.Status;
export const isHrushchEvent = (event: Event): event is HrushchEvent => event.box.type === StationType.Hrushch;

export const isBaklansEvent = (event: Event): event is BaklansEvent => event.box.type === StationType.Baklans;

export const isElescopeStatusEvent = (event: Event): event is ElescopeEvent =>
  isStatusTypeEvent(event) &&
  Boolean((event.data as ElescopeEventData).droneId) &&
  Boolean((event.data as ElescopeEventData).status);
