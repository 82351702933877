import React from 'react';
import { Frequency } from 'types';
import TextResourceContext from 'contexts/TextResource';
import Text, { TextColor, TextSize } from 'components/Text';
import HTMLSpectrum from 'components/HTMLSpectrum';

interface CurrentFrequenciesCountChartProps {
  frequencies: Frequency[];
}

const CurrentFrequenciesCountChart: React.FC<CurrentFrequenciesCountChartProps> = ({ frequencies }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);

  return (
    <>
      <Text color={TextColor.Success} size={TextSize.MdLg} className='mb-5 text-center uppercase'>
        {getTextResourceByKey('frequencyAmplitudeChart')}
      </Text>
      <HTMLSpectrum
        dataFrequency={frequencies}
        scale={1}
        xUnit={getTextResourceByKey('frequency') as string}
        yUnit={getTextResourceByKey('amplitude') as string}
      />
    </>
  );
};

export default React.memo(CurrentFrequenciesCountChart);
