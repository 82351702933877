import React from 'react';
import useEventBus from 'contexts/EventBusContext';
import {
  VMS_CAMERA_OFFLINE,
  VMS_CAMERA_ONLINE,
  VMS_CAMERA_UPDATED,
  VMS_CONNECTION_STATUS_EVENT
} from 'services/event-bus';
import { ONE_MINUTE, ONE_SECOND } from 'utils/date';
import { BsCameraVideo, BsCameraVideoOff } from 'react-icons/bs';
import { appendEvent, updateCamera, updateVmsConnectionStatus } from 'store/vmsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { toastNotification } from 'components/Toaster';
import { getAuthData, getCurrentUserLogin } from 'store/authReducer';
import { VmsApiEvent } from 'types/Vms';
import { vmsApiEvent2VmsEvent } from '../utils/vms';
import { playSingleSound } from '../utils/sound';

export default function useVmsEvents() {
  const eventBus = useEventBus();
  const dispatch = useDispatch();
  const userLoggedIn = useSelector(getCurrentUserLogin);
  const authData = useSelector(getAuthData);
  const soundNotifications = authData?.soundNotifications;
  const cameraOnline = soundNotifications?.cameraOnline;
  const cameraOffline = soundNotifications?.cameraOffline;

  React.useEffect(() => {
    if (eventBus && soundNotifications) {
      const unsubscribeFromCameraOnline = eventBus.subscribe(VMS_CAMERA_ONLINE, (eventData: VmsApiEvent) => {
        dispatch(appendEvent(vmsApiEvent2VmsEvent(eventData)));

        toastNotification(
          {
            title: VMS_CAMERA_ONLINE,
            message: eventData.cameraName,
            description: eventData.message,
            type: 'success',
            date: eventData.createdAt
          },
          {
            IconComponent: <BsCameraVideo />,
            duration: ONE_SECOND * 10
          }
        );

        if (cameraOnline) {
          playSingleSound('success.mp3');
        }
      });

      const unsubscribeFromCameraOffline = eventBus.subscribe(VMS_CAMERA_OFFLINE, (eventData: VmsApiEvent) => {
        dispatch(appendEvent(vmsApiEvent2VmsEvent(eventData)));

        toastNotification(
          {
            title: VMS_CAMERA_OFFLINE,
            message: eventData.cameraName,
            description: eventData.message,
            type: 'error',
            date: eventData.createdAt
          },
          {
            icon: <BsCameraVideoOff />,
            duration: ONE_MINUTE * 10
          }
        );

        if (cameraOffline) {
          playSingleSound('negative_beeps.mp3');
        }
      });

      const vmsCameraUpdatedUnsubscribe = eventBus.subscribe(
        VMS_CAMERA_UPDATED,
        ({ id, status, name }: { id: number; status: string; name: string }) => {
          dispatch(updateCamera({ id, status, name }));
        }
      );

      return () => {
        unsubscribeFromCameraOffline();
        unsubscribeFromCameraOnline();
        vmsCameraUpdatedUnsubscribe();
      };
    }
  }, [eventBus, userLoggedIn, cameraOnline, cameraOffline]);

  React.useEffect(() => {
    if (eventBus) {
      const unsubscribe = eventBus.subscribe<string>(VMS_CONNECTION_STATUS_EVENT, data => {
        dispatch(updateVmsConnectionStatus(data));
      });

      return unsubscribe;
    }
  }, [eventBus]);
}
