class UpdateStationsService {
  _intervalInMins?: number;
  intervalId?: ReturnType<typeof setInterval>;
  _callback?: () => void;
  _initted = false;

  initiate(intervalInMins: number, callback: () => void) {
    this._intervalInMins = intervalInMins;
    this._callback = callback;
    this._initted = true;
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        console.log('Browser tab is hidden');
      } else {
        this.reset({ leading: true });
      }
    });
  }

  start(options: { leading: boolean }) {
    if (!this._callback || !this._intervalInMins) {
      console.warn('UPDATE_STATIONS_SERVICE: Timer is not iniated!!!');

      return null;
    }

    this.intervalId = setInterval(this._callback, this._intervalInMins * 60 * 1000);
    if (options.leading) {
      this._callback();
    }
  }

  stop() {
    this.intervalId && clearInterval(this.intervalId);
  }

  reset = (options: { leading: boolean }) => {
    this.stop();
    this.start(options);
  };

  clearSetup() {
    this.stop();
    this._intervalInMins = undefined;
    this._callback = undefined;
    this._initted = false;
  }
}

export default UpdateStationsService;
