import Draw from 'ol/interaction/Draw.js';
import Map from 'ol/Map.js';
import Overlay from 'ol/Overlay.js';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style.js';
import { LineString, Polygon } from 'ol/geom.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { getArea, getLength } from 'ol/sphere.js';
import { unByKey } from 'ol/Observable.js';
import { Feature } from 'ol';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { EventsKey } from 'ol/events';
import 'styles/map.scss';
import { Coordinate } from 'ol/coordinate';
import uuid4 from 'uuid4';
import { Circle } from 'ol/geom';

export enum DrawType {
  'LineString' = 'LineString',
  'Polygon' = 'Polygon',
  'Circle' = 'Circle'
}

class DrawService {
  map: Map;

  tempId: string | number | null = null;

  source = new VectorSource();
  vector = new VectorLayer({
    source: this.source,
    style: {
      'fill-color': 'rgba(255, 255, 255, 0.2)',
      'stroke-color': '#ffcc33',
      'stroke-width': 2,
      'circle-radius': 7,
      'circle-fill-color': '#ffcc33'
    }
  });

  constructor(map: Map) {
    map.on('pointermove', this.pointerMoveHandler);
    map.getViewport().addEventListener('mouseout', () => {
      this.helpTooltipElement?.classList.add('hidden');
    });

    this.map = map;

    this.map.addLayer(this.vector);
  }

  /**
   * Currently drawn feature.
   * @type {import("../src/ol/Feature.js").default}
   */
  sketch: Feature | null = null;

  /**
   * The help tooltip element.
   * @type {HTMLElement}
   */
  helpTooltipElement: HTMLElement | null = null;

  /**
   * Overlay to show the help messages.
   * @type {Overlay}
   */
  helpTooltip: Overlay | null = null;

  /**
   * The measure tooltip element.
   * @type {HTMLElement}
   */
  measureTooltipElement: HTMLElement | null = null;

  /**
   * Overlay to show the measurement.
   * @type {Overlay}
   */
  measureTooltip: Overlay | null = null;

  /**
   * Message to show when the user is drawing a polygon.
   * @type {string}
   */
  continuePolygonMsg = 'Click to continue drawing the polygon';

  /**
   * Message to show when the user is drawing a line.
   * @type {string}
   */
  continueLineMsg = 'Click to continue drawing the line';

  /**
   * Handle pointer move.
   * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
   */
  pointerMoveHandler = (evt: MapBrowserEvent<any>) => {
    if (evt.dragging) {
      return;
    }
    /** @type {string} */
    let helpMsg = 'Click to start drawing';

    if (this.sketch) {
      const geom = this.sketch.getGeometry();

      if (geom instanceof Polygon) {
        helpMsg = this.continuePolygonMsg;
      } else if (geom instanceof LineString) {
        helpMsg = this.continueLineMsg;
      }
    }

    if (this.helpTooltipElement) {
      this.helpTooltipElement.innerHTML = helpMsg;
      this.helpTooltipElement.classList.remove('hidden');
    }

    if (this.helpTooltip) {
      this.helpTooltip.setPosition(evt.coordinate);
    }
  };

  /**
   * Format length output.
   * @param {LineString} line The line.
   * @return {string} The formatted length.
   */
  formatLength = (line: LineString) => {
    const length = getLength(line);
    let output;

    if (length > 100) {
      output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
    } else {
      output = Math.round(length * 100) / 100 + ' ' + 'm';
    }

    return output;
  };
  /**
   * Format length output.
   * @param {LineString} line The line.
   * @return {string} The formatted length.
   */
  formatCircle = (circle: Circle) => {
    // for some reason we have shift in 1.5
    const multiplier = 1.5;

    return 'r=' + Math.round(circle.getRadius() / 1000 / multiplier) + 'km';
  };

  /**
   * Format area output.
   * @param {Polygon} polygon The polygon.
   * @return {string} Formatted area.
   */
  formatArea = (polygon: Polygon) => {
    const area = getArea(polygon);
    let output;

    if (area > 10000) {
      output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
    } else {
      output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
    }

    return output;
  };

  draw: Draw | null = null;

  addInteraction = ({ type }: { type: DrawType }) => {
    const id = uuid4();

    this.dropInteraction();

    this.draw = new Draw({
      source: this.source,
      type: type,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        }),
        stroke: new Stroke({
          color: 'rgba(225, 225, 0, 0.5)',
          lineDash: [10, 10],
          width: 2
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(255, 255, 0, 1)'
          }),
          fill: new Fill({
            color: 'rgba(255 0, 0, 1)'
          })
        })
      })
    });
    this.map.addInteraction(this.draw);

    this.createMeasureTooltip(id);

    let listener: EventsKey | undefined;

    this.draw.on('drawstart', (evt: any) => {
      // set sketch
      this.sketch = evt.feature;
      evt.feature.setId(id);

      this.tempId = id;

      /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
      let tooltipCoord = evt.coordinate;

      if (this.sketch) {
        listener = this.sketch.getGeometry()?.on('change', changeEvt => {
          const geom = changeEvt.target;

          let output;

          if (geom instanceof Polygon) {
            output = this.formatArea(geom);
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          } else if (geom instanceof LineString) {
            output = this.formatLength(geom);
            tooltipCoord = geom.getLastCoordinate();
          } else if (geom instanceof Circle) {
            output = this.formatCircle(geom);
            tooltipCoord = geom.getFirstCoordinate();
          }
          if (this.measureTooltipElement && output) {
            this.measureTooltipElement.innerHTML = output;
            this.measureTooltipElement.addEventListener('click', clickEvt => {
              if (clickEvt.shiftKey) {
                this.dropFeatureWithOverlay(id);
              }
            });
          }
          if (this.measureTooltip) {
            this.measureTooltip.setPosition(tooltipCoord);
          }
        });
      }
    });

    this.draw.on('drawend', () => {
      if (this.measureTooltipElement) {
        this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      }

      if (this.measureTooltip) {
        this.measureTooltip.setOffset([0, -7]);
      }

      // unset sketch
      this.sketch = null;
      // unset tooltip so that a new one can be created
      this.measureTooltipElement = null;
      this.createMeasureTooltip();

      if (listener) {
        unByKey(listener);
      }

      this.dropInteraction();
      this.addInteraction({ type });
      this.tempId = null;
    });
  };

  addStaticTooltip = (position: Coordinate, id?: string) => {
    const element = document.createElement('div');

    element.className = 'ol-tooltip ol-tooltip-static';

    element.addEventListener('click', evt => {
      if (evt.shiftKey && id) {
        element.removeChild(element);
        this.dropFeatureWithOverlay(id);
      }
    });

    const overlay = new Overlay({
      element: element,
      position,
      offset: [0, -15],
      positioning: 'bottom-center',
      stopEvent: false,
      insertFirst: false,
      id
    });

    this.map.addOverlay(overlay);
  };

  /**
   * Creates a new measure tooltip
   */
  createMeasureTooltip = (id?: string) => {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode?.removeChild(this.measureTooltipElement);
    } else {
      this.measureTooltipElement = document.createElement('div');
    }
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center',
      stopEvent: false,
      insertFirst: false,
      id
    });
    this.map.addOverlay(this.measureTooltip);

    this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
  };

  dropOverlay = (id: string) => {
    const overlay = this.map.getOverlayById(id);

    if (overlay) {
      this.map.removeOverlay(this.map.getOverlayById(id));
      const element = overlay.getElement();

      element?.parentNode?.removeChild(element);
    }
  };

  dropFeature = (id: string) => {
    const feature = this.source.getFeatureById(id);

    if (feature) {
      this.source.removeFeature(feature);
    }
  };

  dropFeatureWithOverlay(id: string) {
    this.dropFeature(id);
    this.dropOverlay(id);
  }

  dropInteraction = () => {
    if (this.draw) {
      this.map.removeInteraction(this.draw);
      this.draw = null;
    }
  };
}

export default DrawService;
