import React from 'react';
import { PageRoutes, User, UsersRole } from 'types';
import Card from 'components/Card';
import TextResourceContext from 'contexts/TextResource';
import { ButtonSize, ButtonType } from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/types';
import { removeUserAction } from 'store/usersReducer';
import classNames from 'classnames';
import { COLORS, ROLES_MAP } from 'consts';
import Text, { TextColor, TextSize } from 'components/Text';
import { ModalContext } from 'contexts/ModalContext';
import ConfirmModalBody from 'components/ConfirmModalBody';
import { IoIosLogIn } from 'react-icons/io';
import { CiSettings, CiTrash } from 'react-icons/ci';
import ButtonIcon from 'components/ButtonIcon';
import Separator from 'components/Separator';
import { getCurrentUserLogin } from 'store/authReducer';
import './UserCard.scss';
import { useNavigate } from 'react-router-dom';

interface UserCardProps {
  user: User;
}

const UserCard: React.FC<UserCardProps> = ({ user }) => {
  const { getTextResourceByKey } = React.useContext(TextResourceContext);
  const { openModal, closeModal } = React.useContext(ModalContext);
  const dispatch = useDispatch() as AppDispatch;
  const currentUserLogin = useSelector(getCurrentUserLogin);
  const navigate = useNavigate();

  const deleteBtnIsEnabled = user.login !== currentUserLogin;

  const onEditUser = React.useCallback(() => {
    navigate(PageRoutes.EditUser.replace(':userId', user.id.toString()));
  }, []);

  const onDeleteUser = React.useCallback(() => {
    const onSubmit = () => {
      dispatch(removeUserAction(user.id)).then(closeModal);
    };

    openModal(
      <ConfirmModalBody
        onClose={closeModal}
        onSubmit={onSubmit}
        title={getTextResourceByKey('deleteUser') as string}
        type='delete'
      >
        {getTextResourceByKey('deleteUserBody', {
          name: <span className='text-bold text-underline color-white'>{user.name}</span>
        })}
      </ConfirmModalBody>,
      { modalType: 'confirm' }
    );
  }, []);

  return (
    <Card bodyClassName='p-3'>
      <div className='d-flex flex-column justify-content-center h-100'>
        <div className='flex flex-space-between'>
          <div className='mb-2'>
            <Text className='mb-2' color={TextColor.White} size={TextSize.MdLg}>
              {user.name}
            </Text>
            <Text color={TextColor.Gray} size={TextSize.Sm} className='d-flex align-items-center'>
              <IoIosLogIn size={20} className='mr-2' />
              <span>{user.login}</span>
            </Text>
          </div>
          <div className='flex'>
            <ButtonIcon
              size={ButtonSize.SmallMedium}
              className='p-1 mr-1'
              buttonType={ButtonType.Dark}
              onClick={onEditUser}
            >
              <CiSettings size={24} color={COLORS.warning} />
            </ButtonIcon>
            {deleteBtnIsEnabled && (
              <ButtonIcon
                size={ButtonSize.SmallMedium}
                className='p-1'
                buttonType={ButtonType.Dark}
                onClick={onDeleteUser}
              >
                <CiTrash size={24} color={COLORS.danger} />
              </ButtonIcon>
            )}
          </div>
        </div>
        <div>
          <Separator className='mb-2' />
          <Text
            className={classNames('user-card__role', { 'is-admin': user.role === UsersRole.Admin })}
            size={TextSize.Sm}
          >
            {getTextResourceByKey(ROLES_MAP[user.role])}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default UserCard;
