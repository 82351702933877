import React from 'react';
import { ElescopeStationModel } from 'types';
import useGetDrones from '../useGetDrones';
import ElescopeStationLayout from '../ElescopeStationLayout';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/types';
import { getDronesCurrentLocationAction } from 'store/dronesReducer';
import { getStationIsAlarm } from 'utils/stations';

import './ElescopeCurrentWidget.scss';

interface ElescopeCurrentWidgetProps {
  station: ElescopeStationModel;
}

const INTERVAL_UPDATE = 3000;

const ElescopeCurrentWidget: React.FC<ElescopeCurrentWidgetProps> = ({ station }) => {
  const { flightsMap } = useGetDrones(station.id);
  const dispatch = useDispatch() as AppDispatch;
  const intervalRef = React.useRef<NodeJS.Timer>();
  const stationIsAlarmed = getStationIsAlarm(station);

  React.useEffect(() => {
    if (stationIsAlarmed) {
      intervalRef.current = setInterval(() => {
        dispatch(getDronesCurrentLocationAction({ stationId: station.id }));
      }, INTERVAL_UPDATE);

      return () => clearInterval(intervalRef.current);
    }
  }, [stationIsAlarmed]);

  return <ElescopeStationLayout flightsMap={flightsMap} station={station} />;
};

export default React.memo(ElescopeCurrentWidget);
