import React, { CanvasHTMLAttributes } from 'react';

const initialBounds = { width: 0, height: 0 };

export const CanvasContext = React.createContext<{
  ctx: CanvasRenderingContext2D | null;
  bounds: { width: number; height: number };
  canvas: null | HTMLCanvasElement;
}>({ ctx: null, bounds: initialBounds, canvas: null });

interface CanvasContextProviderProps extends CanvasHTMLAttributes<any> {
  children: React.ReactNode;
  height: number;
}

const CanvasContextProvider: React.FC<CanvasContextProviderProps> = ({ children, height, ...canvasProps }) => {
  const canvasRef = React.createRef<HTMLCanvasElement>();
  const containerRef = React.createRef<HTMLDivElement>();
  const [ctx, setCtx] = React.useState<CanvasRenderingContext2D | null>(null);
  const [bounds, setBounds] = React.useState(initialBounds);

  React.useEffect(() => {
    const context = canvasRef.current?.getContext('2d') as CanvasRenderingContext2D;

    setCtx(context);

    const containerBounds = containerRef.current?.getBoundingClientRect();

    if (containerBounds) {
      setBounds({ width: containerBounds.width, height: containerBounds.height });
    }
  }, []);

  React.useEffect(() => {
    const onResize = () => {
      const containerBounds = containerRef.current?.getBoundingClientRect();

      if (containerBounds) {
        setBounds({ width: containerBounds.width, height: containerBounds.height });
      }
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [containerRef]);

  return (
    <>
      <div className='p-absolute w-100' ref={containerRef} style={{ height: `${height}px` }}>
        <canvas ref={canvasRef} width={bounds.width} height={bounds.height} {...canvasProps} />
      </div>
      <CanvasContext.Provider value={{ ctx, bounds, canvas: canvasRef?.current || null }}>
        {children}
      </CanvasContext.Provider>
    </>
  );
};

export default CanvasContextProvider;
