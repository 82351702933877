import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  isBaklansStation,
  isElescopeStation,
  isHrushchStation,
  isStatusStation,
  PageRoutes,
  StationModel
} from 'types';
import Card from 'components/Card';
import { TextColor, TextSize } from 'components/Text';
import ConnectionStatusLabel from 'components/StationStatusLabel';
import DashboardStatusStationBody from './DashboardStatusStationBody';
import DashboardElescopeStationBody from './DashboardElescopeStationBody';
import DashboardBaklansStationBody from './DashboardBaklansStationBody';
import classNames from 'classnames';
import { getStationIsDisconnected, getIsStationInfoDisabled, getStationIsNavigatable } from 'utils/stations';
import StationLabel from 'components/StationLabel';
import TruncatedText from 'components/Text/TruncatedText';
import DashboardHrushchStationBody from './DashboardHrushchStationBody';

import './DashboardStationCard.scss';

interface StationCardProps {
  station: StationModel;
}

const getStationBody = (station: StationModel) => {
  if (isStatusStation(station)) {
    return <DashboardStatusStationBody station={station} />;
  }

  if (isHrushchStation(station)) {
    return <DashboardHrushchStationBody station={station} />;
  }

  if (isElescopeStation(station)) {
    return <DashboardElescopeStationBody station={station} />;
  }
  if (isBaklansStation(station) && getStationIsDisconnected(station)) {
    return <DashboardBaklansStationBody />;
  }
};

const DashboardStationCard: React.FC<StationCardProps> = ({ station }) => {
  const navigate = useNavigate();

  const onClickCard = React.useCallback(() => {
    navigate(`${PageRoutes.Stations}/${station.id}`);
  }, [station.id]);

  const stationBody = getStationBody(station);
  const isDisabledBody = getIsStationInfoDisabled(station);
  const cardIsClickable = getStationIsNavigatable(station);

  return (
    <Card
      onClick={cardIsClickable ? onClickCard : undefined}
      className={classNames('dashboard-station-card', { disabled: false }, cardIsClickable && 'cursor-pointer')}
      rightBar={<ConnectionStatusLabel station={station} className={classNames('align-self-end')} />}
    >
      <div className='d-flex h-100'>
        <div className='d-flex align-items-center'>
          <StationLabel className='ml-1 mb-2 mr-1' size={35} stationType={station.type} />
        </div>
        <div className='d-flex flex-column w-100 h-60 align-self-center gap-2'>
          <TruncatedText className='uppercase' color={TextColor.White} size={TextSize.MdLg}>
            {station.name}
          </TruncatedText>
          <TruncatedText size={TextSize.SmMd} color={TextColor.Gray}>
            {station.location}
          </TruncatedText>
        </div>
        {!isDisabledBody ? (
          <div className='text-center h-100 w-50 d-flex flex-column justify-content-center'>{stationBody}</div>
        ) : null}
      </div>
    </Card>
  );
};

export default React.memo(DashboardStationCard);
