import React from 'react';
import classNames from 'classnames';
import { getIsCameraOffline } from 'utils/vms';
import { TextColor, TextSize } from 'components/Text/Text';
import { CameraStatus, VmsCamera } from 'types/Vms';
import { CiVideoOff, CiVideoOn } from 'react-icons/ci';
import { COLORS } from 'consts';
import { MdOutlineEmergencyRecording } from 'react-icons/md';
import TruncatedText from 'components/Text/TruncatedText';

const iconProps = { size: 16, className: 'mr-2', width: '20px' };

const getIconComponent = (camera: VmsCamera) => {
  if (camera.status === CameraStatus.Offline) {
    return <CiVideoOff color={COLORS.white} {...iconProps} />;
  }
  if (camera.status === CameraStatus.Recording) {
    return <MdOutlineEmergencyRecording color={COLORS.white} {...iconProps} />;
  }

  return <CiVideoOn color={COLORS.white} {...iconProps} />;
};

interface VmsCameraListItemProps {
  onClick: () => void;
  camera: VmsCamera;
  isSelected?: boolean;
}

const VmsCameraListItem: React.FC<VmsCameraListItemProps> = ({ onClick, camera, isSelected }) => (
  <div
    onClick={onClick}
    className={classNames(
      'flex flex-row vms-camera-name-wrapper mb-2 position-relative',
      getIsCameraOffline(camera) && 'disabled'
    )}
  >
    <div className='d-flex align-items-center w-100'>
      <div style={{ width: '20px' }}>{getIconComponent(camera)}</div>
      <TruncatedText
        color={isSelected ? TextColor.Warning : TextColor.White}
        size={TextSize.XSm}
        className='cursor-pointer no-white-space text-overflow-elipsis'
        onClick={onClick}
      >
        {camera.name}
      </TruncatedText>
    </div>
  </div>
);

export default VmsCameraListItem;
