import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AnalyticsCounts, AnalyticsCountsState, ThunkOptions, FetchingData } from '../types';
import { createEmptyFetchedState } from './utils';
import { resetStateAction } from './shared';
import { extendUrlByQueries } from 'utils/api';
import { RootState } from './types';
import { isEmpty } from 'lodash';

const initialState: AnalyticsCountsState = {};
const emptyData = { increased: [], decreased: [] };
const initStationState = createEmptyFetchedState(emptyData);

const SIGNAL_KEY_COUNTS = 'resetStationAnalyticsCounts';

type GetAnalayticsQueryParams = {
  firstPeriodStart: number;
  firstPeriodEnd: number;
  secondPeriodStart: number;
  secondPeriodEnd: number;
};

export const getAnalyticsCountAction = createAsyncThunk<
  AnalyticsCounts,
  { stationId: number } & GetAnalayticsQueryParams
>(
  'STATIONS@GET_ANALYTICS_COUNT',
  (
    { stationId, firstPeriodEnd, firstPeriodStart, secondPeriodEnd, secondPeriodStart },
    { extra: { api } }: ThunkOptions
  ) =>
    api.get(
      extendUrlByQueries(`stations/${stationId}/analytics/diffByPeriods`, {
        firstPeriodEnd,
        firstPeriodStart,
        secondPeriodEnd,
        secondPeriodStart
      }),
      SIGNAL_KEY_COUNTS
    )
);

export const resetStationAnalyticsCounts = createAction<number>(SIGNAL_KEY_COUNTS);

export const resetStationAnalyticsCountsAndCancelPrevRequest = createAsyncThunk(
  'STATIONS@ABORT_ANALYTICS_COUNT',
  (stationId: number, { extra: { api }, dispatch }: ThunkOptions) => {
    api.abortController(SIGNAL_KEY_COUNTS);
    dispatch(resetStationAnalyticsCounts(stationId));
  }
);

const analyticsCountsSlice = createSlice({
  name: 'stationAnalyticsCounts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAnalyticsCountAction.pending, (state, action) => {
      const stationId = action.meta.arg.stationId;
      const prevState = state[stationId] || initStationState;

      state[stationId] = {
        ...prevState,
        loading: true
      };
    });
    builder.addCase(getAnalyticsCountAction.rejected, (state, action) => {
      const stationId = action.meta.arg.stationId;

      state[stationId] = {
        ...state[stationId],
        error: action.error,
        loading: false
      };
    });
    builder.addCase(getAnalyticsCountAction.fulfilled, (state, action) => {
      const stationId = action.meta.arg.stationId;

      state[stationId] = {
        ...state[stationId],
        loading: false,
        loaded: true,
        data: {
          increased: action.payload.increased.filter(freq => freq.count > 0),
          decreased: action.payload.decreased.filter(freq => freq.count > 0)
        }
      };
    });
    builder.addCase(resetStationAnalyticsCounts, (state, action) => {
      const stationId = action.payload;

      state[stationId] = initStationState;
    });
    builder.addCase(resetStateAction.fulfilled, () => initialState);
  }
});

export const getAnalyticsCountsSelector = (state: RootState, stationId: number): FetchingData<AnalyticsCounts> =>
  state.analyticsCounts[stationId] || initStationState;

const getAnalyticsCountsData = (state: RootState, stationId: number) =>
  getAnalyticsCountsSelector(state, stationId).data;

export const getAnalyticsCountsDataIsEmptySelector = (state: RootState, stationId: number): boolean =>
  getAnalyticsCountsSelector(state, stationId).loaded &&
  isEmpty(getAnalyticsCountsData(state, stationId).increased) &&
  isEmpty(getAnalyticsCountsData(state, stationId).decreased);

export const analyticsCountsReducer = analyticsCountsSlice.reducer;
